<template>
  <div class="user-details">
    <!-- Content Header (Page header) -->
    <div class="">
      <!--header-->
      <div class="profile-header profile-bg ">
        <div class="row">
          <div v-if="isProfileloading==true" class="d-flex justify-content-center userpf-loader">
            <div  class="spinner-border" role="status">
              <span class="sr-only">Loading...</span>
            </div>
          </div>
          <div  class="col-md-2 text-right hovereffect pl-5 pr-0 mr-0">                      
            <img style="height:100%;width:100%;" :src="profileImg" alt="User Avatar" :key="updateLoggedUserInfo" class="mr-3 avatar">
          </div>
          <div class="col-md-6">
            <div class="profiler-detail pl-4 pr-4">
              <h3>{{userProfileData && userProfileData.personal && userProfileData.personal.full_name ? userProfileData.personal.full_name : ''}}<span class="text-danger text-capitalize family-cat-txt">{{ familyCat || "" }}</span></h3>
              <div class="d-flex">
                <div class="left d-flex">
                  <h5 class="pt-1">{{userProfileData && userProfileData.membership ? userProfileData.membership.name : ''}} Membership</h5>
                </div>
                <div class="right" style="margin-left:auto;align-items: center;">                                   
                  <h5 class="text-white text-right d-flex"  style="align-items: center;" ><span class="lable text-white mr-2">Membership# </span>  {{userProfileData.confirmation_code || "N/A"}}</h5>
                </div>
              </div>
              <div class="text-right" style="margin-left: auto; align-items: center;" v-show="isNotalliancefastpitch && isSpouseOrChild">
                <div class="autorenewal">
                  <span class="text-white">
                    <b-form-checkbox v-model="autoRenew" name="autorenewal" switch value="1" size="lg"  @change="changeAutoRenewal($event)" > Auto Renewal</b-form-checkbox>
                  </span>
                </div>
              </div>
              <div class="b-der"></div>
                <div class="d-flex">
                  <div class="reg_date mt-3 mr-3">
                    <div class="d-flex align-items-center">
                      <div class="up_icon mr-4">
                        <img src="/dist/img/profile/ico-registerd-date.png">
                      </div>
                      <div class="up_text">
                        <span class="lable">Start Date</span>
                        <span> {{startDate || "N/A"}}</span>
                      </div>
                    </div>    
                  </div>
                  <div class="ex_date mt-3 ml-3">
                    <div class="d-flex align-items-center">
                      <div class="up_icon mr-4">
                        <img src="/dist/img/profile/ico-expired-date.png">
                      </div>
                      <div class="up_text">
                        <span  class="lable">Expiration Date</span>
                        <span> {{ userProfileData && userProfileData.membership && userProfileData.membership.period != 999 ? validThru : "N/A"}} <ChangeRegExpirationDate v-if="getSiteProps('general.change_expiry_date')" :memberType="'member'" :memId="currentMemberId" :updateExpireDate="validThru" @reload="displayAlertAndReloadData($event)"></ChangeRegExpirationDate> </span>
                      </div>
                    </div>
                  </div>
                  <div class="mt-4 ml-5">
                    <div class="d-flex align-items-center">
                      <h5 class=" text-right d-flex"  style="align-items: center;" v-if="userNote.notes.length > 0">
                        <a href="#notes_section" class="text-white"><i class="text-success fa fa-check"></i> Notes</a><span class="lable text-white mr-2" v-b-modal.note-modal variant="light" v-on:click="addnote()">&nbsp; <i class="fas fa-plus-circle"></i></span>
                      </h5>
                      <h5 class="text-white text-right d-flex"  style="align-items: center;" v-else>
                        <span class="lable text-white mr-2" v-b-modal.note-modal variant="light" v-on:click="addnote()"><i class="fa fa-sticky-note"></i> Add Note</span>
                      </h5>
                    </div> 
                  </div>
                </div>
              </div>
            </div>
            <div class="col-md-4 pl-0 pr-5"> <!-- Temp condition for shooting -->
              <div class="mcard">
                <membercard :member-id="userProfileData.member_id" :key="updateLoggedUserInfo"></membercard>
              </div>
              <div class="mt-3">
                <a href="javascript:void(0)" @click="refreshCard" class="refreshCardLink"><i class="fas fa-redo" :class="refreshProgress ? 'fa-spin' : ''"></i> Refresh Card</a>
              </div>
            </div>
          </div>
        </div>
        <!--end header-->
        <!-- profile body start-->
        <div class="profile-body mt-4 profile-page-wrapper">
          <custom-alert v-if="displayAlert==true" id="alert_box" :message="alertMessage" v-on:showedAlert="resetAlert"></custom-alert>
          <div v-if="isProfileloading==true && userProfileData.membership.member_type_id!=3" class="d-flex justify-content-center userpf-loader">
            <div  class="spinner-border" role="status">
              <span class="sr-only">Loading...</span>
            </div>
          </div>
          <div  class="row" v-if="userProfileData.membership.member_type_id!=3">
              <div  class="col-md-6 r-pad">
                <div class="card">
                  <div class="card-header container-fluid">
                    <div class="row">
                      <div class="col-md-5">
                        <h3 class="">{{ props_fields && props_fields.personal && props_fields.personal.tab_title  ? (userProfileData.membership.name == 'Staff')?'Coach Information':props_fields.personal.tab_title : 'Personal Information' }}</h3>
                      </div>
                      <div class="col-md-7 float-right">
                        <button v-b-modal.modal-center1 @click="resetForm()" class="btn btn-outline-primary btn-sm float-right ml-1" ><span class="fa fa-edit"></span> Edit</button>
                        <button class="btn btn-outline-primary btn-sm float-right" @click="impersonate(userProfileData.confirmation_code)" ><span class="fa fa-user-secret"></span> Login as {{userProfileData.confirmation_code || "N/A"}}</button>
                      </div>
                    </div>
                  </div>
                  <div class="card-body">
                    <div  class="row">
                      <div  class="col-md-6 col-lg-4" v-if="props_fields && props_fields.personal && props_fields.personal.title">
                        <div class="form-group">
                          <label class="form-label">{{ props_fields && props_fields.personal ? props_fields.personal.title.label : 'Title' }}</label>
                          <span >{{ userProfileData.personal.title || "N/A"}}</span>
                        </div>
                      </div>
                      <div  class="col-md-6 col-lg-4">
                        <div class="form-group">
                          <label class="form-label">{{ props_fields && props_fields.personal ? props_fields.personal.firstname.label  : 'First Name' }}</label>
                          <span >{{userProfileData.personal.first_name || "N/A"}}</span>
                        </div>
                      </div>
                      <div  class="col-md-6 col-lg-4">
                        <div class="form-group">
                          <label class="form-label">{{ props_fields && props_fields.personal ? props_fields.personal.middlename.label  : 'Middle Initial' }}</label>
                          <span >{{userProfileData.personal.middle_name || ""}}</span>
                        </div>
                      </div>
                      <div  class="col-md-6 col-lg-4">
                        <div class="form-group">
                          <label class="form-label" >{{ props_fields && props_fields.personal ? props_fields.personal.lastname.label  : 'Last Name' }}</label>
                          <span >{{userProfileData.personal.last_name || "N/A"}}</span>
                        </div>
                      </div>
                      <div  class="col-md-6 col-lg-4">
                        <div class="form-group">
                          <label class="form-label">{{ props_fields && props_fields.personal ? props_fields.personal.birthday.label  : 'Birthday' }}</label>
                          <span >{{userProfileData.personal.dob || "N/A"}}</span>
                        </div>
                      </div>
                      <div  class="col-md-6 col-lg-4" v-if="props_fields && props_fields.personal && props_fields.personal.gender">
                        <div class="form-group">
                          <label class="form-label">{{ props_fields && props_fields.personal ? props_fields.personal.gender.label  : 'Gender' }}</label>
                          <span >{{userProfileData.personal.gender || "N/A"}}</span>
                        </div>
                      </div>
                      <div  class="col-md-6 col-lg-4" v-if="props_fields && props_fields.personal && props_fields.personal.veteran_status">
                        <div class="form-group">
                          <label class="form-label">{{props_fields && props_fields.personal ? props_fields.personal.veteran_status.label : 'Veteran Status'}}</label>
                          <span >{{ userProfileData.personal.veteran_status || "N/A" }}</span>
                        </div>
                      </div>
                      <div  class="col-md-6 col-lg-4 " v-if="props_fields && props_fields.demographic && props_fields.demographic.race&&(subdomain=='shooting'&&userProfileData.membership.member_type_id==7)">
                        <div class="form-group">
                          <label class="form-label">{{props_fields && props_fields.demographic ? props_fields.demographic.race.label : 'Race' }}</label>
                          <span >{{userProfileData.personal.diversity.name || "N/A"}}</span>
                        </div>
                      </div>
                      <div  class="col-md-6 col-lg-4" v-show="props_fields && props_fields.personal && props_fields.personal.clubandteam && subdomain === 'waterski'" >
                        <div class="form-group">
                          <label class="form-label">{{props_fields && props_fields.personal.clubandteam ? props_fields.personal.clubandteam.label : 'Affiliated Clubs/Teams' }}</label>
                          <span class="title">{{ userProfileData.affiliated_clubs_names || "N/A" }}</span>
                        </div>
                      </div>
                      <!-- Shooting Club Name -->
                      <div class="col-md-6 col-lg-4" v-if="props_fields.personal.clubslist && userProfileData.club_name&&!(subdomain=='shooting'&& userProfileData.membership.member_type_id==7)">
                        <div class="form-group">
                          <label class="form-label">{{props_fields.personal.clubslist.label ||'Clubs/Teams'}}</label>
                          <span class="title">{{ userProfileData.club_name || "N/A" }}</span>
                        </div>
                      </div>
                      <!-- Shooting Club Name -->
                      <!-- AllianceFastpitch Club Name -->
                      <div class="col-md-6 col-lg-4" v-if="props_fields.personal.team_membership_id && !isNotalliancefastpitch">
                        <div class="form-group">
                          <label class="form-label">{{props_fields.personal.team_membership_id.label ||'Teams'}}</label>
                          <span class="title">{{ userProfileData.affiliated_clubs_names || "N/A" }}</span>
                        </div>
                      </div>
                      <div class="col-md-6 col-lg-4" v-if="props_fields.personal.team_membership_id && !isNotalliancefastpitch">
                        <div class="form-group">
                          <label class="form-label">Age Division</label>
                          <span class="title">{{ userProfileData.age_division || "N/A" }}</span>
                        </div>
                      </div>
                      <!-- AllianceFastpitch Club Name -->
                      <div :class="props_fields.general.sport_discipline.first_choice.disColClass" v-if="props_fields && props_fields.general && props_fields.general.sport_discipline && props_fields.general.sport_discipline.first_choice && props_fields.general.sport_discipline.first_choice.visible && !(subdomain=='shooting' && userProfileData.membership.member_type_id==7)">
                        <div class="form-group">
                          <label class="form-label">{{props_fields && props_fields.personal ? props_fields.general.sport_discipline.first_choice.label : '1st Choice' }}</label>
                          <span class="title" v-if="props_fields.general.sport_discipline.first_choice.multiple">
                            <ul v-if="userProfileData.disciplines && userProfileData.disciplines.length" :class="(props_fields.general.sport_discipline.first_choice.multiple) ? 'b-form-tags-list list-unstyled mb-0 d-flex flex-wrap align-items-center' : 'pl-3'">
                              <li v-for="(item, k) in userProfileData.disciplines" :key="'pdis-'+k" :class="(props_fields.general.sport_discipline.first_choice.multiple)? 'm-1 p-1 badge b-form-tag d-inline-flex align-items-baseline mw-100 btn-tag-blue btn-primary':''"><span :class="(props_fields.general.sport_discipline.first_choice.multiple)?'b-form-tag-content flex-grow-1 text-truncate text-white':''">{{ item.name }}</span></li>
                            </ul>
                          </span>
                          <span class="title" v-else>{{ userProfileData.first_choice_txt || "NP - No Preference" }}</span>
                        </div>
                      </div>
                      <div class="col-md-6 col-lg-4" v-if="props_fields && props_fields.general && props_fields.general.sport_discipline &&  props_fields.general.sport_discipline.second_choice && subdomain !== 'shooting'">
                        <div class="form-group">
                          <label class="form-label">{{props_fields && props_fields.personal ? props_fields.general.sport_discipline.second_choice.label : '2nd Choice' }}</label>
                          <span class="title">{{ userProfileData.second_choice_txt || "NP - No Preference"}}</span>
                        </div>
                      </div>
                      <div  class="col-md-6 col-lg-4" v-if="props_fields && props_fields.general && props_fields.general.sport_discipline && props_fields.general.sport_discipline.home_federation && subdomain !== 'shooting'">
                        <div class="form-group">
                          <label class="form-label">Federation </label>
                          <span >{{userProfileData.personal.home_federation || "N/A"}}</span>
                        </div>
                      </div>
                      <div  class="col-md-6 col-lg-4" v-if="props_fields && props_fields.general && props_fields.general.sport_discipline &&  props_fields.general.sport_discipline.skier_license && subdomain !== 'shooting'">
                        <div class="form-group">
                          <label class="form-label">Skier License</label>
                          <span >{{userProfileData.personal.skier_license || "N/A"}}</span>
                        </div>
                      </div>
                    </div>
                    <div class="row" v-if="userProfileData.affiliated_clubs_dropdown.length == 0 && userProfileData.new_club_name">
                      <div class="col-md-12 mb-3 mt-3"><strong>New Club Information</strong></div>
                        <div class="col-md-4" v-if="userProfileData.new_club_name && props_fields && props_fields.personal && props_fields.personal.new_club_name">
                          <div class="form-group">
                            <label class="form-label">{{'Club Name'}}</label>
                            <span >{{userProfileData.new_club_name || "N/A"}}</span>
                          </div>
                        </div>
                        <div class="col-md-4" v-if="userProfileData.new_club_email && props_fields && props_fields.personal && props_fields.personal.new_club_email">
                          <div class="form-group">
                            <label class="form-label">{{'Club E-Mail'}}</label>
                            <span >{{userProfileData.new_club_email || "N/A"}}</span>
                          </div>
                        </div>
                        <div class="col-md-4" v-if="userProfileData.new_club_phone && props_fields && props_fields.personal && props_fields.personal.new_club_phone">
                          <div class="form-group">
                            <label class="form-label">{{'Club Phone'}}</label>
                            <span >{{userProfileData.new_club_phone || "N/A"}}</span>
                          </div>
                        </div>
                      </div>
                    <div class="row" v-if="!isNotalliancefastpitch && isChild">
                      <div class="col-md-6 col-lg-4">
                        <div class="form-group">
                          <label class="form-label">AGL Player ID#</label>
                          <span >{{userProfileData.personal.agl_player_id || "N/A"}}</span>
                        </div>
                      </div>
                      <div class="col-md-6 col-lg-4" >
                        <div class="form-group">
                          <label class="form-label">Graduation Year</label>
                          <span >{{ userProfileData.personal.graduation_year || "N/A"}}</span>
                        </div>
                      </div>
                      <div class="col-md-6 col-lg-4" >
                        <div class="form-group">
                          <label class="form-label">Jersey Number</label>
                          <span >{{ userProfileData.personal.jersey_number || "N/A"}}</span>
                        </div>
                      </div>
                      <div class="col-md-6 col-lg-4" >
                        <div class="form-group">
                          <label class="form-label">Primary Position</label>
                          <span >{{userProfileData.personal.primary_position || "N/A"}}</span>
                        </div>
                      </div>
                      <div class="col-md-6 col-lg-4" >
                        <div class="form-group">
                          <label class="form-label">Secondary Position</label>
                          <span >{{userProfileData.personal.secondary_position || "N/A"}}</span>
                        </div>
                      </div>
                      <div class="col-md-6 col-lg-4" >
                        <div class="form-group">
                          <label class="form-label">Throws</label>
                          <span >{{ userProfileData.personal.throws==1?'Left':'Right' }}</span>
                        </div>
                      </div>
                      <div class="col-md-6 col-lg-4" >
                        <div class="form-group">
                          <label class="form-label">Bats</label>
                          <span >{{ userProfileData.personal.bats==1?'Left':'Right' }}</span>
                        </div>
                      </div>
                      <div class="col-md-6 col-lg-4" >
                        <div class="form-group">
                          <label class="form-label">College Committed</label>
                          <span v-if="userProfileData.personal.college_details != null">{{userProfileData.personal.college_details.name || "N/A"}}</span>
                          <span v-else>N/A</span>
                        </div>
                      </div>
                    </div>
                    <div class="col-md-6 col-lg-4" v-if="props_fields.personal.team_membership_id && !isNotalliancefastpitch">
                      <div class="form-group">
                        <label class="form-label">Member League</label>
                        <span class="title">{{ userProfileData.member_league || "N/A" }}</span>
                      </div>
                    </div>
                    </div>
                  </div>
                  <div class="card mb-3">
                    <div class="card-header ">
                      <div class="header-title">
                        <h3 >Membership Information</h3>
                      </div>
                      <div  class="modal-btn">
                        <b-button v-b-modal.modalMembership><span class="fa fa-edit"></span> Edit</b-button>
                      </div>
                    </div>
                    <div class="card-body">
                      <div  class="row">
                        <div  class="col-md-4">
                          <div class="form-group">
                            <label class="form-label">Membership Type</label>
                            <span>{{userProfileData && userProfileData.membership ? userProfileData.membership.name : ''}}</span>
                          </div>
                        </div>
                        <div  class="col-md-4">
                          <div class="form-group">
                            <label class="form-label">Start Date</label>
                            <span>{{ startDate }}</span>
                          </div>
                        </div>
                        <div  class="col-md-4">
                          <div class="form-group">
                            <label class="form-label">Expiration Date</label>
                            <span>{{ userProfileData && userProfileData.membership && userProfileData.membership.period != 999 ? validThru : "N/A"}}</span>
                          </div>
                        </div>
                        <div  class="col-md-4">
                          <div class="form-group">
                            <label class="form-label">Membership #</label>
                            <span>{{userProfileData.confirmation_code || "N/A"}}</span>
                          </div>
                        </div>

                        <div  class="col-md-4" v-show="digitalView">
                          <div class="form-group">
                            <div class="d-flex">
                                <label class="form-label">Membership Status  <span v-if="userProfileData.membership_status_info && subdomain == 'waterski'" v-b-tooltip.hover :title="userProfileData.membership_status_info"> <img  style="width:18px;" v-bind:src="'/dist/img/memberregistration/tooltip-icon-small.png'" alt="tooltipicon" /> </span></label>
                                <span class="text-danger bordernote" v-if="userProfileData.note && (userProfileData.membership_status_text == 'Suspended' || userProfileData.membership_status_text == 'Deceased')" v-b-tooltip.hover :title="userProfileData.note"><i class="fas fa-clipboard"></i></span>
                            </div>

                            <span class="text-success" v-if="userProfileData.membership_status_text == 'Current'"><img class="mr-1" src="/dist/img/profile/ico-status-current.png"> Current (Expires {{userProfileData.membership.period != 999 ? this.validThru:"N/A"}})</span>
                            <span class="text-warning" v-else-if="userProfileData.membership_status_text != 'Expired'"><img class="mr-1" src="/dist/img/profile/ico-status-pending.png"> {{ userProfileData.membership_status_text || 'N/A' }}</span>
                            <span class="text-warning" v-else> <img class="mr-1" src="/dist/img/profile/ico-status-pending.png"> Expired</span>
                          </div>
                        </div>
                        <div  class="col-md-4">
                          <div class="form-group" v-show="digitalView&&!(subdomain=='shooting'&&userProfileData.membership.member_type_id==7)">
                            <label class="form-label">Waiver Status</label>
                            <template v-if="!ignoreWaiverMembership.includes(userProfileData.membership.id)">
                              <span class="text-success" v-if="userProfileData.waiver_status == 1"><img class="mr-1" src="/dist/img/profile/ico-status-current.png"> Signed (Expires {{ userProfileData && userProfileData.membership && userProfileData.membership.period != 999 ? validThru : "N/A"}})</span>
                              <span class="text-warning"  v-else> <img class="mr-1" src="/dist/img/profile/ico-status-pending.png"> Not signed</span>
                            </template>
                            <span v-else>N/A</span>
                          </div>
                        </div>
                        <template v-if="subdomain == 'waterski'">
                        <div  class="col-md-4" v-show="digitalView">
                          <div class="form-group" v-if="userProfileData.safe_sport_screening_compliance == true">
                            <label class="form-label" >Safe Sport Training Status <a v-if="userProfileData.safe_sport != null" class="btn-refersh" @click.prevent="getSafeSportUserApi(userProfileData.confirmation_code, true);">{{ safeSportRefreshButton }}</a></label>
                            <span class="text-success" v-if="userProfileData.safe_sport != null && userProfileData.safe_sport.safe_sport_status_text == 'Complete'"><img  class="mr-1" src="/dist/img/profile/ico-status-current.png"> {{ userProfileData.safe_sport.safe_sport_status_text}} <br>(Expires {{ userProfileData.safe_sport.expiration }})</span>
                            <span class="text-warning" v-else-if="userProfileData.safe_sport != null"><img class="mr-1" src="/dist/img/profile/ico-status-pending.png"> {{userProfileData.safe_sport.safe_sport_status_text || "N/A"}} <template v-if="userProfileData.safe_sport.safe_sport_status == 13 && userProfileData.safe_sport.expiration"><br>(On {{ userProfileData.safe_sport.expiration }})</template></span>
                            <span class="text-warning" v-else>Not Started</span>
                          </div>
                          <div class="form-group" v-else>
                            <label class="form-label">Safe Sport Training Status</label>
                            <span>N/A</span>
                          </div>
                        </div>
                        <div  class="col-md-4" v-show="digitalView">
                          <div class="form-group" v-if="userProfileData.safe_sport_screening_compliance == true || (userProfileData.membership.id == 4 && age > 17)">
                            <label class="form-label">Background Screening Status <span v-if="userProfileData.background_screening != null && userProfileData.background_screening.background_screening_info" v-b-tooltip.hover :title="userProfileData.background_screening.background_screening_info"> <img  v-bind:src="'/dist/img/memberregistration/tooltip-icon-small.png'" alt="tooltipicon" /></span></label>
                            <span class="text-success" v-if="userProfileData.background_screening != null && userProfileData.background_screening.status == 2 && userProfileData.background_screening.background_screening_status_text != 'In Complete'"><img  class="mr-1" src="/dist/img/profile/ico-status-current.png"> {{ userProfileData.background_screening.background_screening_status_text || "N/A"}} <span class="text-success" v-if="userProfileData.background_screening.expiration != null"><br>(Expires {{ userProfileData.background_screening.expiration | formatDob }})</span></span>
                            <span class="text-danger" v-else-if="(userProfileData.background_screening != null && userProfileData.background_screening.status == 7) || (userProfileData.background_screening != null && userProfileData.background_screening.status == 3)"><img  class="mr-1" style="width: 18px;" src="/dist/img/profile/deactivated.png"> {{ userProfileData.background_screening.background_screening_status_text || "N/A"}} <br>(On {{ userProfileData.background_screening.updated_at | formatDob }})</span>
                            <span class="text-warning" v-else-if="userProfileData.background_screening != null"><img class="mr-1" src="/dist/img/profile/ico-status-pending.png"> {{userProfileData.background_screening.background_screening_status_text || "N/A"}}<template v-if="userProfileData.background_screening.status == 6 && userProfileData.background_screening.expiration"><br>(On {{ userProfileData.background_screening.expiration | formatDob }})</template></span>
                            <span class="text-warning" v-else>Not Started</span>
                          </div>
                          <div class="form-group" v-else>
                            <label class="form-label">Background Screening Status</label>
                            <span>N/A</span>
                          </div>
                        </div>
                          <div  class="col-md-4" v-show="digitalView">
                            <div class="form-group" v-if="age >= 16">
                              <label class="form-label">MVR Status <span v-if="userProfileData.background_screening != null && userProfileData.background_screening.background_screening_info" v-b-tooltip.hover :title="userProfileData.background_screening.background_screening_info"> <img  v-bind:src="'/dist/img/memberregistration/tooltip-icon-small.png'" alt="tooltipicon" /></span></label>
                              <span class="text-success" v-if="userProfileData.background_mvr_review != null && userProfileData.background_mvr_review.status == 2 && userProfileData.background_mvr_review.background_screening_status_text != 'In Complete'"><img  class="mr-1" src="/dist/img/profile/ico-status-current.png"> {{ userProfileData.background_mvr_review.background_screening_status_text || "N/A"}} <span class="text-success" v-if="userProfileData.background_mvr_review.expiration != null"><br>(Expires {{ userProfileData.background_mvr_review.expiration | formatDob }})</span></span>
                              <span class="text-danger" v-else-if="(userProfileData.background_mvr_review != null && userProfileData.background_mvr_review.status == 7) || (userProfileData.background_mvr_review != null && userProfileData.background_mvr_review.status == 3)"><img  class="mr-1" style="width: 18px;" src="/dist/img/profile/deactivated.png"> {{ userProfileData.background_mvr_review.background_screening_status_text || "N/A"}} <br>(On {{ userProfileData.background_mvr_review.updated_at | formatDob }})</span>
                              <span class="text-warning" v-else-if="userProfileData.background_mvr_review != null"><img class="mr-1" src="/dist/img/profile/ico-status-pending.png"> {{userProfileData.background_mvr_review.background_screening_status_text || "N/A"}}<template v-if="userProfileData.background_mvr_review.status == 6 && userProfileData.background_mvr_review.expiration"><br>(On {{ userProfileData.background_mvr_review.expiration | formatDob }})</template></span>
                              <span class="text-warning" v-else>Not Started</span>
                            </div>
                            <div class="form-group" v-else>
                              <label class="form-label">MVR Status</label>
                              <span>N/A</span>
                            </div>
                          </div>
                        </template>

                        <template v-if="subdomain == 'shooting'&&userProfileData.membership.member_type_id!=7">
                          <div  class="col-md-4">
                            <div class="form-group">
                              <label class="form-label">Safe Sport Training Status <a class="btn-refersh" @click.prevent="getSafeSportUserApi(null, true);">{{ safeSportRefreshButton }}</a></label>
                              <template>
                                <span class="text-success" v-if="userProfileData.safe_sport != null && userProfileData.safe_sport.safe_sport_status_text == 'Complete'"><img  class="mr-1" src="/dist/img/profile/ico-status-current.png"> {{ userProfileData.safe_sport.safe_sport_status_text}} <span v-if="userProfileData.safe_sport.expiration" class="text-success">(Expires {{ userProfileData.safe_sport.expiration }})</span></span>
                                <span class="text-warning" v-else-if="userProfileData.safe_sport != null"><img class="mr-1" src="/dist/img/profile/ico-status-pending.png"> {{userProfileData.safe_sport.safe_sport_status_text || "N/A"}} <template v-if="userProfileData.safe_sport.safe_sport_status == 13 && userProfileData.safe_sport.expiration"><br>(On {{ userProfileData.safe_sport.expiration }})</template></span>
                                <span class="text-warning" v-else>Not Started</span>
                              </template>
                            </div>
                          </div>
                          <div  class="col-md-4">
                            <div class="form-group">
                              <label class="form-label">Background Screening Status <span v-if="userProfileData.background_screening != null && userProfileData.background_screening.background_screening_info" v-b-tooltip.hover :title="userProfileData.background_screening.background_screening_info"> <img  v-bind:src="'/dist/img/memberregistration/tooltip-icon-small.png'" alt="tooltipicon" /></span></label>
                              <template>
                                <span class="text-success" v-if="userProfileData.background_screening != null && userProfileData.background_screening.status == 2"><img  class="mr-1" src="/dist/img/profile/ico-status-current.png"> {{ userProfileData.background_screening.background_screening_status_text || "N/A"}} <br><span v-if="userProfileData.background_screening.expiration" class="text-success">(Expires {{ userProfileData.background_screening.expiration | formatDob }})</span></span>
                                <span class="text-danger" v-else-if="(userProfileData.background_screening != null && userProfileData.background_screening.status == 7) || (userProfileData.background_screening != null && userProfileData.background_screening.status == 3)"><img  class="mr-1"  style="width: 18px;" src="/dist/img/profile/deactivated.png"> {{ userProfileData.background_screening.background_screening_status_text || "N/A"}} <br>(On {{ userProfileData.background_screening.updated_at | formatDob }})</span>
                                <span class="text-warning" v-else-if="userProfileData.background_screening != null"><img class="mr-1" src="/dist/img/profile/ico-status-pending.png"> {{userProfileData.background_screening.background_screening_status_text || "N/A"}}<template v-if="userProfileData.background_screening.status == 6 && userProfileData.background_screening.expiration"><br>(On {{ userProfileData.background_screening.expiration | formatDob }})</template></span>
                                <span class="text-warning" v-else>Not Started</span>
                              </template>
                            </div>
                          </div>
                        </template>
                      </div>
                    </div>
                  </div>
                  <div class="card mb-3" v-if="getSiteProps('group_model.visible')&&!(subdomain=='shooting'&&userProfileData.membership.member_type_id==7)">
                    <div class="card-header ">
                      <div class="header-title">
                        <h3 >Group Information</h3>
                      </div>
                      <div  class="modal-btn">
                        <b-button v-b-modal.modalMemberGroup><span class="fa fa-edit"></span> Edit</b-button>
                      </div>
                    </div>
                    <div class="card-body">
                      <div  class="row">
                        <div  class="col-md-12">
                          <ul class="b-form-tags-list list-unstyled mb-0 d-flex flex-wrap align-items-center" v-if="memberGroups.length">
                            <li v-for="(group,i) in memberGroups" :key="i" class="m-1 p-1 badge b-form-tag d-inline-flex align-items-baseline mw-100 btn-tag-blue btn-primary text-white"><span class="b-form-tag-content flex-grow-1 text-truncate">{{ group.name }}</span></li>
                          </ul>
                          <p v-else>No groups associated</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div  class="col-md-6 l-pad">
                  <div class="card">
                    <div class="card-header">
                      <div class="header-title">
                        <h3 >Address Information</h3>
                      </div>
                      <div  class="modal-btn">
                        <b-button v-b-modal.modal-center><span class="fa fa-edit"></span> Edit</b-button>
                      </div>
                    </div>
                    <div class="card-body">
                      <div  class="row">
                        <div class="col-md-12 col-lg-12" v-if="props_fields.contact.address_type">
                            <div class="form-group">
                                <label class="form-label">{{props_fields.contact.address_type.label || 'Address Type'}}</label>
                                <span >{{ userProfileData.address_type || "N/A"}}</span>
                            </div>
                        </div>
                        <div  class="col-md-6 col-lg-4">
                          <div class="form-group">
                            <label class="form-label">{{props_fields && props_fields.contact ? props_fields.contact.address1.label : 'Mailling Address' }}</label>
                            <span >{{userProfileData.address1 || ""}}</span>
                          </div>
                        </div>
                        <div  class="col-md-6 col-lg-4">
                          <div class="form-group">
                            <label class="form-label">{{props_fields && props_fields.contact ? props_fields.contact.address2.label : 'Address2' }}</label>
                            <span >{{userProfileData.address2 || ""}}</span>
                          </div>
                        </div>
                        <div  class="col-md-6 col-lg-4">
                          <div class="form-group">
                            <label class="form-label">City</label>
                            <span >{{userProfileData.city || ""}}</span>
                          </div>
                        </div>
                        <div  class="col-md-6 col-lg-4">
                          <div class="form-group">
                            <label class="form-label">State</label>
                            <span v-if="userProfileData.country=='USA'">{{userProfileData.state_code || ""}}</span>
                            <span v-else-if="isNotalliancefastpitch">{{userProfileData.state || ""}}</span>
                            <span v-else-if="!isNotalliancefastpitch">{{userProfileData.state_code || ""}}</span>
                          </div>
                        </div>
                        <div  class="col-md-6 col-lg-4">
                          <div class="form-group">
                            <label class="form-label">Country</label>
                            <span >{{userProfileData.country || ""}}</span>
                          </div>
                        </div>
                        <div  class="col-md-6 col-lg-4">
                          <div class="form-group">
                            <label class="form-label">Postal Code</label>
                            <span >{{userProfileData.zip || ""}}</span>
                          </div>
                        </div>
                        <div  class="col-md-6 col-lg-4" v-if="!isNotalliancefastpitch">
                          <div class="form-group">
                            <label class="form-label">Citizenship</label>
                            <span >{{userProfileData.citizenship || "N/A"}}</span>
                          </div>
                        </div>
                        <div  class="col-md-6 col-lg-4" v-if="isChild && !isNotalliancefastpitch">
                          <div class="form-group">
                            <label class="form-label">{{props_fields.contact.parent_citizenship.label_text || 'Parent/Guardian Citizenship'}}</label>
                            <span >{{UpdateProfile.parent_citizenship || "N/A"}}</span>
                          </div>
                        </div>
                        <div  class="col-md-6 col-lg-4" v-if="isChild && !isNotalliancefastpitch">
                          <div class="form-group">
                            <label class="form-label">{{props_fields.contact.athlete_citizenship.label_text || 'Child/Athlete Citizenship'}}</label>
                            <span >{{UpdateProfile.athlete_citizenship || "N/A"}}</span>
                          </div>
                        </div>
                        <div  class="col-md-6 col-lg-4" v-if="isChild && !isNotalliancefastpitch">
                          <div class="form-group">
                            <label class="form-label">{{props_fields.contact.eligible_citizenship.label_text || 'Eligibile Child/Athlete Citizenship'}}</label>
                            <span >{{UpdateProfile.eligible_citizenship || "N/A"}}</span>
                          </div>
                        </div>
                        <div  class="col-md-6 col-lg-4" v-if="subdomain != 'shooting' && isNotalliancefastpitch ">
                          <div class="form-group">
                            <label class="form-label">Region</label>
                            <span>{{userProfileData.country =="USA" ? userProfileData.region : "Foreign"}}</span>
                          </div>
                        </div>
                        <template v-if="props_fields.contact.address_type && userProfileData.address_type == 'Work'">
                            <div  class="col-md-6 col-lg-4" v-if="props_fields.contact.work_address_company_name">
                                <div class="form-group">
                                    <label class="form-label">{{props_fields.contact.work_address_company_name.label || 'Company Name'}}</label>
                                    <span >{{ userProfileData.company_name || "N/A"}}</span>
                                </div>
                            </div>
                            <div  class="col-md-6 col-lg-6" v-if="props_fields.contact.work_address_company_phone_extension">
                                <div class="form-group">
                                    <label class="form-label">{{props_fields.contact.work_address_company_phone_extension.label || 'Company Phone with Extension'}}</label>
                                    <span >{{ userProfileData.company_phone ? userProfileData.company_phone+" Ext:"+(userProfileData.company_phone_extension || " N/A") : "N/A"}}</span>
                                </div>
                            </div>
                        </template>

                        <div class="col-md-6 col-lg-4" v-if="enableMagazine(userProfileData)">
                          <div class="form-group">
                            <label class="form-label">Printed copy of the Water Skier magazine</label>
                            <span class="text-warning" v-if="userProfileData.printed_magazine == 1"><img class="mr-1" src="/dist/img/profile/ico-status-pending.png" /> Not Subscribed</span>
                            <span class="text-success" v-else><img class="mr-1" src="/dist/img/profile/ico-status-current.png" /> Subscribed</span>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="form-group" v-if="subdomain == 'waterski'">
                      <div class="form-check ml-4">
                          <input type="checkbox" id="bad_address" name="updateAddressInfo.bad_address" class="form-check-input" @click="changeBadAddress(UpdateProfile.bad_address)"  v-model="UpdateProfile.bad_address" >
                          <label for="bad_address" class="d-flex mr-1">UnDeliverable Address</label>
                      </div>
                    </div>
                  </div>
                  <div class="card">
                    <div class="card-header">
                      <div class="header-title">
                        <h3 >Contact Information</h3>
                      </div>
                      <div  class="modal-btn">
                        <b-button v-b-modal.modal-center3><span class="fa fa-edit"></span> Edit</b-button>
                      </div>
                    </div>
                    <div class="card-body">
                      <div  class="row">
                        <div  class="col-md-6 col-lg-4 " v-if="!isNotalliancefastpitch && this.userProfileData.membership.id != 5">
                          <div class="form-group">
                            <label class="form-label">Parent First Name</label>
                            <span >{{userProfileData.contact_details.first_name || "N/A"}}</span>
                          </div>
                        </div>
                        <div  class="col-md-6 col-lg-4 " v-if="!isNotalliancefastpitch && this.userProfileData.membership.id != 5">
                          <div class="form-group">
                            <label class="form-label">Parent Middle Name</label>
                            <span >{{userProfileData.contact_details.middle_name || "N/A"}}</span>
                          </div>
                        </div>
                        <div  class="col-md-6 col-lg-4 " v-if="!isNotalliancefastpitch && this.userProfileData.membership.id != 5">
                          <div class="form-group">
                            <label class="form-label">Parent Last Name</label>
                            <span >{{userProfileData.contact_details.last_name || "N/A"}}</span>
                          </div>
                        </div>
                        <div  class="col-md-6 col-lg-4 ">
                          <div class="form-group">
                            <label class="form-label">{{props_fields && props_fields.contact ? props_fields.contact.homephone.label : 'Home Phone' }}</label>
                            <span >{{userProfileData.phone_1 || "N/A"}}</span>
                          </div>
                        </div>
                        <div  class="col-md-6 col-lg-4">
                          <div class="form-group">

                            <label class="form-label">{{props_fields && props_fields.contact ? props_fields.contact.mobilephone.label : 'Mobile Phone' }}</label>
                            <span >{{userProfileData.phone_2 || "N/A"}}</span>
                          </div>
                        </div>
                        <div  class="col-md-6 col-lg-4">
                          <div class="form-group">
                            <label class="form-label">{{props_fields && props_fields.contact ? props_fields.contact.email.label : 'Email' }}</label>
                            <span ><a class="email-link" v-bind:href="'mailto:' + userProfileData.email ">{{userProfileData.email || "N/A"}}</a></span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="card mb-5" v-if="isNotalliancefastpitch&&!(subdomain=='shooting'&&userProfileData.membership.member_type_id==7)" v-show="digitalView">
                            <div class="card-header">
                            <div class="header-title">
                                <h3>{{ props_fields.demographic.title || 'Additional Information'}}</h3>
                            </div>
                            <div  class="modal-btn" v-if="userProfileData.personal.relation===0">
                                <b-button v-b-modal.edit-demographic><span class="fa fa-edit"></span> Edit</b-button>
                            </div>
                            </div>
                            <div class="card-body">
                            <div  class="row">
                                <div  class="col-md-6 col-lg-4 " v-if="props_fields && props_fields.demographic && props_fields.demographic.race">
                                    <div class="form-group">
                                        <label class="form-label">{{props_fields && props_fields.demographic ? props_fields.demographic.race.label : 'Race' }}</label>
                                        <span >{{userProfileData.personal.diversity.name || "N/A"}}</span>
                                    </div>
                                </div>
                                <div  class="col-md-6 col-lg-4" v-if="props_fields && props_fields.demographic && props_fields.demographic.ethnicity">
                                    <div class="form-group">
                                        <label class="form-label">{{props_fields && props_fields.demographic ? props_fields.demographic.ethnicity.label : 'Ethnicity' }}</label>
                                        <span >{{userProfileData.personal.ethnicity || "N/A"}}</span>
                                    </div>
                                </div>
                                <div  class="col-md-6 col-lg-4" v-if="props_fields && props_fields.demographic && props_fields.demographic.veteran_status">
                                    <div class="form-group">
                                        <label class="form-label">{{props_fields && props_fields.demographic ? props_fields.demographic.veteran_status.label : 'Veteran Status' }}</label>
                                        <span >{{userProfileData.personal.veteran_status || "N/A"}}</span>
                                    </div>
                                </div>
                                <div  class="col-md-6 col-lg-4" v-if="props_fields && props_fields.demographic && props_fields.demographic.disability_status">
                                    <div class="form-group">
                                        <label class="form-label">{{props_fields && props_fields.demographic ? props_fields.demographic.disability_status.label : 'Disability Status' }}</label>
                                        <span >{{userProfileData.personal.disability || "N/A"}}</span>
                                    </div>
                                </div>
                                <div  class="col-md-6 col-lg-4" v-if="props_fields && props_fields.demographic && props_fields.demographic.primary_area_interest">
                                <div class="form-group">
                                    <label class="form-label">{{props_fields && props_fields.demographic ? props_fields.demographic.primary_area_interest.label : 'Primary Role of Interest' }}</label>
                                    <span >{{userProfileData.personal.primary_area_interest || "N/A"}}</span>
                                </div>
                                </div>

                                <div  class="col-md-6 col-lg-4" v-if="props_fields && props_fields.demographic && props_fields.demographic.category">
                                <div class="form-group">
                                    <label class="form-label">{{props_fields && props_fields.demographic ? props_fields.demographic.category.label : 'Category' }}</label>
                                    <span >{{userProfileData.personal.shooting_category ? userProfileData.personal.shooting_category.name : "N/A"}}</span>
                                </div>
                                </div>

                                <div  class="col-md-6 col-lg-6" v-if="props_fields && props_fields.demographic && props_fields.demographic.issf">
                                <div class="form-group">
                                    <label class="form-label">{{props_fields && props_fields.demographic ? props_fields.demographic.issf.label : 'ISSF License Certification Number' }} </label>
                                    <span >{{userProfileData.personal.issf || "N/A"}}</span>
                                </div>
                                </div>
                                <div class="col-md-6 col-lg-6" v-if="props_fields && props_fields.demographic && props_fields.demographic.cmp">
                                  <div class="form-group">
                                      <label class="form-label">{{props_fields && props_fields.demographic ? props_fields.demographic.cmp.label : 'CMP Membership #' }} </label>
                                      <span >{{userProfileData.personal.cmp_no || "N/A"}}</span>
                                  </div>
                                </div>
                                <div class="col-md-6 col-lg-6" v-if="props_fields && props_fields.demographic && props_fields.demographic.nra">
                                  <div class="form-group">
                                      <label class="form-label">{{props_fields && props_fields.demographic ? props_fields.demographic.nra.label : 'NRA Membership #' }} </label>
                                      <span >{{userProfileData.personal.nra_no || "N/A"}}</span>
                                  </div>
                                </div>
                                <div class="col-md-6 col-lg-6" v-if="props_fields && props_fields.demographic && props_fields.demographic.sasp">
                                  <div class="form-group">
                                      <label class="form-label">{{props_fields && props_fields.demographic ? props_fields.demographic.sasp.label : 'SASP Membership #' }} </label>
                                      <span >{{userProfileData.personal.sasp_no || "N/A"}}</span>
                                  </div>
                                </div>
                                <div class="col-md-6 col-lg-6" v-if="props_fields && props_fields.demographic && props_fields.demographic.sctp">
                                  <div class="form-group">
                                      <label class="form-label">{{props_fields && props_fields.demographic ? props_fields.demographic.sctp.label : 'SCTP Membership #' }} </label>
                                      <span >{{userProfileData.personal.sctp_no || "N/A"}}</span>
                                  </div>
                                </div>
                                <div  class="col-md-6 col-lg-6" v-if="props_fields && props_fields.demographic && props_fields.demographic.hear_about">
                                <div class="form-group">
                                    <label class="form-label">{{props_fields && props_fields.demographic ? props_fields.demographic.hear_about.label : 'How did you hear about USA Shooting' }}</label>
                                    <span >{{userProfileData.personal.hear_about || "N/A"}}</span>
                                </div>
                                </div>
                            </div>
                            </div>
                        </div>
                </div>
              </div>
        </div>
        <div class="row profile-body profile-page-wrapper" v-if="userProfileData.membership.member_type_id==3">
                 <div v-if="isProfileloading==true" class="d-flex justify-content-center userpf-loader">
                    <div  class="spinner-border" role="status">
                      <span class="sr-only">Loading...</span>
                    </div>
                  </div>
                 <div class="col-md-12">
                   <div class="card table-head Merchandise">
                     <div class="card-header">
                       <div class="header-title">
                         <h3>Family Members</h3>
                       </div>
                       <div class="m-0 float-sm-right">
                         <b-button class="btn btn-primary add-family-btn d-none py-0" @click="addFamilyMember()"><i class="fa fa-plus"></i> Add Member</b-button>
                       </div>

                     </div>
                     <div class="card-body p-0 table-responsive">
                       <b-tabs content-class="mt-3" justified  pills card>
                         <b-tab v-for="(member, index) in userProfileData.families"  :key="'types'+index"
                                :title="member.personal_details.full_name+' ('+relationArr[member.personal_details.relation][0]+')'" :active="currentMemberId==member.id">
                           <div class="row">
                             <div class="col-md-6 r-pad">
                               <div class="card mb-3">
                                 <div class="card-header container-fluid">
                                   <div class="row">
                                     <div class="col-md-5">
                                       <h3 v-if="isNotalliancefastpitch" > {{ props_fields.personal.tab_title || "Personal Information"}}</h3>
                                       <h3 v-if="!isNotalliancefastpitch"> {{ (userProfileData.membership.name == 'Staff')?'Coach Information':props_fields.personal.tab_title }}</h3>
                                     </div>
                                     <div class="col-md-7 float-right">

                                       <button v-if="currentMemberId==member.id" @click="resetForm()" v-b-modal.modal-center1 class="btn btn-outline-primary btn-sm float-right ml-1"  ><span class="fa fa-edit"></span> Edit</button>
                                       <button v-else  :class="{ 'actions-click': className }" class="btn btn-outline-primary btn-sm float-right ml-1 " @click="familyprofileview(member.id)"><span class="fa fa-eye"></span> Profile</button>
                                       <button class="btn btn-outline-primary btn-sm float-right" @click="impersonate(member.confirmation_code)" ><span class="fa fa-user-secret"></span> Login as {{member.confirmation_code || "N/A"}}</button>
                                     </div>
                                   </div>

                                 </div>
                                 <div class="card-body">
                                 <div  class="row">
                                   <div  class="col-md-6 col-lg-4" v-if="props_fields.personal && props_fields.personal.title">
                                     <div class="form-group">
                                       <label class="form-label">{{ props_fields.personal.title.label || 'Title'}}</label>
                                       <span >{{member.personal_details.title || "N/A"}}</span>
                                     </div>
                                   </div>
                                   <div  class="col-md-6 col-lg-4 ">
                                     <div class="form-group">
                                       <label class="form-label">First Name</label>
                                       <span >{{member.personal_details.first_name || "N/A"}}</span>
                                     </div>
                                   </div>
                                   <div  class="col-md-6 col-lg-4">
                                     <div class="form-group">
                                       <label class="form-label">Middle Initial</label>
                                       <span >{{member.personal_details.middle_name || ""}}</span>
                                     </div>
                                   </div>
                                   <div  class="col-md-6 col-lg-4">
                                     <div class="form-group">
                                       <label class="form-label" >Last Name</label>
                                       <span >{{member.personal_details.last_name || "N/A"}}</span>
                                     </div>
                                   </div>

                                   <div  class="col-md-6 col-lg-4 " v-if="subdomain === 'shooting'">
                                     <div class="form-group">
                                       <label class="form-label">Suffix</label>
                                       <span >{{member.personal_details.suffix || "N/A"}}</span>
                                     </div>
                                   </div>

                                   <div  class="col-md-6 col-lg-4">
                                     <div class="form-group">
                                       <label class="form-label"> {{subdomain === 'shooting' ? "Date of Birth" : "Birthday"}}</label>
                                       <span >{{member.personal_details.dob || "N/A"}}</span>
                                     </div>
                                   </div>
                                   <div  class="col-md-6 col-lg-4">
                                     <div class="form-group">
                                       <label class="form-label">Gender</label>
                                       <span >{{member.personal_details.gender || "N/A"}}</span>
                                     </div>
                                   </div>
                                   <div class="col-md-6 col-lg-4" v-if="subdomain !== 'shooting'">
                                     <div class="form-group">
                                       <label class="form-label">Affiliated Clubs/Teams</label>
                                       <span class="title">{{ member.affiliated_clubs_names || "N/A"}}</span>
                                     </div>
                                   </div>
                                   <div class="col-md-6 col-lg-4" v-if="subdomain !== 'shooting'">
                                     <div class="form-group">
                                       <label class="form-label">1st Choice</label>
                                       <span class="title">{{ sportDisciplines[member.personal_details.first_choice] || "NP - No Preference" }}</span>
                                     </div>
                                   </div>
                                   <div class="col-md-6 col-lg-4" v-if="subdomain !== 'shooting'">
                                     <div class="form-group">
                                       <label class="form-label">2nd Choice</label>
                                       <span class="title">{{ sportDisciplines[member.personal_details.second_choice] || "NP - No Preference"}}</span>
                                     </div>
                                   </div>
                                   <div  class="col-md-6 col-lg-4" v-if="subdomain !== 'shooting'">
                                     <div class="form-group">
                                       <label class="form-label">Federation </label>
                                       <span >{{member.personal_details.home_federation || "N/A"}}</span>
                                     </div>
                                   </div>
                                   <div  class="col-md-6 col-lg-4" v-if="subdomain !== 'shooting'">
                                     <div class="form-group">
                                       <label class="form-label">Skier License</label>
                                       <span >{{member.personal_details.skier_license || "N/A"}}</span>
                                     </div>
                                   </div>
                                   <div  class="col-md-6 col-lg-4" v-if="subdomain === 'shooting'">
                                     <div class="form-group">
                                       <label class="form-label">Are you a Veteran ? </label>
                                       <span >{{member.personal_details.veteran_status || "N/A"}}</span>
                                     </div>
                                   </div>
                                  <div class="col-md-6 col-lg-4">
                                    <div class="form-group">
                                       <label class="form-label">Relationship</label>
                                       <span >{{relationArr[member.personal_details.relation] || "N/A"}}</span>
                                     </div>
                                  </div>
                                  <div :class="props_fields.general.sport_discipline.first_choice.disColClass" v-if="member.disciplines && member.disciplines.length">
                                    <div class="form-group">
                                      <label class="form-label">Disciplines of Interest</label>
                                      <ul :class="(props_fields.general.sport_discipline.first_choice.multiple) ? 'b-form-tags-list list-unstyled mb-0 d-flex flex-wrap align-items-center' : 'pl-3'">
                                        <li v-for="(discipline,i) in member.disciplines" :key="i" :class="(props_fields.general.sport_discipline.first_choice.multiple)? 'm-1 p-1 badge b-form-tag d-inline-flex align-items-baseline mw-100 btn-tag-blue btn-primary':''"><span :class="(props_fields.general.sport_discipline.first_choice.multiple)?'b-form-tag-content flex-grow-1 text-truncate text-white':''">{{ discipline.name }}</span></li>
                                      </ul>
                                    </div>
                                  </div>
                                  <div class="col-md-6 col-lg-4" v-if="!isNotalliancefastpitch && isChild">
                                     <div class="form-group">
                                       <label class="form-label">AGL Player ID#</label>
                                       <span >{{relationArr[member.personal_details.agl_player_id] || "N/A"}}</span>
                                     </div>
                                   </div>
                                  <div class="col-md-6 col-lg-4" v-if="!isNotalliancefastpitch && isChild">
                                     <div class="form-group">
                                       <label class="form-label">Graduation Year</label>
                                       <span >{{relationArr[member.personal_details.graduation_year] || "N/A"}}</span>
                                     </div>
                                   </div>
                                  <div class="col-md-6 col-lg-4" v-if="!isNotalliancefastpitch && isChild">
                                     <div class="form-group">
                                       <label class="form-label">Jersey Number</label>
                                       <span >{{relationArr[member.personal_details.jersey_number] || "N/A"}}</span>
                                     </div>
                                   </div>
                                  <div class="col-md-6 col-lg-4" v-if="!isNotalliancefastpitch && isChild">
                                     <div class="form-group">
                                       <label class="form-label">Primary Position</label>
                                       <span >{{relationArr[member.personal_details.primary_position] || "N/A"}}</span>
                                     </div>
                                   </div>
                                  <div class="col-md-6 col-lg-4" v-if="!isNotalliancefastpitch && isChild">
                                     <div class="form-group">
                                       <label class="form-label">Secondary Position</label>
                                       <span >{{relationArr[member.personal_details.secondary_position] || "N/A"}}</span>
                                     </div>
                                   </div>
                                  <div class="col-md-6 col-lg-4" v-if="!isNotalliancefastpitch && isChild">
                                     <div class="form-group">
                                       <label class="form-label">Throws</label>
                                       <span >{{relationArr[member.personal_details.throws] || "N/A"}}</span>
                                     </div>
                                   </div>
                                  <div class="col-md-6 col-lg-4" v-if="!isNotalliancefastpitch && isChild">
                                     <div class="form-group">
                                       <label class="form-label">Bats</label>
                                       <span >{{relationArr[member.personal_details.bats] || "N/A"}}</span>
                                     </div>
                                   </div>
                                  <div class="col-md-6 col-lg-4" v-if="!isNotalliancefastpitch && isChild">
                                     <div class="form-group">
                                       <label class="form-label">College Committed</label>
                                       <span >{{relationArr[member.personal_details.college_committed] || "N/A"}}</span>
                                     </div>
                                   </div>
                                 </div>
                               </div>
                               </div>
                               <div class="card mb-3">
                                 <div class="card-header ">
                                   <div class="header-title">
                                     <h3 >Membership Information</h3>
                                   </div>
                                   <div  class="modal-btn" v-if="currentMemberId==member.id">
                                     <b-button v-b-modal.modalMembership><span class="fa fa-edit"></span> Edit</b-button>
                                   </div>
                                 </div>
                                 <div class="card-body">
                                   <div  class="row">
                                     <div  class="col-md-4">
                                       <div class="form-group">
                                         <label class="form-label">Membership #</label>
                                         <span>{{member.confirmation_code || "N/A"}}</span>
                                       </div>
                                     </div>
                                     <div  class="col-md-4">
                                       <div class="form-group">
                                         <label class="form-label">Start Date</label>
                                         <span>{{ startDate }}</span>
                                       </div>
                                     </div>
                                     <div  class="col-md-4">
                                       <div class="form-group">
                                         <label class="form-label">Expiration Date</label>
                                         <span>{{ userProfileData && userProfileData.membership && userProfileData.membership.period != 999 ? validThru : "N/A"}}</span>
                                       </div>
                                     </div>
                                     <div  class="col-md-4">
                                       <div class="form-group">
                                         <div class="d-flex">
                                           <label class="form-label">Membership Status  <span v-if="member.membership_status_info" v-b-tooltip.hover :title="member.membership_status_info"> 
                                             <img  style="width:18px;" v-if="getSiteProps('profile.membershipStatusIcon')" v-bind:src="'/dist/img/memberregistration/tooltip-icon-small.png'" alt="tooltipicon" />
                                            </span></label>
                                           <span class="text-danger bordernote" v-if="member.note" v-b-tooltip.hover :title="member.note"><i class="fas fa-clipboard"></i></span>
                                         </div>

                                         <span class="text-success" v-if="member.status_text == 'Current'"><img class="mr-1" src="/dist/img/profile/ico-status-current.png"> {{ member.status_text }} (Expires {{member.valid_thru | formatDob }})</span>
                                         <span class="text-warning" v-else-if="member.status_text != 'Expired'"><img class="mr-1" src="/dist/img/profile/ico-status-pending.png"> {{ member.status_text || 'N/A' }}</span>
                                         <span class="text-warning" v-else> <img class="mr-1" src="/dist/img/profile/ico-status-pending.png"> Expired</span>
                                       </div>
                                     </div>
                                     <div  class="col-md-4" v-if="member.member_type_id != 2">
                                      <div class="form-group">
                                        <label class="form-label">Waiver Status</label>
                                        <!-- <template v-if="(subdomain == 'shooting' && member.registration[0] != undefined && member.registration[0].waivers != undefined && member.registration[0].waivers.length) || (subdomain == 'waterski' && (member.personal_details.relation == 2 || member.safe_sport_screening_compliance == true))"> -->
                                        <template v-if="(subdomain == 'shooting' && userProfileData.registration[0] != undefined && userProfileData.registration[0].waivers != undefined && userProfileData.registration[0].waivers.length) || subdomain == 'waterski' ">
                                          <span class="text-success" v-if="member.waiver_status == 1"><img class="mr-1" src="/dist/img/profile/ico-status-current.png"> Signed (Expires {{ userProfileData && userProfileData.membership && userProfileData.membership.period != 999 ? validThru : "N/A" }})</span>
                                          <span class="text-warning"  v-else> <img class="mr-1" src="/dist/img/profile/ico-status-pending.png"> Not signed</span>
                                        </template>
                                        <span v-else>N/A</span>
                                      </div>
                                    </div>
                                    <template v-if="subdomain == 'waterski'">
                                     <div  class="col-md-4">
                                       <div class="form-group" v-if="member.safe_sport_screening_compliance == true">
                                         <label class="form-label" >Safe Sport Training Status <a v-if="member.safe_sport != null" class="btn-refersh" @click.prevent="getSafeSportUserApi(member.confirmation_code, true);">{{ safeSportRefreshButton }}</a></label>
                                         <span class="text-success" v-if="member.safe_sport != null && member.safe_sport.safe_sport_status_text == 'Complete'"><img  class="mr-1" src="/dist/img/profile/ico-status-current.png"> {{member.safe_sport.safe_sport_status_text}} (Expires {{ member.safe_sport.expiration }})</span>
                                         <span class="text-warning" v-else-if="member.safe_sport != null"><img class="mr-1" src="/dist/img/profile/ico-status-pending.png"> {{member.safe_sport.safe_sport_status_text || "N/A"}}<template v-if="member.safe_sport.safe_sport_status == 13 && member.safe_sport.expiration"><br>(On {{ member.safe_sport.expiration }})</template></span>
                                         <span class="text-warning" v-else>Not Started</span>
                                       </div>
                                       <div class="form-group" v-else>
                                         <label class="form-label">Safe Sport Training Status</label>
                                         <span class="">N/A</span>
                                       </div>
                                     </div>
                                      <div  class="col-md-4">
                                        <div class="form-group" v-if="member.safe_sport_screening_compliance == true || (member.membership.id == 5 && member.personal_details.relation != 2 && age > 17) || (member.membership.id == 4 && age > 17)">
                                          <label class="form-label">Background Screen Status <span v-if="member.background_screening_with_out_type != null && member.background_screening_with_out_type.background_screening_info" v-b-tooltip.hover :title="member.background_screening_with_out_type.background_screening_info"> <img  v-bind:src="'/dist/img/memberregistration/tooltip-icon-small.png'" alt="tooltipicon" /></span></label>
                                          <span class="text-success" v-if="member.background_screening_with_out_type != null && member.background_screening_with_out_type.status == 2"><img  class="mr-1" src="/dist/img/profile/ico-status-current.png"> {{ member.background_screening_with_out_type.background_screening_status_text || "N/A"}} (Expires {{ member.background_screening_with_out_type.expiration | formatDob }})</span>
                                          <span class="text-danger" v-else-if="(member.background_screening_with_out_type != null && member.background_screening_with_out_type.status == 7) && (member.background_screening_with_out_type != null && member.background_screening_with_out_type.status == 3)"><img  class="mr-1" src="/dist/img/profile/deactivated.png"> {{ member.background_screening_with_out_type.background_screening_status_text || "N/A"}} (Expires {{ member.background_screening_with_out_type.updated_at | formatDob }})</span>
                                          <span class="text-warning" v-else-if="member.background_screening_with_out_type != null"><img class="mr-1" src="/dist/img/profile/ico-status-pending.png"> {{member.background_screening_with_out_type.background_screening_status_text || "N/A"}}<template v-if="member.background_screening_with_out_type.status == 6 && member.background_screening_with_out_type.expiration"><br>(On {{ member.background_screening_with_out_type.expiration | formatDob }})</template></span>
                                          <span class="text-warning" v-else>Not Started</span>
                                        </div>
                                        <div class="form-group" v-else>
                                          <label class="form-label">Background Screen Status</label>
                                          <span>N/A</span>
                                        </div>
                                      </div>
                                      <div  class="col-md-4">
                                        <div class="form-group" v-if="age >= 16">
                                          <label class="form-label">MVR Status <span v-if="member.background_screening != null && member.background_screening.background_screening_info" v-b-tooltip.hover :title="member.background_screening.background_screening_info"> <img  v-bind:src="'/dist/img/memberregistration/tooltip-icon-small.png'" alt="tooltipicon" /></span></label>
                                          <span class="text-success" v-if="member.background_mvr_review != null && member.background_mvr_review.status == 2"><img  class="mr-1" src="/dist/img/profile/ico-status-current.png"> {{ member.background_mvr_review.background_screening_status_text || "N/A"}} (Expires {{ member.background_mvr_review.expiration | formatDob }})</span>
                                          <span class="text-danger" v-else-if="(member.background_mvr_review != null && member.background_mvr_review.status == 7) || (member.background_mvr_review != null && member.background_mvr_review.status == 3)"><img  class="mr-1"  style="width: 18px;" src="/dist/img/profile/deactivated.png"> {{ member.background_mvr_review.background_screening_status_text || "N/A"}} (On {{ member.background_mvr_review.updated_at | formatDob }})</span>
                                          <span class="text-warning" v-else-if="member.background_mvr_review != null"><img class="mr-1" src="/dist/img/profile/ico-status-pending.png"> {{member.background_mvr_review.background_screening_status_text || "N/A"}}<template v-if="member.background_mvr_review.status == 6 && member.background_mvr_review.expiration"><br>(On {{ member.background_mvr_review.expiration | formatDob }})</template></span>
                                          <span class="text-warning" v-else>Not Started</span>
                                        </div>
                                        <div class="form-group" v-else>
                                          <label class="form-label">MVR Status</label>
                                          <span>N/A</span>
                                        </div>
                                      </div>
                                    </template>
                                    <template v-if="subdomain == 'shooting'">
                                      <div  class="col-md-4">
                                        <div class="form-group">
                                          <label class="form-label">Safe Sport Training Status <a class="btn-refersh" @click.prevent="getSafeSportUserApi(member.confirmation_code, true);">{{ safeSportRefreshButton }}</a></label>
                                          <span class="text-success" v-if="member.safe_sport != null && member.safe_sport.safe_sport_status_text == 'Complete'"><img  class="mr-1" src="/dist/img/profile/ico-status-current.png"> {{ member.safe_sport.safe_sport_status_text}} <span v-if="member.safe_sport.expiration">(Expires {{ member.safe_sport.expiration }})</span></span>
                                          <span class="text-warning" v-else-if="member.safe_sport != null"><img class="mr-1" src="/dist/img/profile/ico-status-pending.png"> {{member.safe_sport.safe_sport_status_text || "N/A"}}<template v-if="member.safe_sport.safe_sport_status == 13 && member.safe_sport.expiration"><br>(On {{ member.safe_sport.expiration }})</template></span>
                                          <span class="text-warning" v-else>Not Started</span>
                                        </div>
                                      </div>
                                      <div  class="col-md-4">
                                        <div class="form-group">
                                          <label class="form-label">Background Screening Status <span v-if="member.background_screening != null && member.background_screening.background_screening_info" v-b-tooltip.hover :title="member.background_screening.background_screening_info"> <img  v-bind:src="'/dist/img/memberregistration/tooltip-icon-small.png'" alt="tooltipicon" /></span></label>
                                          <span class="text-success" v-if="member.background_screening != null && member.background_screening.status == 2"><img  class="mr-1" src="/dist/img/profile/ico-status-current.png"> {{ member.background_screening.background_screening_status_text || "N/A"}} (Expires {{ member.background_screening.expiration | formatDob }})</span>
                                          <span class="text-danger" v-else-if="(member.background_screening != null && member.background_screening.status == 7) || (member.background_screening != null && member.background_screening.status == 3)"><img  style="width: 18px;" class="mr-1" src="/dist/img/profile/deactivated.png"> {{ member.background_screening.background_screening_status_text || "N/A"}} (On {{ member.background_screening.updated_at | formatDob }})</span>
                                          <span class="text-warning" v-else-if="member.background_screening != null"><img class="mr-1" src="/dist/img/profile/ico-status-pending.png"> {{member.background_screening.background_screening_status_text || "N/A"}}<template v-if="member.background_screening.status == 6 && member.background_screening.expiration"><br>(On {{ member.background_screening.expiration | formatDob }})</template></span>
                                          <span class="text-warning" v-else>Not Started</span>
                                        </div>
                                      </div>
                                    </template>
                                   </div>
                                 </div>
                               </div>
                               <div class="card mb-3" v-if="getSiteProps('group_model.visible') && (member.groups.length || currentMemberId==member.id)">
                                <div class="card-header ">
                                  <div class="header-title">
                                    <h3 >Group Information</h3>
                                  </div>
                                  <div  class="modal-btn" v-if="currentMemberId==member.id">
                                    <b-button v-b-modal.modalMemberGroup><span class="fa fa-edit"></span> Edit</b-button>
                                  </div>
                                </div>
                                <div class="card-body">
                                  <div  class="row">
                                    <div  class="col-md-12">
                                      <ul class="b-form-tags-list list-unstyled mb-0 d-flex flex-wrap align-items-center" v-if="member.groups.length">
                                        <li v-for="(group,i) in member.groups" :key="i" class="m-1 p-1 badge b-form-tag d-inline-flex align-items-baseline mw-100 btn-tag-blue btn-primary text-white"><span class="b-form-tag-content flex-grow-1 text-truncate">{{ group.name }}</span></li>
                                      </ul>
                                      <p v-else>No groups associated</p>
                                    </div>                       
                                  </div>
                                </div>
                              </div>
                             </div>
                             <div  class="col-md-6 l-pad">
                               <div class="card">
                                 <div class="card-header">
                                   <div class="header-title">
                                     <h3>Address Information</h3>
                                   </div>
                                   <div  class="modal-btn" v-if="currentMemberId==member.id">
                                     <b-button v-b-modal.modal-center><span class="fa fa-edit"></span> Edit</b-button>
                                   </div>
                                 </div>
                                 <div class="card-body">
                                   <div  class="row">
                                      <div  class="col-md-6 col-lg-4 " v-if="subdomain === 'shooting'">
                                       <div class="form-group">
                                         <label class="form-label">Address Type</label>
                                         <span >{{member.contact_details.address_type || "N/A"}}</span>
                                       </div>
                                     </div>
                                   </div>
                                   <div class="row">
                                   <div  class="col-md-6 col-lg-4">
                                       <div class="form-group">
                                         <label class="form-label">{{ subdomain === 'shooting' ? "Address Line 1" : "Mailling Address" }}</label>
                                         <span >{{member.contact_details.address1 || "N/A"}}</span>
                                       </div>
                                     </div>
                                     <div  class="col-md-6 col-lg-4">
                                       <div class="form-group">
                                         <label class="form-label">Address2</label>
                                         <span >{{member.contact_details.address2 || ""}}</span>
                                       </div>
                                     </div>
                                     <div  class="col-md-6 col-lg-4">
                                       <div class="form-group">
                                         <label class="form-label">City</label>
                                         <span >{{member.contact_details.city || "N/A"}}</span>
                                       </div>
                                     </div>
                                     <div  class="col-md-6 col-lg-4">
                                       <div class="form-group">
                                         <label class="form-label">State</label>
                                         <span v-if="member.contact_details.country=='USA'">{{member.contact_details.state_code || "N/A"}}</span>
                                         <span v-else>{{member.contact_details.state || "N/A"}}</span>
                                       </div>
                                     </div>
                                     <div  class="col-md-6 col-lg-4">
                                       <div class="form-group">
                                         <label class="form-label">Country</label>
                                         <span >{{member.contact_details.country || ""}}</span>
                                       </div>
                                     </div>
                                     <div  class="col-md-6 col-lg-4">
                                       <div class="form-group">
                                         <label class="form-label">Postal Code</label>
                                         <span >{{member.contact_details.zip || "N/A"}}</span>
                                       </div>
                                     </div>
                                     <div  class="col-md-6 col-lg-4 " v-if="getSiteProps('region.visible')">
                                       <div class="form-group">
                                         <label class="form-label">Region</label>
                                         <span >{{userProfileData.country =="USA" ? userProfileData.region : "Foreign"}}</span>
                                         
                                       </div>
                                     </div>
                                     <template v-if="props_fields.contact.address_type && member.contact_details.address_type == 'Work'">
                                        <div  class="col-md-6 col-lg-4" v-if="props_fields.contact.work_address_company_name">
                                            <div class="form-group">
                                                <label class="form-label">{{props_fields.contact.work_address_company_name.label || 'Company Name'}}</label>
                                                <span >{{ member.contact_details.company_name || "N/A"}}</span>
                                            </div>
                                        </div>
                                        <div  class="col-md-6 col-lg-6" v-if="props_fields.contact.work_address_company_phone_extension">
                                            <div class="form-group">
                                                <label class="form-label">{{props_fields.contact.work_address_company_phone_extension.label || 'Company Phone with Extension'}}</label>
                                                <span >{{ member.contact_details.company_phone ? member.contact_details.company_phone+" Ext:"+(member.contact_details.company_phone_extension || " N/A") : "N/A"}}</span>
                                            </div>
                                        </div>
                                    </template>
                                   <div class="col-md-6 col-lg-4" v-if="enableMagazine(member)">
                                    <div class="form-group">
                                      <label class="form-label">Printed copy of the Water Skier magazine</label>
                                      <span class="text-warning" v-if="member.printed_magazine == 1"><img class="mr-1" src="/dist/img/profile/ico-status-pending.png" /> Not Subscribed</span>
                                      <span class="text-success" v-else><img class="mr-1" src="/dist/img/profile/ico-status-current.png" /> Subscribed </span>
                                    </div>
                                  </div>
                                   </div>
                                </div>
                                <div class="form-group" v-if="subdomain == 'waterski'">
                                  <div class="form-check ml-4" v-if="currentMemberId==member.id">
                                    
                                      <input type="checkbox" id="bad_address" name="updateAddressInfo.bad_address" class="form-check-input" @click="changeBadAddress(UpdateProfile.bad_address)"  v-model="UpdateProfile.bad_address" >
                                      <label for="bad_address" class="d-flex mr-1">UnDeliverable Address</label>
                                    </div>
                                    <div class="ml-4"  v-else-if="member.contact_details.bad_address==1">
                                      <label for="bad_address" class="d-flex mr-1 text-danger"><i class="fas fa-times-circle" style="margin-top:4px"></i><b>&nbsp; UnDeliverable Address</b></label>
                                    </div>
                                 
                                </div>
                             </div>
                               <div class="card">
                                 <div class="card-header">
                                   <div class="header-title">
                                     <h3 >Contact Information</h3>
                                   </div>
                                   <div  class="modal-btn" v-if="currentMemberId==member.id">
                                     <b-button v-b-modal.modal-center3><span class="fa fa-edit"></span> Edit</b-button>
                                   </div>
                                 </div>
                                 <div class="card-body">
                                   <div  class="row">
                                     <div  class="col-md-6 col-lg-4 ">
                                       <div class="form-group">
                                         <label class="form-label">{{ props_fields.contact.homephone.label || 'Home Phone'}}</label>
                                         <span >{{member.contact_details.phone_1 || "N/A"}}</span>
                                       </div>
                                     </div>
                                     <div  class="col-md-6 col-lg-4">
                                       <div class="form-group">

                                         <label class="form-label">{{ props_fields.contact.mobilephone.label || 'Mobile Phone'}}</label>
                                         <span >{{member.contact_details.phone_2 || "N/A"}}</span>
                                       </div>
                                     </div>
                                     <div  class="col-md-6 col-lg-4">
                                       <div class="form-group">
                                         <label class="form-label">Email</label>
                                         <span ><a class="email-link" v-bind:href="'mailto:' + member.contact_details.email " >{{member.contact_details.email || "N/A"}}</a></span>
                                       </div>
                                     </div>
                                   </div>

                                 </div>
                               </div>

                               <div class="card mb-5">
                                <div class="card-header">
                                  <div class="header-title">
                                      <h3>{{ props_fields.demographic.title || 'Additional Information'}}</h3>
                                  </div>
                                  <div  class="modal-btn" v-if="currentMemberId==member.id">
                                      <b-button v-b-modal.edit-demographic><span class="fa fa-edit"></span> Edit</b-button>
                                  </div>
                                </div>
                                <div class="card-body">
                                  <div  class="row">
                                      <div  class="col-md-6 col-lg-4 " v-if="props_fields && props_fields.demographic && props_fields.demographic.race">
                                          <div class="form-group">
                                              <label class="form-label">{{props_fields && props_fields.demographic ? props_fields.demographic.race.label : 'Race' }}</label>
                                              <span v-if="member.personal_details.diversity != null">{{ member.personal_details.diversity.name }}</span>
                                              <span v-else>N/A</span>
                                          </div>
                                      </div>
                                      <div  class="col-md-6 col-lg-4" v-if="props_fields && props_fields.demographic && props_fields.demographic.ethnicity">
                                          <div class="form-group">
                                              <label class="form-label">{{props_fields && props_fields.demographic ? props_fields.demographic.ethnicity.label : 'Ethnicity' }}</label>
                                              <span >{{member.personal_details.ethnicity || "N/A"}}</span>
                                          </div>
                                      </div>
                                      <div  class="col-md-6 col-lg-4" v-if="props_fields && props_fields.demographic && props_fields.demographic.veteran_status">
                                          <div class="form-group">
                                              <label class="form-label">{{props_fields && props_fields.demographic ? props_fields.demographic.veteran_status.label : 'Veteran Status' }}</label>
                                              <span >{{member.personal_details.veteran_status || "N/A"}}</span>
                                          </div>
                                      </div>
                                      <div  class="col-md-6 col-lg-4" v-if="props_fields && props_fields.demographic && props_fields.demographic.disability_status">
                                          <div class="form-group">
                                              <label class="form-label">{{props_fields && props_fields.demographic ? props_fields.demographic.disability_status.label : 'Disability Status' }}</label>
                                              <span >{{member.personal_details.disability || "N/A"}}</span>
                                          </div>
                                      </div>
                                      <div  class="col-md-6 col-lg-4" v-if="props_fields && props_fields.demographic && props_fields.demographic.primary_area_interest">
                                      <div class="form-group">
                                          <label class="form-label">{{props_fields && props_fields.demographic ? props_fields.demographic.primary_area_interest.label : 'Primary Role of Interest' }}</label>
                                          <span >{{member.personal_details.primary_area_interest || "N/A"}}</span>
                                      </div>
                                      </div>

                                      <div  class="col-md-6 col-lg-4" v-if="props_fields && props_fields.demographic && props_fields.demographic.category">
                                      <div class="form-group">
                                          <label class="form-label">{{props_fields && props_fields.demographic ? props_fields.demographic.category.label : 'Category' }}</label>
                                          <span >{{member.personal_details.shooting_category ? member.personal_details.shooting_category.name : "N/A"}}</span>
                                      </div>
                                      </div>

                                      <div  class="col-md-6 col-lg-6" v-if="props_fields && props_fields.demographic && props_fields.demographic.issf">
                                      <div class="form-group">
                                          <label class="form-label">{{props_fields && props_fields.demographic ? props_fields.demographic.issf.label : 'ISSF License Certification Number' }} </label>
                                          <span >{{member.personal_details.issf || "N/A"}}</span>
                                      </div>
                                      </div>

                                      <div  class="col-md-6 col-lg-6" v-if="props_fields && props_fields.demographic && props_fields.demographic.cmp">
                                      <div class="form-group">
                                          <label class="form-label">{{props_fields && props_fields.demographic ? props_fields.demographic.cmp.label : 'CMP Membership #' }} </label>
                                          <span >{{member.personal_details.cmp_no || "N/A"}}</span>
                                      </div>
                                      </div>

                                      <div  class="col-md-6 col-lg-6" v-if="props_fields && props_fields.demographic && props_fields.demographic.nra">
                                      <div class="form-group">
                                          <label class="form-label">{{props_fields && props_fields.demographic ? props_fields.demographic.nra.label : 'NRA Membership #' }} </label>
                                          <span >{{member.personal_details.nra_no || "N/A"}}</span>
                                      </div>
                                      </div>

                                      <div  class="col-md-6 col-lg-6" v-if="props_fields && props_fields.demographic && props_fields.demographic.sasp">
                                      <div class="form-group">
                                          <label class="form-label">{{props_fields && props_fields.demographic ? props_fields.demographic.sasp.label : 'SASP Membership #' }} </label>
                                          <span >{{member.personal_details.sasp_no || "N/A"}}</span>
                                      </div>
                                      </div>

                                      <div  class="col-md-6 col-lg-6" v-if="props_fields && props_fields.demographic && props_fields.demographic.sctp">
                                      <div class="form-group">
                                          <label class="form-label">{{props_fields && props_fields.demographic ? props_fields.demographic.sctp.label : 'SCTP Membership #' }} </label>
                                          <span >{{member.personal_details.sctp_no || "N/A"}}</span>
                                      </div>
                                      </div>

                                      <div  class="col-md-6 col-lg-6" v-if="props_fields && props_fields.demographic && props_fields.demographic.hear_about">
                                      <div class="form-group">
                                          <label class="form-label">{{props_fields && props_fields.demographic ? props_fields.demographic.hear_about.label : 'How did you hear about USA Shooting' }}</label>
                                          <span >{{member.personal_details.hear_about || "N/A"}}</span>
                                      </div>
                                      </div>
                                  </div>
                                </div>
                            </div>

                             </div>
                           </div>
                         </b-tab>
                       </b-tabs>
                     </div>
                   </div>
                 </div>
               </div>

            <div class="row profile-body " >
                <div class="col-md-12">
                    <div class="card table-head Merchandise">
                        <div class="card-header">
                            <div class="header-title">
                                <h3>Membership History</h3>
                            </div>
                        </div>
                        <!-- /.card-header -->
                        <div class="card-body pt-0 table-responsive">
                            <b-table
                                id="my-table"
                                :items="userProfileData.membership_history"
                                :fields="tblFields"
                                class="mt-3"
                                hover
                                show-empty
                            >
                                <template #empty="scope">
                                    <h4>{{ scope.emptyText }}</h4>
                                </template>
                                 <template #cell(waiver)="row">
                                    <div class="text-left py-0 align-middle" v-if="getSiteProps('general.waiver_eligible_registration_status').includes(row.item.status)">
                                        <div class="btn-group btn-group-sm">
                                            <button v-if="isNotalliancefastpitch" @click="downloadDocs(row.item.id, 'waiver')"
                                                    class="download-btn ml-1 mt-0"><img src="/dist/img/profile/ico-download.png"></button>
                                            <button v-if="!isNotalliancefastpitch && row.item.waivers && row.item.waivers.length" @click="downloadDocs(row.item.id, 'waiver')"
                                                    class="download-btn ml-1 mt-0"><img src="/dist/img/profile/ico-download.png"></button>
                                        </div>
                                    </div>
                                </template>
                                <template #cell(status)="row">
                                    {{ row.item.transaction_status }}
                                </template>

                                <template #cell(receipt)="row">
                                    <div class="text-left py-0 align-middle" v-if="getSiteProps('general.receipt_eligible_registration_status').includes(row.item.status)">
                                        <div class="btn-group btn-group-sm">
                                            <button v-if="isNotalliancefastpitch" @click="downloadDocs(row.item.id, 'payment')"
                                                    class="download-btn ml-1 mt-0"><img src="/dist/img/profile/ico-download.png"></button>
                                            <button v-if="!isNotalliancefastpitch && userProfileData.personal.relation==0 && (row.item.customerprofileid != null || row.item.coupon_id !== null) && row.item.payments" @click="downloadDocs(row.item.id, 'payment')"
                                                  class="download-btn ml-1 mt-0"><img src="/dist/img/profile/ico-download.png"></button>
                                        </div>
                                    </div>
                                </template>
                            </b-table>
                        </div>
                        <!-- /.card-body -->
                    </div>


                </div>
            </div>

                 <div class="row profile-body">
                     <div class="col-md-12" v-show="digitalView">
                         <div class="card table-head Merchandise">
                            <div class="card-header">
                                <div class="header-title">
                                <h3>Donation</h3>
                                </div>
                            </div>
                             <div class="card-body pt-0 table-responsive">
                                 <b-table
                                     id="myDonationTable"
                                     :items="userProfileData.donations"
                                     :fields="donationTbl"
                                     class="mt-3"
                                     hover
                                     show-empty
                                 >
                                 <template #cell(sports_discipline)="row" v-if="subdomain=='alliancefastpitch' ">
                                        {{ row.item.type }}
                                    </template>
                                     <template #cell(sports_discipline)="row" v-else>
                                       <b-button variant="outline-primary" size="sm"
                                                 @click="dispDisciplineModal(row.item, row.index, $event.target)">
                                         <i class="fas fa-pencil-alt"></i>
                                       </b-button>
                                       {{ listOfDonationSportDiscipline[row.value] }}
                                     </template>
                                   <template #cell(cost)="row">
                                     $ {{ row.item.cost }}
                                   </template>
                                     <template #cell(status)="row">
                                         {{row.transaction_code!='' ? 'Paid' : 'Not Paid'}}
                                     </template>
                                   <template #cell(receipt)="row" v-if="userProfileData.personal.relation==0">
                                     <div class="text-left py-0 align-middle">
                                       <div class="btn-group btn-group-sm">
                                         <button @click="downloadDonationDocs(row.item.id)"
                                                 class="btn btn-download"><img src="/dist/img/profile/ico-download.png"></button>
                                       </div>
                                     </div>
                                   </template>
                                 </b-table>
                             </div>
                         </div>

                     </div>
                 </div>
                 <div class="row profile-body" v-if="getSiteProps('general.merchandise')">
                     <div class="col-md-12" v-show="digitalView">
                          <div class="card table-head Merchandise mb-4">
                            <div class="card-header">
                                <div class="header-title">
                                <h3>Merchandise</h3>
                                </div>
                            </div>
                             <div class="card-body pt-0 table-responsive">
                                 <b-table
                                     v-if="subdomain == 'waterski'"
                                     id="myMerchandiseTable"
                                     :items="userProfileData.cartProducts"
                                     :fields="merchandiseTbl"
                                     class="mt-3"
                                     hover
                                     show-empty
                                 >
                                   <template #cell(price)="row">
                                     $ {{ row.item.price }}
                                   </template>
                                   <template #cell(color)="row">
                                     {{ row.item.variant_name.split('|')[0] }}
                                   </template>
                                   <template #cell(size)="row">
                                     {{ row.item.variant_name.split('|')[1] }}
                                   </template>
                                   <template #cell(total)="row">
                                     $ {{ row.item.total }}
                                   </template>
                                  <template #cell(registration.status)="row">
                                      {{row.transaction_code!='' ? 'Paid' : 'Not Paid'}}
                                  </template>
                                  <template #cell(registration.merchandise_order.fulfilled)="row">
                                      {{row.item.registration.merchandise_order.fulfilled !='0' ? 'Yes' : 'No'}}
                                  </template>
                                  <template #cell(registration.merchandise_order.fulfilled_date)="row">
                                    {{row.item.registration.merchandise_order.fulfilled_date == null ? 'N/A' : formatDate(row.item.registration.merchandise_order.fulfilled_date)}}
                                  </template>
                                  <template #cell(registration.merchandise_order.tracking_id)="row">
                                    {{row.item.registration.merchandise_order.tracking_id == null ? 'N/A' : row.item.registration.merchandise_order.tracking_id}}
                                  </template>
                                     
                                 </b-table>
                                 <b-table
                                 v-if="subdomain == 'shooting'"
                                 id="myMerchandiseTable"
                                 :items="userProfileData.cartProducts"
                                 :fields="merchandiseTbls"
                                 class="mt-3"
                                 hover
                                 show-empty
                             >
                               <template #cell(price)="row">
                                 $ {{ row.item.price }}
                               </template>
                               <template #cell(color)="row">
                                 {{ row.item.variant_name.split('|')[0] }}
                               </template>
                               <template #cell(size)="row">
                                 {{ row.item.variant_name.split('|')[1] }}
                               </template>
                               <template #cell(total)="row">
                                 $ {{ row.item.total }}
                               </template>
                                 <template #cell(registration.status)="row">
                                     {{row.transaction_code!='' ? 'Paid' : 'Not Paid'}}
                                 </template>
                                </b-table>
                             </div>
                          </div>
                     </div>
                 </div>
                <!-- Purchase History -->
                <div class="row profile-body" id="purchaseHistorySection" v-if="this.getSiteProps('general.purchaseHistory')">
                  <div class="col-md-12">
                    <div class="card mt-3">
                        <div class="card-header">
                            <div class="header-title">
                                <h3>Purchase History</h3>
                            </div>
                        </div>
                        
                        <div class="card-body mt-4 pt-0">
                            <div class="d-flex">
                            <div class="col-md-3">
                                <b-form inline>
                                    <label class="mr-sm-2 font-weight-normal">Show</label>
                                    <b-form-select
                                    id="inline-form-custom-select-pref"
                                    class="mb-2 mr-sm-2 mb-sm-0"
                                    v-model="purchaseHistory.perPage"
                                    :options="purchaseHistory.viewEntryOptions"
                                    size="md"
                                    ></b-form-select
                                    >entries
                                </b-form>
                                </div>
                                <div class="col-md-6 d-inline-flex">
                                    <div class="form-inline mr-1">
                                        <label class="form-label mr-2">From Date</label>
                                        <div class="form-group birthdate">
                                            <div class="birthdaypicker">
                                                <date-pick
                                                    v-model="purchaseHistory.filter.startDate"
                                                    :format="'MM/DD/YYYY'"
                                                    :displayFormat="'MM/DD/YYYY'"
                                                    placeholder="start"
                                                    v-mask="'##/##/####'"
                                                    :parseDate="parseDatePick"
                                                ></date-pick>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="form-inline">
                                        <label class="form-label mr-2">To Date</label>
                                        <div class="form-group birthdate">
                                            <div class="birthdaypicker">
                                                <date-pick
                                                    v-model="purchaseHistory.filter.endDate"
                                                    :format="'MM/DD/YYYY'"
                                                    :displayFormat="'MM/DD/YYYY'"
                                                    placeholder="End"
                                                    v-mask="'##/##/####'"
                                                    :parseDate="parseDatePick"
                                                ></date-pick>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                
                                <div class="col-md-3">
                                <b-input-group size="md">
                                    <b-form-input
                                    class="border border-secondary"
                                    id="filter-input"
                                    v-model="purchaseHistory.filter.search"
                                    type="search"
                                    placeholder="Search"
                                    ></b-form-input>
                                </b-input-group>
                            </div>
                        </div>
                        <b-table
                            id="purchaseHistoryTable"
                            :per-page="purchaseHistory.perPage"
                            :current-page="purchaseHistory.currentPage"
                            :items="purchaseHistory.data"
                            :fields="purchaseHistoryTbl"
                            class="mt-3"
                            :busy="!purchaseHistory.loaded"
                            :filter="purchaseHistory.filter"
                            :filter-included-fields="purchaseHistory.filterOn"
                            :filter-function="customFilter"
                            responsive='true'
                            @filtered="onFilteredPurchaseHistory"
                            hover
                            show-empty
                        >
                        <template #cell(receipt)="row">
                          <div class="text-left py-0 align-middle">
                              <div class="btn-group btn-group-sm" v-if="row.item.transaction_type == 'donation'">
                                  <button @click="downloadDonationDocs(row.item.donation.id)"
                                          class="btn btn-download"><img src="/dist/img/profile/ico-download.png"></button>
                              </div>
                              <div class="btn-group btn-group-sm" v-else-if="row.item.transaction_type == 'registraion' && !row.item.name.includes('Discount Coupon')">
                                  <button @click="downloadDocs(row.item.ref_regid, 'payment')"
                                          class="btn btn-download"><img src="/dist/img/profile/ico-download.png"></button>
                              </div>
                              <div class="btn-group btn-group-sm" v-else-if="['mvr','background_screening'].includes(row.item.transaction_type)">
                                  <button @click="downloadBgScreeningDocs(row.item.payment.id, row.item.product)"
                                          class="btn btn-download"><img src="/dist/img/profile/ico-download.png"></button>
                              </div>
                            <div class="btn-group btn-group-sm" v-else-if="row.item.transaction_type=='merchandise'">
                              <button @click="downloadOtherDocs(row.item.ref_regid,row.item.ref_memid, row.item.transaction_type, row.item.transaction_id)"
                                      class="btn btn-download"><img src="/dist/img/profile/ico-download.png"></button>
                            </div>
                          </div>
                      </template>
                        </b-table>
                        <br />
                        <b-pagination
                            v-if="purchaseHistory.loaded"
                            v-model="purchaseHistory.currentPage"
                            :total-rows="purchaseHistoryRows"
                            :per-page="purchaseHistory.perPage"
                            prev-text="Previous"
                            next-text="Next"
                            aria-controls="purchaseHistoryTable"
                            class="float-right custom-color"
                            hide-goto-end-buttons
                        ></b-pagination>
                        </div>
                    </div>
                  </div>
              </div>
               <div class="row profile-body" ref="committee" v-if="subdomain == 'waterski'">
                 <div class="col-md-12" v-show="digitalView">
                   <div class="card table-head Merchandise mb-4">
                     <div class="card-header">
                       <div class="header-title">
                         <h3>Groups</h3>
                       </div>
                     </div>
                     <div class="card-body pt-0 table-responsive">
                       <b-table
                           id="myMerchandiseTable"
                           :items="userProfileData.committes"
                           :fields="committeeTbl"
                           class="mt-3"
                           hover
                           show-empty
                       >
                         <template #cell(price)="row">
                           $ {{ row.item.price }}
                         </template>
                         <template #cell(color)="row">
                           {{ row.item.variant_name.split('|')[0] }}
                         </template>
                         <template #cell(size)="row">
                           {{ row.item.variant_name.split('|')[1] }}
                         </template>
                         <template #cell(total)="row">
                           $ {{ row.item.total }}
                         </template>
                         <template #cell(registration.status)="row">
                           {{row.transaction_code!='' ? 'Paid' : 'Not Paid'}}
                         </template>
                         <template #cell(actions)="row">
                           <div class="text-left py-0 align-middle">
                             <div class="btn-group btn-group-sm">
                               <!-- <button
                                   class="btn btn-primary"
                                   @click="viewRow(row.item, row.index, $event.target)"
                               >
                                 <i class="fas fa-eye"></i>
                               </button>
                               <button
                                   class="btn btn-primary ml-1"
                                   @click="editRow(row.item, $event.target)"
                               >
                                 <i class="fas fa-pencil-alt"></i>
                               </button> -->
                               <!-- <a class="editPosition" memberid="${row.id}" position_id="${row.position_id}" effective_date="${row.effective_date}" expiration_date="${row.expiration_date}" href="javascript:void(0);"><i class="ml-1 fas fa-pencil-alt"></i></a> -->

                               <button
                                   class="btn btn-primary ml-1"
                                   @click="editPosition(row.item.id,row.item.position_id,row.item.effective_date,row.item.expiration_date,row.item.sport_discipline,row.item.member.confirmation_code,row.item.personal_details.full_name,row.item.committee.committee)"
                               >
                                 <i class="fas fa-pencil-alt"></i>
                               </button>
                               <button
                                   class="btn btn-danger ml-1"
                                   @click="deleteRow(row.item.id)"
                               >
                                 <i class="fas fa-trash"></i>
                               </button>
                             </div>
                           </div>
                         </template>
                       </b-table>
                     </div>
                   </div>
                 </div>
               </div>

                <div class="row profile-body" id="noteList" v-show="digitalView">
                  <div class="col-md-12">
                    <div class="card table-head Merchandise mb-4">
                      <div class="card-header">
                        <div class="header-title" style="width: 100%">
                          <div class="row">
                            <div class="col-md-11">
                              <h3>Notes</h3>
                            </div>
                            <div class="col-md-1 text-right pt-1">
                              <button class="btn btn-primary btn-blue btn-sm" v-b-modal.note-modal @click="addnote()"><i class="fa fa-plus"></i> Add</button>
                            </div>
                          </div>
                        </div>
                      </div>
                      <section id="notes_section">
                      <div class="card-body pt-0 table-responsive">
                        <b-table
                           id="myMerchandiseTable"
                           :items="userNote.notes"
                           :fields="notesTbl"
                           class="mt-3"
                           hover
                           show-empty
                        >
                         <template #cell(note)="row">
                          {{row.item.note}}
                         </template>
                         <template #cell(name)="row">
                          {{row.item.user.first_name}}
                         </template>
                         <template #cell(status)="row">
                            {{row.item.status!='0' ? 'Active' : 'In Active'}}
                         </template>
                         <template #cell(actions)="row">
                           <div class="text-left py-0 align-middle">
                             <div class="btn-group btn-group-sm">
                               <button
                                  data-bs-toggle="modal"  v-b-modal.note-modal
                                   class="btn btn-primary ml-1"
                                   @click="editNote(row.item, $event.target)"
                               >
                                 <i class="fas fa-pencil-alt"></i>
                               </button>
                               <button
                                   class="btn btn-danger ml-1"
                                   @click="deleteNote(row.item.id)"
                               >
                                 <i class="fas fa-trash"></i>
                               </button>
                             </div>
                           </div>
                         </template>
                       </b-table>
                     </div>
                      </section>
                   </div>
                 </div>
               </div>
               <!-- Volunteering Module -->
               <div class="row profile-body" v-if="getSiteProps('volunteering.visibility') && userProfileData.volunteering "> 
                 <div class="col-md-12">
                    <div class="card table-head Volunteering mb-4">
                      <div class="card-header">
                        <div class="header-title">
                          <h3>Volunteering</h3>                           
                        </div>
                      </div>
                      <div class="card-body pt-0 table-responsive">
                        <div class="row">                          
                          <div class="col-lg-8 mr-0 pr-0">
                            <div class="mt-3">
                              <b-form inline>
                              <label class="mr-sm-2 font-weight-normal">Show</label>
                              <b-form-select
                                id="inline-form-custom-select-pref"
                                class="mb-2 mr-sm-2 mb-sm-0"
                                v-model="perPage"
                                :options="viewEntryOptions"
                                size="sm"
                              ></b-form-select
                              >entries
                            </b-form>

                            </div>
                          
                          </div>
                          <div class="col-lg-4  text-right pr-0">
                            <div class=" card-text d-flex align-items-center float-right"> 
                            <div class="mt-3"> 
                            <b-input-group size="sm">
                              <b-form-input
                                id="filter-input"
                                v-model="filter"
                                type="search"
                                placeholder="Search"
                              ></b-form-input>
                            </b-input-group>
                            </div>
                            <div class="col-md-4 text-right pt-1 mt-3">
                                <b-button class="btn btn-primary btn-blue btn-sm"                                
                                @click="addvolRow($event.target)"
                                ><i class="fa fa-plus"></i> Add</b-button
                              >
                            </div>
                            </div>
                          </div>
                        </div>

                       <b-table
                           id="volunteeringTable"
                           :per-page="perPage"
                           :current-page="currentPage"
                           :items="volunteering"
                           :fields="volunteerTbl"
                           :busy="!loaded"
                           class="mt-3"
                           :filter="filter"
                           :filter-included-fields="filterOn"
                           @filtered="onFiltered"
                           hover
                           show-empty
                       >
                         <template #cell(volunteer)="row">
                           {{ row.item.volunteer }}
                         </template>                          
                         <template #cell(status)="row">
                           <b-button pill :variant="(row.item.status == 'Current') ? 'success' : 'danger' ">{{ row.item.status }}</b-button>
                         </template>
                         <template #cell(role)="row">
                          {{row.item.role}}
                         </template>
                         <template #cell(actions)="row">
                           <div class="text-left py-0 align-middle">
                             <div class="btn-group btn-group-sm">
                               <!-- <button
                                   class="btn btn-primary"
                                   @click="viewvolRow(row.item, row.index, $event.target)"
                               >
                                 <i class="fas fa-eye"></i>
                               </button> -->
                               <button
                                   class="btn btn-primary ml-1"
                                   @click="editvolRow(row.item, $event.target)"
                               >
                                 <i class="fas fa-pencil-alt"></i>
                               </button>
                               <button
                                   class="btn btn-danger ml-1"
                                   @click="deletevolRow(row.item.id)"
                               >
                                 <i class="fas fa-trash"></i>
                               </button>
                             </div>
                           </div>
                         </template>
                       </b-table>
                       <br />
                      <b-pagination
                        v-if="loaded"
                        v-model="currentPage"
                        :total-rows="rows"
                        :per-page="perPage"
                        prev-text="Previous"
                        next-text="Next"
                        aria-controls="volunteeringTable"
                        class="float-right custom-color"
                        hide-goto-end-buttons
                      ></b-pagination>
                     </div>

                    </div> 
                 </div> 
                 <b-modal
                    id="editvol-modal"
                    :title="editvolModal.title"
                    size="lg"
                    ref="modal"
                    ok-title="Save" 
                    @ok="saveVolunteering"
                    @hidden="resetEditvolModal"
                  >
                    <div class="row">
                      <div class="col-md-12">
                        <form ref="form">
                          <div class="row" id="volunteer_status">
                            <div class="col-md-2"> 
                                  <label for="role" class="form-label">Status</label>  
                              </div>
                              <div class="col-md-10">     
                                  <div class="form-group">                                   
                                      <b-button variant="success"><i class="fas fa-check"></i> {{form.status}}</b-button>
                                  </div>
                              </div>
                          </div>
                          <div class="row">
                            <div class="col-md-2"> 
                                <label for="role" class="form-label">Role<sup class="text-danger">*</sup></label>  
                            </div>
                            <div class="col-md-10">     
                                <div class="form-group">                                   
                                    <select class="form-control" id="role" v-model="form.role" @change="loadsubroles($event)" >
                                        <option value="">Select a Role</option>
                                        <option v-for="(role, id) in roles" :value="role.id" :key="id">{{ role.text }}</option>
                                    </select>
                                    <span class="text-sm text-danger">{{ errors.role }}</span>
                                </div>
                            </div>
                          </div>
                          <div class="row" id="sub_roles">
                            <div class="col-md-2"> 
                                <label for="role" class="form-label"><sup class="text-danger">*</sup></label>  
                            </div>
                            <div class="col-md-10">  
                                <div class="form-group">   
                                    <Select2 v-model="form.sub_roles" :options="sub_roles"  :settings="{ multiple: true, placeholder: 'Click to select one or multiple'}" @change="loadother($event)"/> 
                                    <span class="text-sm text-danger">{{ errors.sub_roles }}</span> <br/>
                                    <small>Please select all of the roles that apply. If none of the roles fit, select 'Other' and complete the text box.</small> 
                                </div>
                            </div>   
                          </div> 
                          <div class="row" id="other_role">
                            <div class="col-md-2"> 
                                <label for="role" class="form-label">Other<sup class="text-danger">*</sup></label>  
                            </div>
                            <div class="col-md-10">  
                                <div class="form-group">   
                                    <input type="text" class="form-control" v-model="form.other_role">
                                    <span class="text-sm text-danger">{{ errors.other_role }}</span>
                                </div>
                            </div>   
                          </div>
                        </form>
                      </div>
                       
                    </div>
                  </b-modal>

               </div>


              <div class="row profile-body" id="travelinformation" v-if="this.getSiteProps('general.travelInformation') && userProfileData.travel_form">
                  <div class="col-md-12">
                    <div class="card table-head Merchandise mb-4">
                      <div class="card-header">
                        <div class="header-title" style="width: 100%">
                          <div class="row">
                            <div class="col-md-11">
                              <h3>Travel Information</h3>
                            </div>
                            <div class="col-md-1 text-right pt-1" v-if="this.addTravelForm">
                              <button class="btn btn-primary btn-blue btn-sm" @click="addTravelInformation()"><i class="fa fa-plus"></i> Add</button>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="card-body pt-0 table-responsive">
                        <div v-if="isTravelFormloading==true" class="d-flex justify-content-center userpf-loader travel-form-loader">
                              <div  class="spinner-border" role="status">
                              <span class="sr-only">Loading...</span>
                              </div>
                            </div>
                        <b-table
                           id="myMerchandiseTable"
                           :items="travelInformation"
                           :fields="travelInformationTbl"
                           class="mt-3"
                           hover
                           show-empty
                        >
                         <template #cell(actions)="row">
                          <div class="text-left py-0 align-middle">
                            <div class="btn-group btn-group-sm">
                  
                               <button
                                class="btn btn-primary ml-1"
                                @click="editTravelInformation(row.item.id)"
                              >
                                 <i class="fas fa-pencil-alt"></i>
                              </button>

                              <button
                                class="btn btn-danger ml-1"
                                @click="deleteTravelForm(row.item.id)"
                              >
                                <i class="fas fa-trash"></i>
                              </button>
                            </div>
                          </div>
                        </template>
                       </b-table>
                     </div>
                   </div>
                 </div>
               </div>
            <!-- profile body end-->
        </div>

      <b-modal id="modal-center1" size="lg" ok-title="Update" @hidden="clearErrorMessage" @ok="updatePersonalInfo" centered :title="props_fields && props_fields.personal && props_fields.personal.tab_title ? (userProfileData.membership.name == 'Staff')?'Coach Information':props_fields.personal.tab_title : 'Personal Information'">
        <div class="form-body">
          <form>
            <div class="row" v-if="props_fields && props_fields.personal.title">
                <div class="col-md-5">
                    <div class="form-group">
                        <label for="title" class="form-label">{{ props_fields.personal.title.label }}<sup class="text-danger" v-if="props_fields.personal.title.required">*</sup></label>
                        <!-- <select name="verifyPersonal.title" class="form-control" v-model="UpdateProfile.title">
                            <option v-for="(item, k) in props_fields.personal.title.options" :value="item" :key="'title-'+k">{{ item }}</option>
                        </select> -->
                        <input type="text" class="form-control" id="title" v-model="UpdateProfile.title" name="verifyPersonal.title" value="">
                        <span class="text-sm text-danger">{{ errors.title}}</span>
                    </div>
                </div>
            </div>
            <div class="row">
              <div class="col-md-5">
                <div class="form-group">
                  <label class="form-label" for="first_name">First Name <sup class="text-danger">*</sup></label>
                  <input type="text" id="first_name" v-model="UpdateProfile.first_name" placeholder="John" class="form-control">
                  <span class="text-sm text-danger">{{ errors.first_name}}</span>
                  <span class="text-sm text-danger">{{ duplicateErrorForPersonal}}</span>
                </div>
              </div>
              <div class="col-md-2">
                <div class="form-group">
                  <label class="form-label" for="middle_name">Middle Initial</label>
                  <input type="text" id="middle_name" v-model="UpdateProfile.middle_name" value="" placeholder="A" maxlength="3" class="form-control">
                </div>
                <span class="text-sm text-danger">{{ errors.middle_name}}</span>
              </div>
              <div class="col-md-5">
                <div class="form-group">
                  <label class="form-label" for="last_name">Last Name</label>
                  <input type="text" id="last_name" v-model="UpdateProfile.last_name" value="" placeholder="Doe" class="form-control">
                  <span class="text-sm text-danger">{{ errors.last_name}}</span>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-md-6">
                <div class="form-group">
                  <label for="birthday" class="form-label">{{ props_fields.personal.birthday.label || 'Date of Birth' }} <sup class="text-danger">*</sup></label>
                  <div class="input-group birthdate">
                    <div class="birthdaypicker">
                      <date-pick
                          v-model="UpdateProfile.dob"
                          :pickTime="false"
                          name="form.dob"
                          :format="'MM/DD/YYYY'"
                          :selectableYearRange="{from: 1900, to: 2021}"
                          v-mask="'##/##/####'"
                          :isDateDisabled="isFutureDate"
                          placeholder="MM/DD/YYYY"
                          :parseDate="parseDatePick"
                      ></date-pick>
                    </div>
                    <span class="text-sm text-danger">{{ errors.dob}}</span>
                  </div>
                </div>
              </div>
              <div class="col-md-6" v-if="props_fields && props_fields.personal && props_fields.personal.gender">
                <div class="form-group">
                  <label for="gender" class="form-label">{{ props_fields.personal.gender.label }} <sup class="text-danger" v-if="props_fields.personal.gender.required">*</sup></label>
                  <div class="d-flex" v-if="props_fields.personal.gender.choices">
                      <template v-for="(choice, key) in props_fields.personal.gender.choices">
                          <div class="sexitems" :key="'wrap-'+key">
                              <input type="radio" :id="choice.value" v-model="UpdateProfile.gender" name="form.gender" :value="choice.label" :key="'radio_' + key" @click="messageFor(choice.value)">
                              <label class="gender-label-span" :for="choice.value" :key="'gender_label_'+key">{{ choice.label }}</label>
                          </div>
                      </template>
                  </div>
                      <span class="text-sm text-danger">{{ errors.gender}}</span>
                  <!-- <label class="form-label" for="male">Gender</label><br>
                  <div class="d-flex">
                    <div class="sexitems">
                      <input type="radio" v-model="UpdateProfile.gender" :checked="userProfileData.personal.gender == 'Male'" id="male" name="gender" value="Male">
                      <label for="male">Male</label>
                    </div>
                    <div class="sexitems">
                      <input type="radio" v-model="UpdateProfile.gender" :checked="userProfileData.personal.gender == 'Female'" id="female" name="gender" value="Female">
                      <label for="female">Female</label>
                    </div>
                    <div class="sexitems">
                      <input type="radio" v-model="UpdateProfile.gender" :checked="userProfileData.personal.gender == 'Other'" id="other" name="gender" value="Other">
                      <label for="other">Other</label>
                    </div>

                  </div> -->
                </div>
              </div>
              
              <div class="col-md-6" v-if="isNotalliancefastpitch && userProfileData.membership.id == 5 && userProfileData.personal_details.relation != 0">
                <div class="form-group">
                  <label for="gender" class="form-label">Relation</label>
                          <div class="sexitems d-flex" >

                            <template v-for="(choice, index) in relationArr">
                              <div class="sexitems" :key="'wrap-'+index" v-if="index != 0 && (index != 3 || (index == 3 && userProfileData.personal_details.relation == 3))">
                                  <input type="radio" :id="choice" v-model="UpdateProfile.relation" name="form.relation" :value="index" :key="'radio_' + index">
                                  <label class="gender-label-span" :for="choice" :key="'gender_label_'+index">{{ choice }}</label>
                              </div>
                            </template>
                          </div>
                      <span class="text-sm text-danger">{{ errors.relation}}</span>
                </div>
              </div>

              <div class="col-md-12" v-if="subdomain === 'waterski'">
                <div class="form-group">
                  <label for="clubandteam" class="form-label">Affiliated Clubs/Teams</label>
                  <Select2 v-model="UpdateProfile.team_id" name="UpdateProfile.team_id" :options="clubOptions" :settings="select2MultipleOpt" id="clubandteam"/>
                  <small id="clubandteam" class="form-text text-muted">First, you have to select your Club/Team and then press and hold the Ctrl key. While holding Ctrl, click each of the other clubs/teams you want to select.</small>
                </div>
              </div>
            </div>
            <div class="row" v-if="isNotalliancefastpitch">
              <div class="col-md-6" v-if="props_fields.personal.veteran_status">
                  <div class="form-group">
                      <label for="veteran_status" class="form-label">{{ props_fields.personal.veteran_status.label }} <sup class="text-danger" v-if="props_fields.personal.veteran_status.required">*</sup></label>
                      <div class="form-group" v-if="props_fields.personal.veteran_status.choices">
                          <template v-for="(choice, key) in props_fields.personal.veteran_status.choices">
                              <input type="radio" :id="choice.value" v-model="UpdateProfile.veteran_status" name="veteran_status" :value="choice.label" :key="'radio_' + key">
                              <label class="veteran_status-label-span" :for="choice.value" :key="'veteran_status_label_'+key">{{ choice.label }}</label>
                              <br :key="'veteran_status_br_'+key">
                          </template>
                          <span class="text-sm text-danger">{{ errors.veteran_status}}</span>
                      </div>
                  </div>
              </div>
              <div class="col-md-6" v-if="props_fields.demographic.race&&(subdomain=='shooting'&&userProfileData.membership.member_type_id==7)">
                <div class="form-group radiotext">
                  <label for="race" class="form-label"><strong>{{ props_fields.demographic.race.label || 'Race'}}</strong><sup class="text-danger">*</sup></label>
                  <div v-for="(diversity,i) in diversities" :key="i">
                    <input type="radio" :id="'diversity'+diversity.name" name="UpdateProfile.diversity_id" :value="diversity.id" v-model="UpdateProfile.diversity_id">
                    <label  :for="'diversity'+diversity.name">{{diversity.name}}</label> <br>
                  </div>
                  <span class="text-sm text-danger">{{ errors.diversity_id }}</span>
                </div>
              </div>
              <div :class="[props_fields.general.sport_discipline.first_choice.multiple ? 'col-md-12 full-width-select2' : 'col-md-6']" v-if="props_fields.general.sport_discipline.first_choice&&!(subdomain=='shooting'&&userProfileData.membership.member_type_id==7)">
                <div class="form-group">
                  <label for="first-choice" class="form-label">{{ props_fields.general.sport_discipline.first_choice.label }} <sup class="text-danger">*</sup></label>
                  <Select2 v-if="props_fields.general.sport_discipline.first_choice.multiple" v-model="UpdateProfile.first_choice" name="first_choice" id="edit-disciplines" :options="sportDisciplinesSelect2" :settings="{ multiple: true, placeholder: 'Click to select one or multiple'}" />
                  <select v-else class="form-control" id="first-choice" name="form.first_choice"
                          v-model="UpdateProfile.first_choice">
                    <option value="">Select</option>
                    <option v-for="(discipline, index) in sportDisciplines" :value="index"
                            :key="'types'+index">{{ discipline }}
                    </option>
                    <option :value="null">NP - No Preference</option>
                  </select>
                  <span class="text-sm text-danger">{{ errors.first_choice }}</span>
                </div>
              </div>
              <div class="col-md-6" v-if="props_fields.general.sport_discipline.second_choice">
                <div class="form-group">
                  <label for="secondchoice" class="form-label">2nd Choice </label>
                  <select class="form-control" id="secondchoice" name="form.second_choice"
                          v-model="UpdateProfile.second_choice">
                    <option value="">Select</option>
                    <option v-for="(discipline, index) in sportDisciplines" :value="index"
                            :key="'types'+index">{{ discipline }}
                    </option>
                    <option :value="null">NP - No Preference</option>
                  </select>

                  <span class="text-sm text-danger">{{ errors.second_choice }}</span>
                </div>
              </div>
            </div>
            <div class="row" v-if="isNotalliancefastpitch && props_fields.general.sport_discipline.home_federation">
              <div class="col-md-6" v-if="subdomain !== 'shooting'">
                <div class="form-group">
                  <label for="home_federation" class="form-label">Federation <sup>*</sup></label>
                  <select class="form-control" id="home_federation" name="form.home_federation"
                          v-model="UpdateProfile.home_federation">
                    <option :value="null">Select</option>
                    <option v-for="(group, index) in groups" :key="'types'+index">{{ group.name }}</option>
                  </select>
                  <span class="text-sm text-danger">{{ errors.home_federation }}</span>
                </div>
              </div>
              <div class="col-md-6">
                <div v-if="UpdateProfile.home_federation !='USA' && UpdateProfile.home_federation != null && subdomain !== 'shooting'">
                  <div class="form-group">
                    <label for="skier_license" class="form-label">Foreign Federation ID<sup>*</sup></label>
                    <input type="text" id="skier_license" class="form-control" maxlength="9" @keypress="isNumber($event)"
                           placeholder="" name="form.skier_license"
                           v-model="UpdateProfile.skier_license">
                    <span class="text-sm text-danger">{{ errors.skier_license }}</span><br/>
                    <span class="text-danger font-13">FOR FOREIGN ATHLETES Use numbers only as listed in EMS Athlete Code DO NOT INCLUDE FEDERATION PREFIX</span>
                  </div>
                </div>
              </div>
            </div>
            <div class="row" v-if="isNotalliancefastpitch && props_fields.personal.clubslist && userProfileData.personal.relation===0&&!(subdomain=='shooting'&&userProfileData.membership.member_type_id==7)">
                <div class="col-md-12">
                    <div class="form-group">
                        <label for="clubslist" class="form-label">{{ props_fields.personal.clubslist.label }}<sup class="text-danger" v-if="props_fields.personal.clubslist.required">*</sup></label>
                        <Select2 v-model="UpdateProfile.club" :options="clubOptions" name="verifyPersonal.club" :settings="{ placeholder: props_fields.personal.clubslist.placeholder}" />
                    </div>
                </div>
            </div>
            <div class="row" v-if="isNotalliancefastpitch && UpdateProfile.club == '-' && userProfileData.personal.relation===0">
                <div class="col-md-4" v-if="props_fields.personal.new_club_name">
                    <div class="form-group">
                        <label for="new_club_name" class="form-label">New Club Name<sup class="text-danger" v-if="props_fields.personal.new_club_name.required">*</sup></label>
                        <input type="text" v-model="UpdateProfile.new_club_name" class="form-control" id="verify-new_club_name" name="verifyPersonal.new_club_name" placeholder="" value="">
                        <span class="text-sm text-danger">{{ errors.new_club_name }}</span>
                    </div>
                </div>
                <div class="col-md-4" v-if="props_fields.personal.new_club_email">
                    <div class="form-group">
                        <label for="new_club_email" class="form-label">New Club E-Mail<sup class="text-danger" v-if="props_fields.personal.new_club_email.required">*</sup></label>
                        <input type="text" v-model="UpdateProfile.new_club_email" class="form-control" id="verify-new_club_email" name="verifyPersonal.new_club_email" placeholder="">
                        <span class="text-sm text-danger">{{ errors.new_club_email }}</span>
                    </div>
                </div>
                <div class="col-md-4" v-if="props_fields.personal.new_club_phone">
                    <div class="form-group">
                        <label for="new_club_phone" class="form-label">New Club Phone<sup class="text-danger" v-if="props_fields.personal.new_club_phone.required">*</sup></label>
                        <vue-tel-input v-model="UpdateProfile.new_club_phone" ref="phone" @validate="validateTelinput"  v-bind="mobileProps" :validCharactersOnly="true"></vue-tel-input>
                        <span class="text-sm text-danger">{{ errors.new_club_phone }}</span>
                    </div>
                </div>
            </div>
            <div class="row" v-if="!isNotalliancefastpitch && isChild">
              <div class="col-md-6">
                <div class="form-group">
                  <label for="agl_player_id" class="form-label">
                    AGL Player ID#
                  </label>
                  <input type="text" class="form-control" maxlength="45" id="agl_player_id" v-model="UpdateProfile.agl_player_id" name="form.agl_player_id" value="">
                  <span class="text-sm text-danger">{{ errors.agl_player_id}}</span>
                </div>
              </div>
              <div :class="[props_fields.personal.graduation_year.colClass]" v-if="props_fields.personal.graduation_year">
                <div class="form-group">
                  <label for="graduation_year" class="form-label">
                    {{ props_fields.personal.graduation_year.label }}<sup class="text-danger" v-if="props_fields.personal.graduation_year.required">*</sup>
                  </label>
                  <select class="form-select form-control" id="graduation_year" required="" name="form.graduation_year" v-model="UpdateProfile.graduation_year">
                    <option value="">Select Graduation Year</option>
                    <option v-for="year in GraduationyearArr" :value="year" :key="year">{{ year }}</option>
                  </select>
                  <span class="text-sm text-danger">{{ errors.graduation_year}}</span>
                </div>
              </div>
              <div :class="[props_fields.personal.jersey_number.colClass]" v-if="props_fields.personal.jersey_number">
                <div class="form-group">
                  <label for="jersey_number" class="form-label">
                    {{ props_fields.personal.jersey_number.label }}<sup class="text-danger" v-if="props_fields.personal.jersey_number.required">*</sup>
                  </label>
                  <input class="form-control" :placeholder="props_fields.personal.jersey_number.placeholder" maxlength="3" id="jersey_number" v-model="UpdateProfile.jersey_number" name="form.jersey_number" value="">
                  <span class="text-sm text-danger">{{ errors.jersey_number}}</span>
                </div>
              </div>
              <div :class="[props_fields.personal.primary_position.colClass]" v-if=" props_fields.personal.primary_position">
                <div class="form-group">
                  <label for="primary_position" class="form-label">{{ props_fields.personal.primary_position.label }} <sup class="text-danger" v-if="props_fields.personal.primary_position.required">*</sup></label>
                  <select class="form-control" id="primary_position" v-model="UpdateProfile.primary_position" name="form.primary_position">
                    <option value="">Select</option>
                    <option  v-for="(listOfPrimaryPosition,key) in props_fields.personal.primary_position.options" :key="key" :value="listOfPrimaryPosition">{{ listOfPrimaryPosition }}</option>
                  </select>
                  <span class="text-sm text-danger">{{ errors.primary_position}}</span>
                </div>
              </div>
              <div :class="[props_fields.personal.secondary_position.colClass]" v-if="props_fields.personal.secondary_position">
                <div class="form-group">
                  <label for="secondary_position" class="form-label">{{ props_fields.personal.secondary_position.label }} <sup class="text-danger" v-if="props_fields.personal.secondary_position.required">*</sup></label>
                  <select class="form-control" id="secondary_position" v-model="UpdateProfile.secondary_position" name="form.secondary_position">
                    <option value="">Select</option>
                    <option  v-for="(listOfSecondaryPosition,key) in props_fields.personal.secondary_position.options" :key="key" :value="listOfSecondaryPosition">{{ listOfSecondaryPosition }}</option>
                  </select>
                  <span class="text-sm text-danger">{{ errors.secondary_position}}</span>
                </div>
              </div>
              <div class="col-md-3" >
                <div class="form-group">
                  <label for="throw" class="form-label">{{ props_fields.personal.throw.label }} <sup class="text-danger" v-if="props_fields.personal.throw.required">*</sup></label>
                  <div class="form-group" v-if="props_fields.personal.throw.choices">
                    <template v-for="(choice, key) in props_fields.personal.throw.choices">
                      <input type="radio" :id="choice.value" v-model="UpdateProfile.throws" name="form.throw" :value="choice.value" :key="'radio_' + key">
                      <label class="throw-label-span mr-3" :for="choice.value" :key="'throw_label_'+key">{{ choice.label }}</label>
                    </template>
                    <span class="text-sm text-danger">{{ errors.throws}}</span>
                  </div>
                </div>
              </div>
              <div class="col-md-3" >
                <div class="form-group" >
                  <label for="bats" class="form-label">{{ props_fields.personal.bats.label }} <sup class="text-danger" v-if="props_fields.personal.bats.required">*</sup></label>
                  <div class="form-group" v-if="props_fields.personal.bats.choices">
                    <template v-for="(choice, key) in props_fields.personal.bats.choices">
                      <input type="radio" :id="choice.value" v-model="UpdateProfile.bats" name="form.bats" :value="choice.value" :key="'radio_' + key">
                      <label class="bats-label-span mr-3" :for="choice.value" :key="'bats_label_'+key">{{ choice.label }}</label>
                    </template>
                    <span class="text-sm text-danger">{{ errors.bats}}</span>
                  </div>
                </div>
              </div>
              <div :class="[props_fields.personal.college_committed.colClass]" >
                <div class="form-group">
                  <label for="college_committed" class="form-label">{{ props_fields.personal.college_committed.label }} <sup class="text-danger" v-if="props_fields.personal.college_committed.required">*</sup></label>
                  <div class="form-group" v-if="props_fields.personal.college_committed.choices">
                    <template v-for="(choice, key) in props_fields.personal.college_committed.choices">
                      <input type="radio" :id="choice.value" v-model="college_list" name="form.college_committed" :value="choice.value" @click="collegeCommitted = choice.value" :key="'radio_' + key">
                      <label class="college_committed-label-span mr-3" :for="choice.value" :key="'college_committed_label_'+key">{{ choice.label }}</label>
                    </template>
                    <span class="text-sm text-danger">{{ errors.college_committed}}</span>
                  </div>
                </div>
              </div>
              <div :class="[props_fields.personal.college_committed.yes_fields.college_list.colClass]" v-if="props_fields.personal.college_committed.yes_fields.college_list && collegeCommitted == 1">
                <div class="form-group">
                  <label for="college_list" class="form-label" v-if="props_fields.personal.college_committed.yes_fields.college_list.label !=''">{{ props_fields.personal.college_committed.yes_fields.college_list.label }} <sup class="text-danger" v-if="props_fields.personal.college_committed.yes_fields.college_list.required">*</sup></label>
                  <Select2 id="college_list" v-model="UpdateProfile.college_committed" :options="collegeOptions" name="form.college_list" :settings="{ placeholder: 'Click to Select the College'}" />
                </div>
              </div>
            </div>
          </form>
        </div>
      </b-modal>
      <b-modal id="modal-center3" size="lg" ok-title="Update" @ok="updateContactInfo" centered title="Contact Information">
        <div class="form-body">
          <form>
            <div class="row">
              <div class="col-md-6" v-if="!isNotalliancefastpitch && this.userProfileData.membership.id != 5">
                <div class="form-group">
                  <label class="form-label" for="parent_first_name">Parent First Name<sup class="text-danger">*</sup></label>
                  <input type="text" v-model="UpdateProfile.parent_first_name" class="form-control" id="parent_first_name" name="verifyPersonal.parent_first_name" placeholder="" value="">
                  <span class="text-danger">{{ errors.parent_first_name }}</span>
                </div>
              </div>
              <div class="col-md-6" v-if="!isNotalliancefastpitch && this.userProfileData.membership.id != 5">
                <div class="form-group">
                  <label class="form-label" for="parent_middle_name">Parent Middle Name</label>
                  <input type="text" v-model="UpdateProfile.parent_middle_name" class="form-control" id="verify-parent_middle_name" name="verifyPersonal.parent_middle_name" placeholder="" value="">
                  <span class="text-danger">{{ errors.parent_middle_name }}</span>
                </div>
              </div>
              <div class="col-md-6" v-if="!isNotalliancefastpitch && this.userProfileData.membership.id != 5">
                <div class="form-group">
                  <label class="form-label" for="parent_last_name">Parent Last Name<sup class="text-danger">*</sup></label>
                  <input type="text" v-model="UpdateProfile.parent_last_name" class="form-control" id="verify-parent_last_name" name="verifyPersonal.parent_last_name" placeholder="" value="">
                  <span class="text-danger">{{ errors.parent_last_name }}</span>
                </div>
              </div>
              <div class="col-md-6">
                <div class="form-group">
                  <label class="form-label" for="phone_1">{{ props_fields.contact && props_fields.contact.homephone ? props_fields.contact.homephone.label : 'Phone'}} <sup class="text-danger">*</sup></label>
                  <!-- <input type="text" id="phone_1" v-model="UpdateProfile.phone_1" value="" placeholder="8080 Holister" class="form-control"> -->
                  <vue-tel-input ref="phone" @validate="validateTelinput" v-model="UpdateProfile.phone_1" v-bind="homeProps" :validCharactersOnly="true"></vue-tel-input>
                  <span class="text-danger">{{ errors.phone_1 }}</span>
                </div>
              </div>
              <div class="col-md-6">
                <div class="form-group">
                  <label class="form-label" for="phone_2">{{ props_fields.contact && props_fields.contact.mobilephone ? props_fields.contact.mobilephone.label : 'Alternative Phone'}}</label>
                  <!-- <input type="text" id="phone_2" v-model="UpdateProfile.phone_2" value="" placeholder="Apt.400" class="form-control"> -->
                  <vue-tel-input ref="phone" @validate="validateTelinput2" v-model="UpdateProfile.phone_2" v-bind="mobileProps" :validCharactersOnly="true"></vue-tel-input>
                  <span class="text-danger">{{ errors.phone_2 }}</span>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-md-6">
                <div class="form-group">
                  <label class="form-label" for="email">Email <sup class="text-danger">*</sup></label>
                  <input type="text" id="email" v-model="UpdateProfile.email" value="" placeholder="William.smith@email.com" class="form-control">
                  <span class="text-danger">{{ errors.email }}</span>
                </div>
              </div>
            </div>
          </form>
        </div>
      </b-modal>
      <b-modal id="modal-center" size="lg" ok-title="Update" @ok="updateAddressInfo" centered title="Address Information">
        <div class="form-body">
          <form>
            <div class="row" v-if="props_fields.contact.address_type">
                <div class="col-md-4">
                    <div class="form-group">
                        <label for="address_type" class="form-label">{{ props_fields.contact.address_type.label }}<sup class="text-danger" v-if="props_fields.contact.address_type.required">*</sup></label>
                        <select name="UpdateProfile.address_type" id="address_type" class="form-control" v-model="UpdateProfile.address_type">
                            <option v-for="(item, k) in props_fields.contact.address_type.options" :key="'address-type-'+k" :value="item.value">{{ item.label }}</option>
                        </select>
                    </div>
                </div>
            </div>
            <div class="row">
              <div class="col-md-6">
                <div class="form-group">
                  <label class="form-label" for="address1">{{ props_fields.contact.address1.label || 'Mailling Address'}} <sup class="text-danger" v-if="props_fields.contact.address1.required">*</sup><span v-if="props_fields.contact.address1.charLimitText" class="text-danger text-xs" style="margin-left:30px;">{{ props_fields.contact.address1.charLimitText }}</span></label>
                  <input type="text" id="address1" v-model="UpdateProfile.address1" value="" :maxlength=props_fields.contact.address1.charLimitValue placeholder="8080 Holister" class="form-control">
                  <span class="text-sm text-danger">{{ errors.address1 }}</span>
                </div>
              </div>
              <div class="col-md-6">
                <div class="form-group">
                  <label class="form-label" for="address2">{{ props_fields.contact.address2.label || 'Address2'}} <span v-if="props_fields.contact.address2.charLimitText" class="text-danger text-xs" style="margin-left:30px;">{{ props_fields.contact.address2.charLimitText }}</span></label>
                  <input type="text" id="address2" v-model="UpdateProfile.address2" value="" :maxlength=props_fields.contact.address2.charLimitValue placeholder="Apt.400" class="form-control">
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-md-4" v-if="props_fields.contact.country">
                  <div class="form-group">
                      <label for="Country" class="form-label">{{ props_fields.contact.country.label }} <sup class="text-danger" v-if="props_fields.contact.country.required">*</sup></label>
                      <select class="form-control" id="country" @change="removeStateCode" v-model="UpdateProfile.country" name="UpdateProfile.country" v-if="isNotalliancefastpitch">
                          <option value="">Select</option>
                          <option :value="listOfCountry.country" v-for="listOfCountry in listOfCountries" :key="listOfCountry.country" :selected="listOfCountry.country == 'Bahamas'">{{ listOfCountry.country }}</option>
                      </select>
                      <select class="form-control" id="country" @change="removeStateCode" v-model="UpdateProfile.country" name="UpdateProfile.country" v-else>
                        <option value="">Select</option>
                        <option :value="listOfCountry.country" v-for="listOfCountry in props_fields.contact.country.options" :key="listOfCountry.country" :selected="listOfCountry.country == 'Bahamas'">{{ listOfCountry.country }}</option>
                      </select>
                      <span class="text-sm text-danger">{{ errors.country}}</span>
                  </div>
              </div>
              <div class="col-md-4">
                <div class="form-group">
                  <label class="form-label" for="city">City <sup class="text-danger" v-if="props_fields.contact.city.required">*</sup></label>
                  <input type="text" id="city" v-model="UpdateProfile.city" value="" placeholder="FL" class="form-control">
                  <span class="text-sm text-danger">{{ errors.city }}</span>
                </div>
              </div>
              <div class="col-md-4" v-if="UpdateProfile.country == 'USA'">
                  <div class="form-group">
                      <label for="state_code" class="form-label">{{ props_fields.contact.state_code.label }} <sup class="text-danger" v-if="props_fields.contact.state_code.required">*</sup></label>
                      <select class="form-control" id="state_code" v-model="UpdateProfile.state_code" name="UpdateProfile.state_code">
                          <option value="">Select</option>
                          <option  v-for="listOfState in listOfStates" :key="listOfState.state_code" :value="listOfState.state_code">{{ listOfState.state }}</option>
                      </select>
                      <span class="text-sm text-danger">{{ errors.state_code}}</span>
                  </div>
              </div>
              <div class="col-md-4" v-else-if="UpdateProfile.country == 'Canada'">
                <div class="form-group">
                  <label for="state_code" class="form-label">{{ props_fields.contact.state_code.label }}  <sup class="text-danger" v-if="props_fields.contact.state_code.required">*</sup> </label>
                  <select class="form-control" id="state_code" v-model="UpdateProfile.state" name="UpdateProfile.state" v-if="isNotalliancefastpitch">
                    <option value="">Select</option>
                    <option  v-for="listOfState in listOfCAStates" :key="listOfState.state_code" :value="listOfState.state">{{ listOfState.state }}</option>
                  </select>
                  <select class="form-control" id="state_code" v-model="UpdateProfile.state_code" name="UpdateProfile.state_code" v-else>
                    <option value="">Select</option>
                    <option  v-for="listOfState in props_fields.contact.state_code.option" :key="listOfState.state_code" :value="listOfState.state_code">{{ listOfState.state }}</option>
                  </select>
                  <span class="text-sm text-danger">{{ errors.state }}</span>
                </div>
              </div>
              <div class="col-md-4" v-else>
                  <div class="form-group">
                      <label for="state_code" class="form-label">{{ props_fields.contact.other_state.label }} </label>
                      <input type="text" class="form-control" maxlength="45" v-model="UpdateProfile.state" name="UpdateProfile.state">
                      <span class="text-sm text-danger">{{ errors.state}}</span>
                  </div>
              </div>
              <!-- <div class="col-md-4">
                <div class="form-group">
                  <label class="form-label" for="state">State</label>
                  <input type="text" id="state" v-model="UpdateProfile.state" value="" placeholder="FL" class="form-control">
                </div>
              </div> -->
              <div class="col-md-4">
                <div class="form-group">
                  <label class="form-label" for="zip">Postal Code <sup class="text-danger" v-if="props_fields.contact.zip.required">*</sup></label>
                  <input type="text" id="zip" v-model="UpdateProfile.zip" value="" :maxlength="getSiteProps('general.zip_code_length')" placeholder="77080" class="form-control">
                  <span class="text-sm text-danger">{{ errors.zip }}</span>
                </div>
              </div>
              <div class="col-md-4" v-if="!isNotalliancefastpitch">
                <div class="form-group">
                  <label class="form-label" for="zip">Citizenship <sup class="text-danger" v-if="props_fields.contact.citizenship.required">*</sup></label>
                  <select class="form-control" id="citizenship" v-model="UpdateProfile.citizenship" name="UpdateProfile.country">
                    <option value="">Select</option>
                    <option :value="listOfCountry.country" v-for="listOfCountry in listOfCountriesCitizen" :key="listOfCountry.country" :selected="listOfCountry.country == 'Bahamas'">{{ listOfCountry.country }}</option>
                  </select>
                  <span class="text-sm text-danger">{{ errors.citizenship }}</span>
                </div>
              </div>
            </div>
            <template v-if="UpdateProfile.address_type == 'Work'">
                  <div class="row">
                      <div :class="[props_fields.contact.work_address_company_name.colClass]" v-if="props_fields.contact.work_address_company_name">
                          <div class="form-group">
                              <label for="work_address_company_name" class="form-label">{{ props_fields.contact.work_address_company_name.label }}<sup class="text-danger" v-if="props_fields.contact.work_address_company_name.required">*</sup></label>
                              <input type="text" class="form-control" id="work_address_company_name" v-model="UpdateProfile.company_name">
                              <span class="text-sm text-danger">{{ errors.company_name }}</span>
                          </div>
                      </div>
                      <div :class="[props_fields.contact.work_address_company_phone_extension.colClass]" v-if="props_fields.contact.work_address_company_phone_extension">
                          <div class="form-group">
                              <label for="work_address_company_phone" class="form-label">{{ props_fields.contact.work_address_company_phone_extension.label }}<sup class="text-danger" v-if="props_fields.contact.work_address_company_phone_extension.required">*</sup></label>
                              <div class="row">
                                  <div class="col-md-9">
                                      <vue-tel-input ref="phone" @validate="validateTelinput" v-model="UpdateProfile.company_phone" v-bind="companyPhoneExtProps" :validCharactersOnly="true"></vue-tel-input>
                                      <span class="text-sm text-danger">{{ errors.company_phone }}</span>
                                  </div>
                                  <div class="col-md-3">
                                      <input type="text" class="form-control" v-model="UpdateProfile.company_phone_extension" name="UpdateProfile.company_phone_extension" placeholder="Extension" style="background: #F7F7F7;padding: 19px 12px;border-color: #bbb;">
                                      <span class="text-sm text-danger">{{ errors.company_phone_extension }}</span>
                                  </div>
                              </div>
                          </div>
                      </div>
                  </div>
              </template>
            <div :class="[props_fields.contact.parent_citizenship.colClass]" v-if="isChild && props_fields.contact.parent_citizenship">
              <div class="form-group">
                <label for="parent_citizenship" class="form-label">{{ props_fields.contact.parent_citizenship.label }} <sup class="text-danger" v-if="props_fields.contact.parent_citizenship.required">*</sup></label>
                <div class="form-group" v-if="props_fields.contact.parent_citizenship.choices">
                  <template v-for="(choice, key) in props_fields.contact.parent_citizenship.choices">
                    <input type="radio" :id="choice.value" v-model="UpdateProfile.is_parent_citizenship" name="UpdateProfile.parent_citizenship" :value="choice.value" @click="parentCitizenship = choice.value" :key="'radio_' + key">
                    <label class="parent_citizenship-label-span mr-3" :for="choice.value" :key="'parent_citizenship_label_'+key">{{ choice.label }}</label>
                  </template>
                  <span class="text-sm text-danger">{{ errors.is_parent_citizenship}}</span>
                </div>
              </div>
            </div>
            <div :class="[props_fields.contact.parent_citizenship.yes_fields.citizenship.colClass]" v-if="isChild && props_fields.contact.parent_citizenship && props_fields.contact.parent_citizenship.yes_fields.citizenship && parentCitizenship == 1">
              <div class="form-group">
                <label for="citizenship" class="form-label" v-if="props_fields.contact.parent_citizenship.yes_fields.citizenship.label !=''">{{ props_fields.contact.parent_citizenship.yes_fields.citizenship.label }} <sup class="text-danger" v-if="props_fields.parent_citizenship.yes_fields.citizenship.required">*</sup></label>
                <select class="form-control" id="parent_citizenship" v-model="UpdateProfile.parent_citizenship" name="UpdateProfile.parent_citizenship">
                  <option value="">Select citizenship</option>
                  <option :value="listOfCountry.country" v-for="listOfCountry in listOfCountriesCitizen" :key="listOfCountry.country" :selected="listOfCountry.country == 'Bahamas'">{{ listOfCountry.country }}</option>
                </select>
                <span class="text-sm text-danger">{{ errors.parent_citizenship}}</span>
              </div>
            </div>
            <div :class="[props_fields.contact.athlete_citizenship.colClass]" v-if="isChild && props_fields.contact.athlete_citizenship">
              <div class="form-group">
                <label for="athlete_citizenship" class="form-label">{{ props_fields.contact.athlete_citizenship.label }} <sup class="text-danger" v-if="props_fields.contact.athlete_citizenship.required">*</sup></label>
                <div class="form-group" v-if="props_fields.contact.athlete_citizenship.choices">
                  <template v-for="(choice, key) in props_fields.contact.athlete_citizenship.choices">
                    <input type="radio" :id="choice.value" v-model="UpdateProfile.is_athlete_citizenship" name="UpdateProfile.athlete_citizenship" :value="choice.value" @click="athleteCitizenship = choice.value" :key="'radio_' + key">
                    <label class="athlete_citizenship-label-span mr-3" :for="choice.value" :key="'athlete_citizenship_label_'+key">{{ choice.label }}</label>
                  </template>
                  <span class="text-sm text-danger">{{ errors.is_athlete_citizenship}}</span>
                </div>
              </div>
            </div>
            <div :class="[props_fields.contact.athlete_citizenship.yes_fields.citizenship.colClass]" v-if="isChild && props_fields.contact.athlete_citizenship && props_fields.contact.athlete_citizenship.yes_fields.citizenship && athleteCitizenship == 1">
              <div class="form-group">
                <label for="citizenship" class="form-label" v-if="props_fields.contact.athlete_citizenship.yes_fields.citizenship.label !=''">{{ props_fields.athlete_citizenship.yes_fields.citizenship.label }} <sup class="text-danger" v-if="props_fields.contact.athlete_citizenship.yes_fields.citizenship.required">*</sup></label>
                <select class="form-control" id="athlete_citizenship" v-model="UpdateProfile.athlete_citizenship" name="UpdateProfile.athlete_citizenship">
                  <option value="">Select citizenship</option>
                  <option :value="listOfCountry.country" v-for="listOfCountry in listOfCountriesCitizen" :key="listOfCountry.country" :selected="listOfCountry.country == 'Bahamas'">{{ listOfCountry.country }}</option>
                </select>
                <span class="text-sm text-danger">{{ errors.athlete_citizenship}}</span>
              </div>
            </div>
            <div :class="[props_fields.contact.eligible_citizenship.colClass]" v-if="isChild && props_fields.contact.eligible_citizenship" v-show="parentCitizenship == 2 || athleteCitizenship == 2">
              <div class="form-group">
                <label for="eligible_citizenship" class="form-label">{{ props_fields.contact.eligible_citizenship.label }} <sup class="text-danger" v-if="props_fields.contact.eligible_citizenship.required">*</sup></label>
                <div class="form-group" v-if="props_fields.contact.eligible_citizenship.choices">
                  <template v-for="(choice, key) in props_fields.contact.eligible_citizenship.choices">
                    <input type="radio" :id="choice.value" v-model="UpdateProfile.is_eligible_citizenship" name="UpdateProfile.eligible_citizenship" :value="choice.value" @click="eligibleCitizenship = choice.value" :key="'radio_' + key">
                    <label class="eligible_citizenship-label-span mr-3" :for="choice.value" :key="'eligible_citizenship_label_'+key">{{ choice.label }}</label>
                  </template>
                  <span class="text-sm text-danger">{{ errors.is_eligible_citizenship}}</span>
                </div>
              </div>
            </div>
            <div :class="[props_fields.contact.eligible_citizenship.yes_fields.citizenship.colClass]" v-if="isChild && props_fields.contact.eligible_citizenship && props_fields.contact.eligible_citizenship.yes_fields.citizenship && eligibleCitizenship == 1 && (parentCitizenship == 2 || athleteCitizenship == 2)">
              <div class="form-group">
                <label for="citizenship" class="form-label" v-if="props_fields.contact.eligible_citizenship.yes_fields.citizenship.label !=''">{{ props_fields.contact.eligible_citizenship.yes_fields.citizenship.label }} <sup class="text-danger" v-if="props_fields.contact.eligible_citizenship.yes_fields.citizenship.required">*</sup></label>
                <select class="form-control" id="eligible_citizenship" v-model="UpdateProfile.eligible_citizenship" name="UpdateProfile.eligible_citizenship">
                  <option value="">Select citizenship</option>
                  <option :value="listOfCountry.country" v-for="listOfCountry in listOfCountriesCitizen" :key="listOfCountry.country" :selected="listOfCountry.country == 'Bahamas'">{{ listOfCountry.country }}</option>
                </select>
                <span class="text-sm text-danger">{{ errors.eligible_citizenship}}</span>
              </div>
            </div>

            <b-form-group label="The Water Skier magazine subscription" label-class="form-label" label-for="printed_magazine" v-if="enableMagazine(UpdateProfile)">
              <b-form-checkbox v-model="UpdateProfile.printed_magazine" name="printed_magazine" switch value="0" unchecked-value="1" size="md" id="printed_magazine"> Receive a printed copy of the Water Skier magazine</b-form-checkbox>
            </b-form-group>
          </form>
        </div>
      </b-modal>
      <b-modal id="edit-demographic" size="lg" ok-title="Update" @ok="updateDemographicInfo" centered :title="props_fields.demographic.tab_title || 'Demographic Details'">
        <div class="form-body">
            <form>
                <div class="row">
                    <div class="col-md-6" v-if="props_fields.demographic.race">
                        <div class="form-group radiotext">
                            <label for="race" class="form-label"><strong>{{ props_fields.demographic.race.label || 'Race'}}</strong><sup class="text-danger">*</sup></label>
                            <div v-for="(diversity,i) in diversities" :key="i">
                                <input type="radio" :id="'diversity'+diversity.name" name="UpdateProfile.diversity_id" :value="diversity.id" v-model="UpdateProfile.diversity_id">
                                <label  :for="'diversity'+diversity.name">{{diversity.name}}</label> <br>
                            </div>
                            <span class="text-sm text-danger">{{ errors.diversity_id }}</span>
                        </div>
                    </div>
                    <div class="col-md-6" v-if="props_fields.demographic.ethnicity">
                        <div class="form-group radiotext ">
                            <label for="ethnicitystatus" class="form-label"><strong>Ethnicity</strong><sup  class="text-danger">*</sup></label>
                            <div class="form-group">
                                <label for="hispanic-or-latino"><input type="radio" id="hispanic-or-latino" name="ethnicity" value="Hispanic or Latino" v-model="UpdateProfile.ethnicity"> Hispanic or Latino</label><br>
                                <label for="non-hispanic-or-latino"> <input type="radio" id="non-hispanic-or-latino" name="ethnicity" value="Non-Hispanic or Latino" v-model="UpdateProfile.ethnicity"> Non-Hispanic or Latino</label><br>
                                <label for="ethnicity-Prefer-not-to-say"> <input type="radio" id="ethnicity-Prefer-not-to-say" name="ethnicity" value="Prefer not to say" v-model="UpdateProfile.ethnicity"> Prefer not to say</label><br>
                                <span class="text-sm text-danger">{{ errors.ethnicity }}</span>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-6" v-if="props_fields.demographic.disability_status">
                        <div class="form-group radiotext ">
                            <label for="disabilitystatus" class="form-label"><strong>Disability Status</strong>  <sup  class="text-danger">*</sup></label>
                            <div class="form-group">
                                <label for="normal"> <input type="radio" id="normal" name="verifyPersonal.disability" value="Persons without Disability" v-model="UpdateProfile.disability"> Persons without Disability</label><br>
                                <label for="Persons-with-Disability"><input type="radio" id="Persons-with-Disability" name="verifyPersonal.disability" value="Persons with Disability" v-model="UpdateProfile.disability"> Persons with Disability</label><br>
                                <label for="disability-Prefer-not-to-say"><input type="radio" id="disability-Prefer-not-to-say" name="verifyPersonal.disability" value="Prefer not to say" v-model="UpdateProfile.disability"> Prefer not to say</label><br>
                                <span class="text-sm text-danger">{{ errors.disability }}</span>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-6" v-if="props_fields.demographic.veteran_status">
                        <div class="form-group radiotext ">
                            <label for="veteranstatus" class="form-label"><strong>Veteran Status</strong> <sup class="text-danger">*</sup></label>
                            <div class="form-group">
                                <div>
                                    <label for="veteran">
                                        <input type="radio" id="veteran" name="verifyPersonal.veteran_status" value="Veteran" v-model="UpdateProfile.veteran_status"> Veteran
                                    </label>
                                    <br>
                                </div>
                                <div>
                                    <label for="non-Veteran">
                                        <input type="radio" id="non-Veteran" name="verifyPersonal.veteran_status" value="Non-Veteran" v-model="UpdateProfile.veteran_status"> Non-Veteran
                                    </label>
                                    <br>
                                </div>
                                <div>
                                    <label for="Active-or-Reserve">
                                        <input type="radio" id="Active-or-Reserve" name="verifyPersonal.veteran_status" value="Active or Reserve" v-model="UpdateProfile.veteran_status"> Active or Reserve
                                    </label>
                                    <br>
                                </div>
                                <div>
                                    <label for="vet-prefer-not-to-say">
                                        <input type="radio" id="vet-prefer-not-to-say" name="verifyPersonal.veteran_status" value="Prefer not to say" v-model="UpdateProfile.veteran_status"> Prefer not to say
                                    </label>
                                    <br>
                                </div>
                                <span class="text-sm text-danger">{{ errors.veteran_status }}</span>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-6" v-if="props_fields.demographic.primary_area_interest">
                            <div class="form-group">
                                <label for="area_of_interest" class="form-label">
                                    {{ props_fields.demographic.primary_area_interest.label }}
                                    <sup class="text-danger" v-if="props_fields.demographic.primary_area_interest.required">*</sup>
                                </label>
                                <select name="verifyPersonal.area_of_interest" id="verify-area_of_interest" class="form-control" v-model="UpdateProfile.primary_area_interest">
                                    <option value="">Select</option>
                                    <option v-for="(item, k) in props_fields.demographic.primary_area_interest.options" :key="'v-pai'+k" :value="item">{{ item }}</option>
                                </select>
                                <span class="text-sm text-danger">{{ errors.primary_area_interest }}</span>
                            </div>
                        </div>
                        <div class="col-md-6" v-if="props_fields.demographic.category">
                            <div class="form-group">
                                <label for="category" class="form-label">{{ props_fields.demographic.category.label }}<sup class="text-danger" v-if="props_fields.demographic.category.required">*</sup></label>
                                <select name="verifyPersonal.category" id="verify-category" class="form-control" v-model="UpdateProfile.category">
                                    <option value="">Select</option>
                                    <option v-for="(item, k) in categories" :key="'verify-catd-'+k" :value="item.id">{{ item.name }}</option>
                                </select>
                                <span class="text-sm text-danger">{{ errors.category }}</span>
                            </div>
                        </div>
                        <div class="col-md-6" v-if="props_fields.demographic.issf">
                            <div class="form-group">
                                <label for="issf" class="form-label">{{ props_fields.demographic.issf.label }}<sup class="text-danger" v-if="props_fields.demographic.issf.required">*</sup></label>
                                <input type="text" class="form-control" name="verifyPersonal.issf" v-model="UpdateProfile.issf">
                                <span class="text-sm text-danger">{{ errors.issf }}</span>
                            </div>
                        </div>
                        <div class="col-md-6" v-if="props_fields.demographic.cmp">
                            <div class="form-group">
                                <label for="cmp_no" class="form-label">{{ props_fields.demographic.cmp.label }}<sup class="text-danger" v-if="props_fields.demographic.cmp.required">*</sup></label>
                                <input type="text" class="form-control" name="verifyPersonal.cmp_no" v-model="UpdateProfile.cmp_no">
                                <span class="text-sm text-danger">{{ errors.cmp_no }}</span>
                            </div>
                        </div>
                        <div class="col-md-6" v-if="props_fields.demographic.nra">
                            <div class="form-group">
                                <label for="nra_no" class="form-label">{{ props_fields.demographic.nra.label }}<sup class="text-danger" v-if="props_fields.demographic.nra.required">*</sup></label>
                                <input type="text" class="form-control" name="verifyPersonal.nra_no" v-model="UpdateProfile.nra_no">
                                <span class="text-sm text-danger">{{ errors.nra_no }}</span>
                            </div>
                        </div>
                        <div class="col-md-6" v-if="props_fields.demographic.sasp">
                            <div class="form-group">
                                <label for="sasp_no" class="form-label">{{ props_fields.demographic.sasp.label }}<sup class="text-danger" v-if="props_fields.demographic.sasp.required">*</sup></label>
                                <input type="text" class="form-control" name="verifyPersonal.sasp_no" v-model="UpdateProfile.sasp_no">
                                <span class="text-sm text-danger">{{ errors.sasp_no }}</span>
                            </div>
                        </div>
                        <div class="col-md-6" v-if="props_fields.demographic.sctp">
                            <div class="form-group">
                                <label for="sctp_no" class="form-label">{{ props_fields.demographic.sctp.label }}<sup class="text-danger" v-if="props_fields.demographic.sctp.required">*</sup></label>
                                <input type="text" class="form-control" name="verifyPersonal.sctp_no" v-model="UpdateProfile.sctp_no">
                                <span class="text-sm text-danger">{{ errors.sctp_no }}</span>
                            </div>
                        </div>
                        <div class="col-md-6" v-if="props_fields.demographic.hear_about">
                            <div class="form-group">
                                <label for="hear_about" class="form-label">{{ props_fields.demographic.hear_about.label }} <sup class="text-danger" v-if="props_fields.demographic.hear_about.required">*</sup></label>
                                <select id="verify_hear_about" class="form-control" name="verifyPersonal.hear_about" v-model="UpdateProfile.hear_about">
                                    <option value="">Select</option>
                                    <option v-for="(item, k) in props_fields.demographic.hear_about.options" :key="'verify-hear-about-'+k" :value="item">{{ item }}</option>
                                </select>
                                <span class="text-sm text-danger">{{ errors.hear_about }}</span>
                            </div>
                        </div>
                    </div>
                </div>
            </form>
        </div>
      </b-modal>
      <b-modal id="modalMembership" size="lg" ok-title="Update" @ok="updateMemberDetails" centered title="Membership Information">
        <form>
          <div class="row">
            <div  class="col-md-6" v-if="showMemberTypeDropdown">
              <div class="form-group">
                <label class="form-label">Membership Type</label>
                <b-form-select v-if="userProfileData.activeRegCount <= 1" v-model="UpdateProfile.membership_id" :disabled="(subdomain=='alliancefastpitch')" :options="updateMemberTypeData" plain ></b-form-select>
                <input v-else type="text" :disabled="(subdomain=='alliancefastpitch') || userProfileData.activeRegCount > 1" v-model="userProfileData.membership.name" placeholder="" class="form-control">
              </div>
            </div>
            <div class="col-md-6" v-if="getSiteProps('membershipInfoEdit.membershipStatus')" @click="deleteEvent()">
              <div class="form-group">
                <label class="form-label">Membership Status</label>
                <b-form-select
                    name="membership_stauts"
                    id="membership_stauts"
                    v-model="UpdateProfile.membership_status"
                    :options="membershipStatusLists"
                    plain
                ></b-form-select>
              </div>
            </div>
          </div>
          <div class="row" v-if="userProfileData.membership.member_type_id!=3 || userProfileData.personal_details.relation == 0">
            <div class="col-md-6" v-if="getSiteProps('membershipInfoEdit.startDate')">
              <div class="form-group">
                <label class="form-label">Start Date</label>
                <date-pick
                    v-model="UpdateProfile.start_date"
                    :pickTime="false"
                    name="UpdateProfile.start_date"
                    id="startDate"
                    :format="'MM/DD/YYYY'"
                    :selectableYearRange="{from: 1900, to: 2021}"
                    v-mask="'##/##/####'"
                    placeholder="MM/DD/YYYY"
                    class="form-control"
                    :isDateDisabled="isStartDisabledDate"
                ></date-pick>
                <span class="text-sm text-danger">{{
                    errors.start_date
                  }}</span>
              </div>
            </div>
            <div  class="col-md-6" v-if="getSiteProps('membershipInfoEdit.expireDate') && UpdateProfile.membership && UpdateProfile.membership.period != 999">
              <div class="form-group">
                <label class="form-label">Expiration Date</label>
                <date-pick
                    v-model="UpdateProfile.valid_thru"
                    :pickTime="false"
                    name="UpdateProfile.valid_thru"
                    id="validThru"
                    :format="'MM/DD/YYYY'"
                    :selectableYearRange="{from: 2022, to: 2025}"
                    v-mask="'##/##/####'"
                    placeholder="MM/DD/YYYY"
                    class="form-control"
                    :isDateDisabled="isEndDisabledDate"
                ></date-pick>
                <span class="text-sm text-danger">{{
                    errors.end_date
                  }}</span>
              </div>
            </div>
          </div>
          <div class="row" v-if="!ignoreWaiverMembership.includes(userProfileData.membership.id) || (this.subdomain === 'shooting' && userProfileData.membership.member_type_id!=7)">
            <div  class="col-md-6" v-if="getSiteProps('membershipInfoEdit.safeSportStatus')">
              <div class="form-group">
                <label class="form-label">Safe Sport Training Status</label>
                <select name="safesports_status" id="safesports_status" v-model="UpdateProfile.safesports_status" class="form-control">
                  <!-- <option value="">select</option> -->
                  <option value="0">Not Started</option>
                  <option value="1">In Progress</option>
                  <option value="3">Complete</option>
                  <option value="13">Expired</option>
                </select>
              </div>
            </div>
            <div  class="col-md-6" v-if="getSiteProps('membershipInfoEdit.safeSportStatus')">
              <div class="form-group">
                <label class="form-label">Safe Sport Expiration</label>
                <date-pick
                    v-model="UpdateProfile.safesports_expiration"
                    :pickTime="false"
                    name="UpdateProfile.safesports_expiration"
                    id="validThru"
                    :format="'MM/DD/YYYY'"
                    :selectableYearRange="{from: 2022, to: 2025}"
                    v-mask="'##/##/####'"
                    placeholder="MM/DD/YYYY"
                    class="form-control"
                ></date-pick>
              </div>
            </div>
            <div class="col-md-6" v-if="getSiteProps('membershipInfoEdit.safeSportGuid')">
              <div class="form-group">
                <label class="form-label">Safesport GUID</label>
                <input type="text" id="safesports_guid" v-model="UpdateProfile.safesports_guid" placeholder="" class="form-control" />
              </div>
            </div>
          </div>
          <div class="row" v-if="!ignoreWaiverMembership.includes(userProfileData.membership.id) || (userProfileData.membership.id == 4 && age > 17) || (this.subdomain === 'shooting'&&userProfileData.membership.member_type_id!=7)">
            <div  class="col-md-6" v-if="getSiteProps('membershipInfoEdit.backgroundScreenStatus')">
              <div class="form-group">
                <label class="form-label">Background Screen Status</label>
                <select name="background_screen" id="background_screen" v-model="UpdateProfile.background_screen" class="form-control">
                  <option value="0">Not Started</option>
                  <option value="1">In Progress</option>
                  <option value="2">Complete</option>
                  <option value="3" v-if="subdomain == 'shooting'">Canceled</option>
                  <option value="7">In Complete</option>
                  <option value="6">Expired</option>
                </select>
              </div>
            </div>
            <div  class="col-md-6" v-if="getSiteProps('membershipInfoEdit.backgroundExpireDate')">
              <div class="form-group">
                <label class="form-label">Background Screen Expiration</label>
                <date-pick
                    v-model="UpdateProfile.background_screen_expiration"
                    :pickTime="false"
                    name="UpdateProfile.background_screen_expiration"
                    id="validThru"
                    :format="'MM/DD/YYYY'"
                    v-mask="'##/##/####'"
                    :selectableYearRange="{from: 2022, to: 2025}"                    
                    placeholder="MM/DD/YYYY"
                    class="form-control" :not-before="disabledBefore" :not-after="disabledAfter"
                ></date-pick>
              </div>
            </div>
          </div>
          <div class="row" v-if="age >= 16">
            <div  class="col-md-6" v-if="getSiteProps('membershipInfoEdit.backgroundMvrReview')">
              <div class="form-group">
                <label class="form-label">MVR Review Status</label>
                <select name="background_mvr_review" id="background_mvr_review" v-model="UpdateProfile.background_mvr_review" class="form-control">
                  <option value="0">Not Started</option>
                  <option value="1">In Progress</option>
                  <option value="2">Complete</option>
                  <option value="7">In Complete</option>
                  <option value="6">Expired</option>
                </select>
              </div>
            </div>
            <div  class="col-md-6" v-if="getSiteProps('membershipInfoEdit.backgroundMvrReviewExpiration') && age >= 16">
              <div class="form-group">
                <label class="form-label">MVR Review Expiration</label>
                <date-pick
                    v-model="UpdateProfile.background_mvr_review_expiration"
                    :pickTime="false"
                    name="UpdateProfile.background_mvr_review_expiration"
                    id="validThru"
                    :format="'MM/DD/YYYY'"
                    v-mask="'##/##/####'"
                    :selectableYearRange="{from: 2022, to: 2025}"                    
                    placeholder="MM/DD/YYYY"
                    class="form-control"
                ></date-pick>
              </div>
            </div>
          </div>
          <div class="row">
            <div  class="col-md-6" v-if="UpdateProfile.membership_status == 'Suspended' || UpdateProfile.membership_status == 'Deceased'">
              <div class="form-group">
                <label class="form-label">Notes</label>
                <input type="text" id="note" v-model="UpdateProfile.note" placeholder="" class="form-control">
              </div>
            </div>
          </div>
        </form>
      </b-modal>
      <b-modal id="modalMemberGroup" size="lg" ok-title="Update" @ok="updateGroupInfo()" centered title="Group Information">
        <form>
          <div class="row">             
            <div class="col-md-12">
              <div class="form-group">
                <label class="form-label">Groups</label>
                <Select2 v-model="memberGroupIds" name="groupList" id="group_List" :options="groupList" :settings="{ multiple: true, placeholder: 'Click to select one or multiple'}" />                 
              </div>
            </div>
          </div>          
        </form>
      </b-modal>

     <b-modal id="note-modal" hide-footer  :no-close-on-backdrop="true">
         <template #modal-title>
		<h3 v-if="!edit_note_id">Add Note</h3> 
    <h3  v-else>Edit Note</h3> 
    </template>
    
		<div class="form-group">
        <label for="note">Note:</label>
			<textarea type="text" id="note" v-model="note" size="lg" placeholder="Enter Note" class="form-control" ></textarea>
      <p class="text-danger note-error"></p>
   
        </div>
    
<div class="form-group">
   <label for="notetype">Note Type:  </label>
      <select name="notetypeSelect" id="notetype" v-model="selectedNote"  class="form-control">
        <option disabled value=""> Select </option>
        <template v-if="Notetype.options">
          <option v-for="(Notetypes,i) in Notetype.options" :key="i" :value=i>{{ Notetypes }}
                        </option>
        </template>
      
    </select>
    <p class="text-danger note-type-error"></p>
</div>
<!-- <div class="form-group">
  
    <b-form-checkbox  v-model="notestatus" name="notestatus" switch value="1">
     Status
    </b-form-checkbox>
 
</div> -->
    <div>
        <button v-if="!edit_note_id"  type="button" class="btn btn-sm btn-primary" @click="storenote()" :disabled="noteProgress"><i class="fa mr-2" :class="noteProgress ? 'fa-circle-notch' : 'fa-save'"></i> Submit</button>
        <button v-else  type="button" class="btn btn-sm btn-primary" @click="updatenote()" :disabled="noteProgress"><i class="fa mr-2" :class="noteProgress ? 'fa-circle-notch' : 'fa-save'"></i> Update</button>
       &nbsp;
        <!-- <button  type="button" class="btn btn-sm btn-secondary"  @click="$bvModal.hide('note-modal')">Close</button> -->
    </div>
      </b-modal>


      <b-modal id="changeDiscipline" title="Change Discipline" @ok="changeDiscipline" @hidden="resetDisciplineData" ok-title="Update">
        <b-form-select v-model="changeDisciplineData.sports_discipline" :state="DonationSportDisciplineDpDwn" @change="checkDiscipline()">
          <b-form-select-option v-for="(SportDiscipline, id) in  sortedDonations(listOfDonationSportDiscipline)" :value="SportDiscipline.id" :key="id">
            {{ SportDiscipline.name }}
          </b-form-select-option>
        </b-form-select>
      </b-modal>

      <b-modal id="edit-position-modal" :title="editPositionModal.title" size="lg" ref="modal" @ok="handlePositionOk">
                    <form ref="form" @submit.stop.prevent="handlePositionSubmit">
                      <div class="row">
                        <div class="col-md-6">
                          <label class="pt-4 form-label" for="feedback-name">Member ID</label>
                          <input type="text" disabled class="form-control" v-model="editPositionModal.confirmationcode">
                          <label class="pt-4 form-label" for="feedback-name">Group</label>
                          <input type="text" disabled class="form-control" v-model="editPositionModal.groupname">
                        </div>
                        <div class="col-md-6">
                          <label class="pt-4 form-label" for="feedback-name">Member Name</label>
                          <input type="text" disabled class="form-control" v-model="editPositionModal.member">
                          <label class="pt-4 form-label" for="feedback-name">Position</label><span class="text-danger"> * </span>
                          <b-form-select
                              id="inline-form-custom-select-pref"
                              class="mb-2 mr-sm-2 mb-sm-0 select_position"
                              v-model="editPositionModal.content.position_id"
                              :options="positions"
                              :value="positions.id"
                              size="sm"
                          ></b-form-select>
                          <div class="text-sm text-danger">{{ position_err }}</div>
                        </div>
                        <div class="col-md-6">
                          <label class="pt-4 form-label" for="feedback-name">Sport Discipline</label><span class="text-danger"> * </span>
                          <b-form-select
                              id="inline-form-custom-select-pref"
                              class="mb-2 mr-sm-2 mb-sm-0 select_position"
                              v-model="editPositionModal.content.sport_discipline"
                              size="sm"
                          >
                            <option value="">Select Sport Discipline</option>
                            <option v-for="(discipline, index) in selectdisciplines" :value="index"
                                    :key="'types'+index">{{ discipline }}
                            </option>
                          </b-form-select>
                          <div class="text-sm text-danger">{{ sport_discipline_err }}</div>
                          <label class="pt-4 form-label" for="feedback-name">Term To Date</label>
                          <div class="input-group birthdate">
                            <div class="birthdaypicker">
                              <date-pick
                                  id="term_to_date"
                                  v-model="editPositionModal.content.expiration_date"
                                  :format="'YYYY-MM-DD'"
                                  :displayFormat="'MM/DD/YYYY'"
                                  v-mask="'##/##/####'"
                              ></date-pick>
                            </div>
                          </div>
                        </div>
                        <div class="col-md-6">
                          <label class="pt-4 form-label" for="feedback-name">Term From Date</label>
                          <div class="input-group birthdate">
                            <div class="birthdaypicker">
                              <date-pick
                                  id="term_from_date"
                                  v-model="editPositionModal.content.effective_date"
                                  :format="'YYYY-MM-DD'"
                                  :displayFormat="'MM/DD/YYYY'"
                                  v-mask="'##/##/####'"
                              ></date-pick>
                            </div>
                          </div>
                          </div>
                      </div>


                    </form>
                  </b-modal>

<familymemberprofileadd ref="familyMemberProfileAddComponent" @memfresh="loadMembersInfo(); memrefresh($event)" ></familymemberprofileadd>
<!-- Edit modal -->
<!--      <b-modal
          id="edit-modal"
          :title="editModal.title"
          size="lg"
          ref="modal"
          @ok="handleOk"
          @hide="resetEditModal"
      >
        <div class="row">
          <div class="col-md-6">
            <form ref="form" @submit.stop.prevent="handleSubmit">
              <div>
                <label class="pt-4 form-label" for="feedback-code"
                >Group</label
                >
                <b-form-select
                    id="inline-form-custom-select-pref"
                    class="mb-2 mr-sm-2 mb-sm-0"
                    v-model="editModal.content.committee_group_id"
                    :options="groups"
                    :value="groups.id"
                    size="sm"
                ></b-form-select
                >
              </div>
              <div>
                <label class="pt-4 form-label" for="feedback-position"
                >Position</label
                >
                <b-form-select
                    id="inline-form-custom-select-pref"
                    class="mb-2 mr-sm-2 mb-sm-0"
                    v-model="editModal.content.position_id"
                    :options="committee_positions"
                    :value="committee_positions.id"
                    size="sm"
                ></b-form-select
                >
              </div>
              <div>
                <label class="pt-4 form-label" for="feedback-region"
                >Region</label
                >
                <b-form-input
                    @keydown="errors.clear('region')"
                    v-model="editModal.content.region"
                    :class="errors.has('region') ? 'is-invalid' : ''"
                    id="feedback-region"
                ></b-form-input>
                <b-form-invalid-feedback
                    :state="!errors.has('region')"
                >
                  {{ errors.get("region") }}</b-form-invalid-feedback
                >
              </div>
            </form>
          </div>
          <div class="col-md-6">
            <div>
              <label class="pt-4 form-label" for="feedback-group"
              >Committee</label
              >
              <b-form-select
                  id="inline-form-custom-select-pref"
                  class="mb-2 mr-sm-2 mb-sm-0"
                  v-model="editModal.content.committee_id"
                  :options="committees"
                  :value="committees.id"
                  size="sm"
              ></b-form-select
              >
            </div>
            <div class="pt-2">
              <label
                  class="pt-4 form-label"
                  for="feedback-effective-date"
              >Effective Date</label
              >
              <div class="input-group birthdate">
                <div class="birthdaypicker">
                  <date-pick
                      v-model="editModal.content.effective_date"
                      :format="'YYYY-MM-DD'"
                      :displayFormat="'MM/DD/YYYY'"
                  ></date-pick>
                </div>
                <span class="text-sm text-danger">{{
                    errors.get("effective_date")
                  }}</span>
              </div>
            </div>
            <div class="pt-2">
              <label
                  class="pt-4 form-label"
                  for="feedback-end-date"
              >Expiration Date</label
              >
              <div class="input-group birthdate">
                <div class="birthdaypicker">
                  <date-pick
                      v-model="editModal.content.expiration_date"
                      :format="'YYYY-MM-DD'"
                      :displayFormat="'MM/DD/YYYY'"
                  ></date-pick>
                </div>
                <span class="text-sm text-danger">{{
                    errors.get("expiration_date")
                  }}</span>
              </div>
            </div>
          </div>
        </div>
      </b-modal>-->
    </div>
</template>

<script>
  import Vue from "vue";
  import { EventBus } from '@/event-bus';

  const validateTitle = title => {
    if (!title.length) {
      return { valid: false, error: "This field is required" };
    } 
    return { valid: true, error: null };
  };

  const validateName = (name, fieldName = '') => {
    let names = '';
    if(name != null){
      names = name.trim();
    }
    let fieldText = ( fieldName != '' ? fieldName : "This field");

    if (!names.length) {
      return { valid: false, error: fieldText+ " is required" };
    }
    if (!names.match(/^[a-zA-Z\s]*$/))
    {
      return { valid: false, error:  fieldText+ " must be letters only. No other characters" };
    }
    return { valid: true, error: null };
  };
  const validatePhone2 = (phone, validTelinput2) => {
    if(validTelinput2 == false && phone.length){
      return { valid: false, error: 'Enter the Exact phone number(Example:+XX XX XXXX XXXX).' };
    }
    return { valid: true, error: null };
  }
  const validateLastName = (name, fieldName = '') => {
    let names = '';
    if(name != null){
      names = name.trim();
    }
    let fieldText = ( fieldName != '' ? fieldName : "This field");

    if (!names.length) {
      return { valid: false, error: fieldText+ " is required" };
    }
    if (!names.match(/^[a-zA-Z-\s]*$/))
    {
      return { valid: false, error:  fieldText+ " must be letters and hyphens only. No other characters" };
    }
    return { valid: true, error: null };
  };

  const validatePhone = (phone, validTelinput, phoneField= '' ) => {
    let phoneText = ( phoneField != '' ? phoneField : "This field");
    if (!phone.length) {
      return { valid: false, error: phoneText+' is required .' };
    }


    if(validTelinput == false && phone.length){
      return { valid: false, error: 'Enter the Exact phone number(Example:+XX XX XXXX XXXX).' };
    }

    return { valid: true, error: null };
  }
  const validateDob = (dob, membership_id, age, isNotAlliance, current_age_division, age_divisionArr, isPlayer) => {
    if (!dob.length) {
      return { valid: false, error: "This field is required" };
    }
    /*if (!dob.match(/^\w+([.-]?\w+)_@\w+(_[_.-]?\w+)_(.\w{2,3})+$/)) {
    return { valid: false, error: "Please, enter a valid data of birth." };
    }*/
    // validation for alliance staff dob
    if(!isNotAlliance && membership_id ==5 && age < 18){
      return { valid: false, error: "Coach user age should be age 18 or above." };
    }
    if (!isNotAlliance && isPlayer) {
      var valObj = age_divisionArr.filter(function (elem) {
        if (elem.value == current_age_division) return elem.value;
      });
      if (valObj.length > 0) {
        age = Math.floor(age);
        const division = valObj[0].validAge;
        if (age > division) {
          return {valid: false, error: "DOB must be under the age of " + division};
        }
      }
    }

    return { valid: true, error: null };
  };
  const validateGender = gender => {
    if (!gender) {
      return { valid: false, error: "This field is required" };
    }
    return { valid: true, error: null };
  };
  const validateSelect = (select, fieldState='',isNull=true) => {
    console.log('m select');
    console.log(select);
    let textState = (fieldState != '' ? fieldState : "This field");
    if (select == "" || (select == null && isNull)) {
      return {valid: false, error: textState + " is required"};
    }
    return {valid: true, error: null};
  };
  const validateSelectOption = (select, fieldState='') => {
    console.log('m select');
    console.log(select);
    let textState = (fieldState != '' ? fieldState : "This field");
    if(!Number.isInteger(select)){
      if(select == "" || select == null || !select.length){
        return {valid: false, error: textState + " is required"};
      }
    }
    if (select == "" || select == null) {
      return {valid: false, error: textState + " is required"};
    }
    return {valid: true, error: null};
  };
  const validateRadio = (radio, fieldName='') => {
    let fieldText= (fieldName != '' ? fieldName : "This field");
    if (!radio) {
      return {valid: false, error: fieldText+" is required"};
    }
    return {valid: true, error: null};
  };
  const validateStateName = name => {
    console.log('v state');
    console.log(name);
    if (name == null || name == "") {
      return { valid: false, error: "This field is required" };
    }
    return { valid: true, error: null };
  };
  const validateEmail = (email, fieldEmail='') => {
    let emailText= (fieldEmail != '' ? fieldEmail : "This field");
    if (email == null || !email.length) {
      return { valid: false, error: emailText+" is required" };
    }
    if (!email.match(/^[a-zA-Z0-9+_.-]+@[a-zA-Z0-9.-]+$/) || !email.match(/^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/)) {
        return {valid: false, error: "Please enter a valid email."};
    }
    return { valid: true, error: null };
  };
const validateZip = (zip, country)=> {
    if (zip == undefined) {
      return { valid: false, error: "This field is required" };
    }
    if(zip == null || !zip.length){
      return { valid: false, error: "Zip code is required" };
    }
  if(!zip.match(/^\d{5}(-\d{4})?$/) && country == 'USA'){
        return { valid: false, error: "Zip Code Should Be 5 Number Values for USA." };
  }
    else if(zip.length < 5 ){
      return { valid: false, error: "Zip code should be 5 character" };
    }
    return { valid: true, error: null };
  };
  const validateFedration = name => {
    if (!name || name.trim() == "") {
      return {valid: false, error: "This field is required"};
    } else if (isNaN(name.trim())) {
      return {valid: false, error: "This field must be a numeric value"};
    }
    // else if (name.length != 9 || name.trim() == "") {
    //   return {valid: false, error: "Foreign federation ID must be 9 characters"};
    // }
    return {valid: true, error: null};
  };
//volunteer validation
const validateRole = role => {
    if (role == null || role==0 || role == '') {
        return { valid: false, error: "This field is required" };
    }
    return { valid: true, error: null };
};

const validateOtherRole = (sub_roles,other_role) => {
    if ( (other_role=='' || other_role == undefined) && sub_roles.includes('-1') ) {
        return { valid: false, error: "This field is required" };
    }
    return { valid: true, error: null };
};

  const validateAddressName = (name, contactField='' ) => {
    let contactText = ( contactField != '' ? contactField : "This field");
    if (name == null || !name.length) {
      return { valid: false, error: contactText+ " is required " };
    }
    return { valid: true, error: null };
  };
  const validateOptionalTextFields = (name, fieldName = '') => {
    let names = '';
    if(name != null){
      names = name.trim();
    }

    let fieldText = ( fieldName != '' ? fieldName : "This field");

    if (names != null && names.length && !names.match(/^[a-zA-Z\s]*$/))
    {
      return { valid: false, error:  fieldText+ " must be letters only. No other characters" };
    }
    return { valid: true, error: null };
  };
  const validStartDisabledDate = (date, currentDate,fieldName='') => {
    let fieldText = ( fieldName != '' ? fieldName : "This field");
    if (date > currentDate)
    {
      return { valid: false, error:  fieldText+ " not longer than the expiration date" };
    }
    return { valid: true, error: null };
  };

  const validEndDisabledDate = (date, currentDate,fieldName='') => {
    let fieldText = ( fieldName != '' ? fieldName : "This field");
    if (date < currentDate)
    {
      return { valid: false, error:  fieldText+ " not lesser than the start date" };
    }
    return { valid: true, error: null };
  };
  const validateJerseyNumber = number => {
    if(!number){
      return {valid: false,error:"This field is required"};
    }
    if(!number.match(/^[0-9]+$/)){
      return { valid: false, error: "Please enter a valid Jersey Number." };
    }
    return {valid: true, error: null};
  };



  import * as $ from "jquery";
  import membercard from "../Member/Dashboard/profile/membercard.vue";
  import DatePick from 'vue-date-pick';
  import moment from "moment";
  import axios from 'axios';
  import Select2 from "v-select2-component";
  import CustomAlert from "../CustomAlert";
  import ChangeRegExpirationDate from "./changeRegExpirationDate";
  import familymemberprofileadd from "../Member/Dashboard/FamilyMemberProfileAdd";

  export default {
    name: 'userprofile',
    components: { ChangeRegExpirationDate, membercard, DatePick,/* Select2,*/ CustomAlert, familymemberprofileadd },
    data() {
      return {
        duplicateErrorForPersonal:'',
        families_count:0,
        selectedNote:'',
        age:0,
        notes:'',
        edit_index: '',
        member_id:'',
        edit_note_id:'',
        userProfileData: '',
        tempuserprofileData:'',
        userNote:'',
        isProfileloading: false,
        startDate: '',
        validThru: '',
        Notetype: {},
        notestatus:1,
        profileImg: '/dist/img/profile1.png',
        tblFields: [
          {key: 'membership.name', sortable: true, label: 'Registration Type', formatter: 'formatName'},
          {key: 'transaction_id', sortable: true, label: 'Transaction Id', formatter: 'formatName'},
          {key: 'transaction_date', sortable: true, label: 'Transaction Date', formatter: 'formatDate'},
          {key: 'status', sortable: true, label: 'Status', formatter: 'formatName'},
          {key: 'start_date', sortable: true, label: 'Effective From', formatter: 'formatDate'},
          {key: 'valid_thru', sortable: true, label: 'Effective To', formatter: 'formatDate'},
          {key: 'waiver', sortable: false, label: 'Waiver'},
          {key: 'receipt', sortable: false, label: 'Receipt'},
        ],
        donationTbl: [
          {key: 'sports_discipline', sortable: true, label: 'Donation Discipline', formatter: 'formatName'},
          {key: 'cost', sortable: true, label: 'Amount', formatter: 'formatName'},
          {key: 'transaction_code', sortable: true, label: 'Transaction Id', formatter: 'formatName'},
          {key: 'transaction_time', sortable: true, label: 'Transaction Date', formatter: 'formatDate'},
          {key: 'status', sortable: true, label: 'Status'},
          { key: 'receipt', sortable: false, label: 'Receipt'},
        ],
        merchandiseTbl:[
          {key: 'name', sortable: true, label: 'Product', formatter: 'formatName'},
          {key: 'color', sortable: true, label: 'Logo'},
          {key: 'size', sortable: true, label: 'Size'},
          {key: 'price', sortable: true, formatter: 'formatName'},
          {key: 'quantity', sortable: true, formatter: 'formatName'},
          {key: 'total', sortable: true, formatter: 'formatName'},
          {key: 'registration.transaction_id', sortable: true, label: 'Transaction Id', formatter: 'formatName'},
          {key: 'registration.transaction_date', sortable: true, label: 'Transaction Date', formatter: 'formatDatenew'},
          {key: 'registration.status', sortable: true, label: 'Status'},
                {key: 'registration.merchandise_order.fulfilled', sortable: true, label: 'Fulfilled'},
                {key: 'registration.merchandise_order.fulfilled_date', sortable: true, label: 'Fulfilled Date'},
                {key: 'registration.merchandise_order.tracking_id', sortable: true, label: 'Tracking ID'}
            ],
            merchandiseTbls:[
                {key: 'name', sortable: true, label: 'Product', formatter: 'formatName'},
                {key: 'color', sortable: true, label: 'Product Color'},
                {key: 'size', sortable: true, label: 'Product Size'},
                {key: 'price', sortable: true, formatter: 'formatName'},
                {key: 'quantity', sortable: true, formatter: 'formatName'},
                {key: 'total', sortable: true, formatter: 'formatName'},
                {key: 'registration.transaction_id', sortable: true, label: 'Transaction Id', formatter: 'formatName'},
                {key: 'registration.transaction_date', sortable: true, label: 'Transaction Date', formatter: 'formatDatenew'},
                {key: 'registration.status', sortable: true, label: 'Status'}
        ],
        committeeTbl:[
        {key: 'committee_name', sortable: true, label: 'Name', formatter: 'formatName'},
          {key: 'committee.group.name', sortable: true, label: 'Group', formatter: 'formatName'},
          {key: 'position.position', sortable: true, label: 'Position', formatter: 'formatName'},
          {key: 'region', sortable: true, label: 'Region', formatter: 'formatRegion'},
          {key: 'effective_date', sortable: true, label: 'Effective From', formatter: 'formatDatenew'},
          {key: 'expiration_date', sortable: true, label: 'Expiration Date', formatter: 'formatDatenew'},
          {key: 'committee.safe_sport', sortable: true, label: 'Safe Sport', formatter: 'formatName'},
          {key: 'discipline.code', sortable: true, label: 'Sport Discipline', formatter: 'formatName'},
          {key: "actions", sortable: false}
        ],
        volunteerTbl:[
          {key: 'volunteer', sortable: true, label: 'Volunteer'},
          {key: 'status', sortable: true, label: 'Status'},
          {key: 'role', sortable: true, label: 'Role'},
          {key: 'actions', sortable: false, label: 'Actions'}
        ],
        notesTbl:[
          {key: 'note', sortable: true, label: 'Notes', thStyle: { width: '400px'}},
          {key: 'note_type', sortable: true, label: 'Note Type', formatter: 'getNoteTypeName'},
          {key: 'status', sortable: true, label: 'Status', thClass: 'd-none', tdClass: 'd-none' },
          {key: 'name', sortable: true, label: 'Created By', formatter: 'formatName'},
          {key: 'created_at', sortable: true, label: 'Date', formatter: 'formatDatenew'},
          {key: "actions", sortable: false }
        ],
        travelInformationTbl:[
          {key: 'Travel Information' , formatter: () => {
              return 'USA Shooting Travel Information';
            },
          },
          {key: 'created_at', label: 'Created Date', sortable: true,
            formatter: (value) => {
              return this.formatDate(value) || "N/A";
            },
          },
          {key: 'updated_at', label: 'Updated Date', sortable: true,
            formatter: (value) => {
              return this.formatDate(value) || "N/A";
            },
          },
          {key: "actions", sortable: false }
        ],
        currentMemberId:0,
        UpdateProfile: {
          addressInfoUpdation:false,
          title: "",
          veteran_status: "",
          first_name: "",
          last_name: "",
          middle_name: "",
          suffix: "",
          address_type: "",
          address1: "",
          address2: "",
          zip: "",
          state: "",
          state_code: "",
          city: "",
          email: "",
          country: "",
          bad_address: "",
          phone_1: "",
          phone_2: "",
          company_name: "",
          company_phone: "",
          company_phone_extension: "",
          dob: "",
          gender: "",
          citizenship:"",
          team_id:[],
          first_choice:"",
          second_choice:"",
          home_federation:"",
          skier_license:"",
          membership_status:"",
          safesports_status:"",
          safesports_expiration:"",
            safesports_guid: "",
            safesports_sync: 0,
          membership_id:"",
          background_screen:"",
          background_screen_expiration:"",
          background_mvr_review:"",
          background_mvr_review_expiration:"",
          diversity_id: "",
          relation:"",
          ethnicity: "",
          disability: "",
          primary_area_interest: "",
          category: "",
          issf: "",
          cmp_no: "",
          nra_no: "",
          sasp_no: "",
          sctp_no: "",
          hear_about: "",
          printed_magazine:"",
          club: "",
          new_club_name: "",
          new_club_email: "",
          new_club_phone: "",
          note: "",
          start_date:'',
          valid_thru:'',
          membership:{},
          is_parent_citizenship: 0,
          is_athlete_citizenship: 0,
          is_eligible_citizenship: 0,
          parent_citizenship: '',
          athlete_citizenship: '',
          eligible_citizenship: '',
          parent_first_name: "",
          parent_last_name: "",
          parent_middle_name: ""
        },
        postData:
        {
          note:""
        },
        clubOptions:[],
        userDatadetails:{},
        errors: {},
        updateLoggedUserInfo:0,
        relationArr:["HOH", "Spouse", "Child", "Dependent"],
        isExpired:"",
        props_fields: null,
        validTelinput:false,
        validTelinput2:false,
        autoRenew: 0,
        autoRenewData: {
          regId: "",
          status: "",
        },
        homeProps: {
          mode: "international",
          defaultCountry: "US",
          inputOptions:{
            autocomplete:"on",
            autofocus:false,
            id:"",
            maxlength:20,
            name:"telephone",
            placeholder:"Home Number",
            readonly:false,
            required:false,
            showDialCode:false,
            styleClasses:"",
            tabindex:0,
            type:"tel"
          }

        },
        mobileProps: {
          mode: "international",
          defaultCountry: "US",
          inputOptions:{
            autocomplete:"on",
            autofocus:false,
            id:"",
            maxlength:20,
            name:"telephone",
            placeholder:"Mobile Number",
            readonly:false,
            required:false,
            showDialCode:false,
            styleClasses:"",
            tabindex:0,
            type:"tel"
          }
        },
        companyPhoneExtProps: {
          mode: "international",
          defaultCountry: "US",
          placeholder: "Company Phone Extension",
          // maxLen: 30,
        },
        diversities: [],
        categories: [],
        valid: false,
        props_membership: null,
        changeDisciplineData: {
          'sports_discipline':'',
          'id':'',
        },
        changeMemberType: {
          'member_type_id':''
        },
        select2MultipleOpt: {
          multiple: true,
          placeholder: 'Click to select one or multiple'
        },
        DonationSportDisciplineDpDwn:true,
        updateMemberTypeData:[],
        enableMemberTypeChange: false,
        safeSportRefreshButton: 'Refresh',
        editModal: {
          editing: true,
          id: "edit-modal",
          title: "",
          content: {},
        },
        positions: [],
        selectdisciplines: [],
        position_err:'',
        sport_discipline_err:'',
        editPositionModal: {
        id: "edit-position-modal",
        title: "",
        member: "",
        memberid: '',
        confirmationcode:"",
        groupname:"",
        content: {
          position_id: 0,
          sport_discipline: '',
          effective_date:'',
          expiration_date:''
        },
      },
        note:'',
        safeSportApiCall: false,
        refreshProgress: false,
        noteProgress: false,
        digitalView:true,
        travelInformation: '',
        addTravelForm : false,
        isTravelFormloading : true,
        memberGroup:[],
        memberGroupIds:[],
        groupList:[],
        groupList_id:[],
        membergroupform:{},
        GroupModel:'',
        purchaseHistoryTbl:[
          {key: 'transaction_date', sortable: true, label: 'Transaction Date',
              formatter: (value) => {
              return moment(String(value)).format("MM/DD/YYYY");
              }
          },
          {key: 'transaction_id', sortable: true, label: 'Transaction ID'},
          {key: 'product', sortable: true, label: 'Product',
          formatter: (value , key , item) => {
              let couponValue = value + ' ('+item.name+')';
              return item.name != null && item.name.includes('Discount Coupon') ? couponValue : value;
              }
          },
          {key: 'cost', sortable: false, label: 'Cost',
              formatter: (value) => {
              return '$'+parseFloat(value).toFixed(2);
              }
          },
          { key: 'receipt', sortable: false, label: 'Receipt'},
        ],
        volunteering:{},
          viewEntryOptions: [
            { value: 5, text: "5" },
            { value: 10, text: "10" },
            { value: 20, text: "20" },
          ],
          filter: null,
          filterOn: [],
          loaded: false,
          perPage: 10,
          currentPage: 1,
          form:{
            role:''
          },
          roles:'',
          subroles:'',
          editvolModal: {
            editing: true,
            id: "editvol-modal",
            title: "",
            content: "",
          },
        purchaseHistory : {
            data: [],
            totalRows: 1,
            loaded: false,
            perPage: 10,
            currentPage: 1,
            filter: {
                search: '',
                startDate: '',
                endDate: '',
                // year:moment().year()
            },
            filterOn: [],
            viewEntryOptions: [
                { value: 5, text: "5" },
                { value: 10, text: "10" },
                { value: 20, text: "20" },
                { value: 50, text: "50" }
            ]
        },
        isNotalliancefastpitch: true,
        isSpouseOrChild: false,
        GraduationyearArr: [],
        isChild:false,
        collegeCommitted : null,
        college_list : null,
        listOfCountriesCitizen:null,
        parentCitizenship:0,
        athleteCitizenship:0,
        eligibleCitizenship:0
      }
    },
    methods: {
      clearErrorMessage() {
        this.duplicateErrorForPersonal = '';
      },
      handlePositionOk(bvModalEvt){
      bvModalEvt.preventDefault();
      let url = process.env.VUE_APP_URL + "api/committee_member_position/update/"+ this.editPositionModal.memberid;
      let requestType = "post";
        let valid = true;
        this.position_err = "";
        this.sport_discipline_err = "";
        if(this.editPositionModal.content.position_id == 0)
        {
          this.position_err = "Position Field is Required";
          valid = false;
        }
        if(this.editPositionModal.content.sport_discipline == '')
        {
          this.sport_discipline_err = "Sport Discipline is Required";
          valid = false;
        }
     if(valid)
      {
        this.handlePositionSubmit(requestType, url)
        .then(() => {
          this.$nextTick(() => {
            this.alertMessage = "Committee Member has been Updated.";
            this.displayAlert = true;
            this.$bvModal.hide("edit-position-modal");
            this.userProfileApi();
          });
        })
        .catch(() => false);
      }

    },
    handlePositionSubmit(requestType, url) {
      return new Promise((resolve, reject) => {
        axios[requestType](url, this.editPositionModal.content, {
          headers: this.adminHeaders,
        })
          .then((response) => {
            resolve(response.data);
          })
          .catch((error) => {
            this.onFail(error.response.data.errors);
            reject(error.response.data);
          });
      });
    },
    loadPositions()
    {
      axios
            .get(process.env.VUE_APP_URL + "api/committee_positions")
            .then((response) => {
              let positions = Object.keys(response.data.committee_positions);
              if(positions.length && this.positions.length == 0){
                this.positions.push({value:0, text: 'Select Position'});
                positions.forEach((code,value) => {
                  this.positions.push({ value: response.data.committee_positions[code].id, text: response.data.committee_positions[code].position });
                });
              }
            })
            .catch((error) => (this.errors = error.response.data));
    },
      getSelectDiscipline() {
        axios.get(this.basePath+"api/getDisciplines/Committee")
            .then(response => {
              this.selectdisciplines = response.data.data;
            })
            .catch(err => {
              // Manage the state of the application if the request
              // has failed
            })
      },
      addFamilyMember() {
        axios.get(this.basePath+"api/admin/member-detail/"+this.userProfileData.confirmation_code+"/admin",{ headers:this.adminHeaders })
        .then((res) => {
            this.$refs.familyMemberProfileAddComponent.showModal(res.data.member);
          })
          .catch((error) => {
            // error.response.status Check status code
          }).finally(() => {
        });
		  },
      deleteEvent: function(event) {
        if(this.UpdateProfile.membership.period == 999){
          const index = this.membershipStatus.indexOf("Expired");
            if (index > -1) {
              this.membershipStatus.splice(index, 1); // 2nd parameter means remove one item only
            }
        }
      },
      getSafeSportUserApi(member_id = null, force = false) {
        this.safeSportRefreshButton = 'Processing...';
        if(member_id == null) {
          member_id = this.userProfileData.confirmation_code;
        }
        axios.get(this.basePath+"api/member/safeSport/"+member_id+"?force="+force,{ headers:this.adminHeaders })
        .then((res) => {
          console.log(res.data);
          this.userProfileData.safe_sport = res.data.data;
          //this.userProfileData.safe_sport.safe_sport_status_text = this.profileData.SafeSport.safe_sport_status_text;
          this.safeSportRefreshButton = 'Refresh';
          // this.userProfileApi(false);
          this.refreshCard();
          return res;
          })
          .catch((error) => {
            console.log('Error:'+ error);
            this.safeSportRefreshButton = 'Refresh';
            return false;
            // error.response.status Check status code
          }).finally(() => {
        });
      },
      getBackgroundScreeningApi() {
        axios.get(this.basePath+'api/member/background_screening')
        .then((res) => {
          this.userProfileData.background_screen = res.data.data;
          return res;
        })
        .catch((error) => {
          console.log('Error:'+ error);
          return false;
          // error.response.status Check status code
        }).finally(() => {
        });
      },
      formatDate(value) {
        var date = moment(value, "YYYY-MM-DD hh:mm:ss");
        if (date.isValid()) {
          return date.format('MM/DD/YYYY');
        } else {
          return 'N/A';
        }
      },
      formatDatenew(value) {
        var date = moment(value.trim());
        if (date.isValid()) {
          return date.format('MM/DD/YYYY');
        } else {
          return 'N/A';
        }
      },
      formatName(value) {
        return value || 'N/A'
      },
      formatRegion(value) {
        return this.userProfileData.country =="USA" ? this.userProfileData.region : "Foreign"
      },
      getNoteTypeName(value){
          var key = 'Notetype.disciplines.options.'+value;
          var typName = this.getSiteProps(key);
          return typName || "N/A";
      },
      userProfileApi(isProfileloading = true) {
        this.isProfileloading = isProfileloading;
        this.regId = this.$route.params.regId;
        this.loadPurchaseHistory();
        axios.get(this.basePath + 'api/admin/get_member/' + this.regId, {headers: this.adminHeaders})
            .then((res) => {
              this.isProfileloading = false;
              this.userProfileData = res.data.data;
              this.tempuserprofileData = res.data.data;
              if(this.userProfileData.personal_details.relation == 0)
              {
                this.isSpouseOrChild = true;
              }
              this.age = this.getAge(this.userProfileData.dob);
              if(res.data.data.families != null){
                let families_length = Object.keys(res.data.data.families).length;
                if((this.subdomain == 'waterski' && families_length <= 5) || (this.subdomain == 'shooting' && families_length <= 7)){
                  $('.add-family-btn').removeClass('d-none');
                }
                let inSpouse = '';
                Object.keys(res.data.data.families).forEach(function (key) {
                  if(res.data.data.families[key].personal_details.relation == 1){
                    EventBus.$emit('addSpouse', true);
                    inSpouse = true;
                    return;
                  }
                });
                if(inSpouse != true){
                  EventBus.$emit('addSpouse', false);
                }
              }
              this.currentMemberId = this.userProfileData.id;
              this.startDate = moment(this.userProfileData.membership_startdate).format('MM/DD/YYYY');
              this.validThru = moment(this.userProfileData.membership_endtate).format('MM/DD/YYYY');
              this.isExpired = moment(new Date()).isAfter(moment(new Date(this.validThru)));

              this.autoRenewData.regId = this.userProfileData.active_registration_id;
              this.autoRenew = this.userProfileData.auto_renew;
              console.log('test');
              this.errors.gender = '';
              console.log( this.autoRenew);
                this.autoRenewData.status= this.userProfileData.auto_renew;
              for (let prop in this.userProfileData.personal) {
                if ( Object.prototype.hasOwnProperty.call(this.UpdateProfile, prop) ) {
                  this.UpdateProfile[prop] = this.userProfileData.personal[prop]
                }
              }
              for (let prop in this.userProfileData) {
                if ( Object.prototype.hasOwnProperty.call(this.UpdateProfile, prop) ) {
                  this.UpdateProfile[prop] = this.userProfileData[prop]
                }
              }
              if(this.subdomain == 'waterski' && (this.userProfileData.membership.id == 13 || this.userProfileData.membership.member_type_id == 5)){
                this.digitalView = false;
              }
              this.UpdateProfile.team_id = Object.keys(this.userProfileData.affiliated_clubs_dropdown);
              this.UpdateProfile.membership_id = this.userProfileData.membership.id;
              this.UpdateProfile.relation = this.userProfileData.personal_details.relation;
              this.UpdateProfile.membership_status = this.userProfileData.membership_status_text;
              this.UpdateProfile.safesports_status = this.userProfileData.safe_sport.safe_sport_status;
              this.UpdateProfile.safesports_expiration = this.userProfileData.safe_sport.expiration;
              this.UpdateProfile.background_screen = this.userProfileData.background_screening != null ? this.userProfileData.background_screening.status : 0;
              this.UpdateProfile.background_screen_expiration = this.userProfileData.background_screening != null && this.userProfileData.background_screening.expiration != null ? moment(this.userProfileData.background_screening.expiration).format('MM/DD/YYYY') : null;
              this.UpdateProfile.background_mvr_review = this.userProfileData.background_mvr_review != null ? this.userProfileData.background_mvr_review.status : 0;
              this.UpdateProfile.background_mvr_review_expiration = this.userProfileData.background_mvr_review != null && this.userProfileData.background_mvr_review.expiration != null ?  moment(this.userProfileData.background_mvr_review.expiration).format('MM/DD/YYYY') : null;
              this.UpdateProfile.printed_magazine = this.userProfileData.printed_magazine != 1 ? 0 : 1;
              this.UpdateProfile.note = this.userProfileData.note;
              this.UpdateProfile.start_date = moment(this.userProfileData.membership_startdate).format('MM/DD/YYYY');
              this.UpdateProfile.valid_thru = moment(this.userProfileData.membership_endtate).format('MM/DD/YYYY');

              this.UpdateProfile.safesports_guid = this.userProfileData.safe_sport.user_id;
              this.UpdateProfile.safesports_sync = this.userProfileData.safe_sport.admin_flag;

              this.UpdateProfile.diversity_id = this.UpdateProfile.diversity_id == 99 ? '' : this.UpdateProfile.diversity_id;
              this.UpdateProfile.bad_address = this.userProfileData.contact_details.bad_address;
              this.UpdateProfile.parent_first_name = this.userProfileData.contact_details.first_name;
              this.UpdateProfile.parent_last_name = this.userProfileData.contact_details.last_name;
              this.UpdateProfile.parent_middle_name = this.userProfileData.contact_details.middle_name;

              //Load Membership List
              axios.get(this.basePath + "api/admin/members/"+this.currentMemberId+"/eligible-for-type-change", { headers: this.adminHeaders })
              .then(res => {
                this.updateMemberTypeData = res.data.data;
                this.enableMemberTypeChange = Object.prototype.hasOwnProperty.call(this.updateMemberTypeData, this.userProfileData?.membership?.id);
              })
              .catch(err => console.log(err));

              if(this.userProfileData.disciplines != undefined && Array.isArray(this.userProfileData.disciplines)){
                  this.UpdateProfile.first_choice = [];
                  this.userProfileData.disciplines.forEach(dis => {
                      this.UpdateProfile.first_choice.push(dis.id);
                  });
              }

              if(this.userProfileData.safe_sport != null && this.safeSportApiCall == false) {
                this.safeSportApiCall = true;
                this.getSafeSportUserApi();
              }

              if(this.userProfileData.membership != undefined){
                  const retry = setInterval(() => {
                      let memberTypes = this.getSiteProps('membershipTypes');
                      if(memberTypes) {
                          clearInterval(retry);
                          let currentMemberType = memberTypes.filter(item => item.id == this.userProfileData.membership.member_type_id);
                          if(currentMemberType.length){
                              let memberType = currentMemberType.shift();
                              if(memberType.memberships != undefined && memberType.memberships.length){
                                  this.props_membership = memberType.memberships.filter(item => item.id == this.userProfileData.membership.id);
                                  if(this.props_membership.length) this.props_membership = this.props_membership.shift();
                              }
                          }
                      }
                  }, 200);
              }

              if(this.subdomain == 'shooting' && this.UpdateProfile.club == null && this.userProfileData.affiliated_clubs_dropdown != null){
                  let affiliated_clubs_dropdown_value = this.userProfileData.affiliated_clubs_dropdown;
                  for (var prop_data in affiliated_clubs_dropdown_value){
                      this.UpdateProfile.club = prop_data;
                      this.userProfileData.club_name = this.userProfileData.affiliated_clubs_dropdown[prop_data];
                  }
              }
              if(!this.getSiteProps('general.member_profile_addition_info') && this.getSiteProps('general.member_profile_addition_info') != undefined){
                    this.isNotalliancefastpitch=false;
              }

              if(this.subdomain == 'alliancefastpitch') {
                if ([1, 2].indexOf(this.userProfileData.registration[0].membership_id) != -1) this.isChild = true;

                this.UpdateProfile.jersey_number = this.userProfileData.personal.jersey_number;
                this.UpdateProfile.graduation_year = this.userProfileData.personal.graduation_year;
                this.UpdateProfile.throws = this.userProfileData.personal.throws;
                this.UpdateProfile.bats = this.userProfileData.personal.bats;
                this.UpdateProfile.primary_position = this.userProfileData.personal.primary_position;
                this.UpdateProfile.secondary_position = this.userProfileData.personal.secondary_position;
                this.UpdateProfile.agl_player_id = this.userProfileData.personal.agl_player_id;
                this.UpdateProfile.college_committed = this.userProfileData.personal.college_committed;
                if(this.userProfileData.personal.college_committed != null || (this.userProfileData.personal.college_committed != undefined )){
                  this.collegeCommitted =1;
                  this.college_list =1;
                }else{
                  this.collegeCommitted =2;
                  this.college_list =2;
                }
                this.tempuserprofileData.college_list = this.college_list;
                this.tempuserprofileData.collegeCommitted = this.collegeCommitted;
                this.parentCitizenship =  this.userProfileData.parent_citizenship != null? 1:2;
                this.eligibleCitizenship = this.userProfileData.eligible_citizenship != null?1:2;
                this.athleteCitizenship = this.userProfileData.athlete_citizenship != null?1:2;
                this.UpdateProfile.is_parent_citizenship = this.userProfileData.parent_citizenship != null? 1:2;
                this.UpdateProfile.is_eligible_citizenship = this.userProfileData.eligible_citizenship != null?1:2;
                this.UpdateProfile.is_athlete_citizenship = this.userProfileData.athlete_citizenship != null?1:2;
                this.UpdateProfile.parent_citizenship = this.userProfileData.parent_citizenship;
                this.UpdateProfile.eligible_citizenship = this.userProfileData.eligible_citizenship;
                this.UpdateProfile.athlete_citizenship = this.userProfileData.athlete_citizenship;
              }
              this.isProfileloading = false;
              this.showMembership=(this.subdomain == 'alliancefastpitch' && this.userProfileData.membership.id == 5) ? false : true;
              if(this.getSiteProps('general.travelInformation') && this.userProfileData.travel_form ){
                this.loadTravelInformation();
              }
              this.checkcommittee();
            })
            .catch((error) => {
              this.isProfileloading = false;
              console.log('Error:' + error);
              return false;
            });
      },
      downloadDocs(item, type) {
        this.downloadPdf(this.basePath + 'api/registration/download/'+type+'/'+item, 'Reg'+item+"_"+type );
      },
      downloadOtherDocs(regid, memid, type, transaction_id){
        this.downloadPdf(this.basePath + 'api/registration/receiptDownload/'+type+'/'+regid+'/'+memid+'/'+transaction_id, 'Reg'+regid+"_"+type );
      },
      downloadDonationDocs(item) {
        this.downloadPdf(this.basePath + 'api/download/donation/'+item, 'Donation');
      },
      downloadBgScreeningDocs(paymentId , type){
            this.downloadPdf(this.basePath + 'api/member/bgScreeningInvoice/'+paymentId, type);
        },
      downloadPdf(url, name){
        axios.get(url, {headers:this.adminHeaders, responseType: 'arraybuffer'})
        .then(response => {
          let blob = new Blob([response.data], { type: 'application/pdf' });
          let link = document.createElement('a');
          link.href = window.URL.createObjectURL(blob);
          link.download = this.userProfileData.member_id+"_"+name+'.pdf';
          link.click();
          link.remove();
        }).catch((error)=>{
          console.log(error);
        });
      },
      resetForm() {
        this.errors = {};
        this.UpdateProfile.title = this.tempuserprofileData.title;
        this.UpdateProfile.first_name = this.tempuserprofileData.personal.first_name;
        this.UpdateProfile.middle_name = this.tempuserprofileData.personal.middle_name;
        this.UpdateProfile.last_name = this.tempuserprofileData.personal.last_name;
        this.UpdateProfile.dob = this.tempuserprofileData.personal.dob;
        this.UpdateProfile.gender = this.tempuserprofileData.personal.gender;
        this.UpdateProfile.relation = this.tempuserprofileData.personal.relation;
        this.UpdateProfile.team_id = Object.keys(this.tempuserprofileData.affiliated_clubs_dropdown);
        this.first_choice = this.tempuserprofileData.personal.first_choice;
        this.UpdateProfile.second_choice = this.tempuserprofileData.personal.second_choice;
        this.UpdateProfile.veteran_status = this.tempuserprofileData.veteran_status;
        this.UpdateProfile.home_federation = this.tempuserprofileData.personal.home_federation ;
        this.UpdateProfile.graduation_year = this.tempuserprofileData.personal.graduation_year ;
        this.UpdateProfile.jersey_number = this.tempuserprofileData.personal.jersey_number ;
        this.UpdateProfile.primary_position = this.tempuserprofileData.personal.primary_position;
        this.UpdateProfile.secondary_position = this.tempuserprofileData.personal.secondary_position;
        this.UpdateProfile.agl_player_id = this.tempuserprofileData.personal.agl_player_id;
        this.UpdateProfile.throws = this.tempuserprofileData.personal.throws;
        this.UpdateProfile.bats = this.tempuserprofileData.personal.bats;
        this.college_list = this.tempuserprofileData.college_list;
        this.collegeCommitted = this.tempuserprofileData.collegeCommitted;
        this.UpdateProfile.college_committed = this.tempuserprofileData.personal.college_committed;
        this.UpdateProfile.club= Object.keys(this.tempuserprofileData.affiliated_clubs_dropdown);
      },
      updatePersonalInfo(bvModalEvt){
        // Prevent modal from closing
        bvModalEvt.preventDefault()
        if(this.validatePersonalInfo()){
          this.updateMemberInfo();
        }
      },
        getAge(birth_date)
        {
            let age = moment().diff(birth_date, 'years', true);
            age = parseInt(age);
        return age;
      },
      validatePersonalInfo(){
        this.errors = {}
        this.valid = false;
        var validTitle = {valid:true}, validGender ='', validMiddleName ={valid:true},validPrimaryPosition = {valid:true}, validThrow = {valid:true}, validBats = {valid:true},  validJerseyNumber = {valid:true}, validGraduationYear = {valid:true}, validTeam = {valid:true};
        // const validTitle = validateTitle(this.UpdateProfile.title);
        // this.errors.title = validTitle.error;
        const validFirstName = validateName(this.UpdateProfile.first_name);
        this.errors.first_name = validFirstName.error;
        validMiddleName = validateOptionalTextFields(this.UpdateProfile.middle_name, "Middle name");
        this.errors.middle_name = validMiddleName.error;
        const validLastName = validateLastName(this.UpdateProfile.last_name);
        this.errors.last_name = validLastName.error;

        let age = this.isUnderAgeDivision(this.UpdateProfile.dob);
        var age_divisionArr = this.getSiteProps('memberRegistration.team.fields.club_info.age_division.options');
        const validDob = validateDob(this.UpdateProfile.dob, this.UpdateProfile.membership.id, age, this.isNotalliancefastpitch, this.userProfileData.age_division, age_divisionArr, this.isChild);
        this.errors.dob = validDob.error;
        if(this.props_fields.personal.gender) {
          validGender = validateGender(this.UpdateProfile.gender);
          this.errors.gender = validGender.error;
        }else{
          validGender = {valid:true};
        }
        if(this.isChild) {
          validPrimaryPosition = validateSelect(this.UpdateProfile.primary_position, "Primary");
          this.errors.primary_position = validPrimaryPosition.error;

          validThrow = validateRadio(this.UpdateProfile.throws);
          this.errors.throws = validThrow.error;

          validBats = validateRadio(this.UpdateProfile.bats);
          this.errors.bats = validBats.error;

          validJerseyNumber = validateJerseyNumber(this.UpdateProfile.jersey_number);
          this.errors.jersey_number = validJerseyNumber.error;

          validGraduationYear = validateSelectOption(this.UpdateProfile.graduation_year);
          this.errors.graduation_year = validGraduationYear.error;
        }
        var isValid = true;
        if(!this.isNotalliancefastpitch && this.isChild && (!validFirstName.valid || !validLastName.valid || !validDob.valid || !validTitle.valid || !validMiddleName.valid || !validPrimaryPosition.valid || !validThrow.valid || !validBats.valid || !validJerseyNumber.valid || !validGraduationYear.valid)){
          isValid = false;
        }
            if (validFirstName.valid && validLastName.valid && validGender.valid && validDob.valid && validTitle.valid && validMiddleName.valid && isValid) {
                let dob = this.getAge(this.UpdateProfile.dob);
                if (!this.isNotalliancefastpitch && this.isChild) {
                  dob = age;
                }
                if(!this.validateDate(this.UpdateProfile.dob)){
                  this.valid = false;
                    this.errors.dob = 'Invalid Date';
                } else {
                    this.errors.dob = '';
                    if(this.props_membership != null && this.props_membership.age){
                      if(this.userProfileData.membership.member_type_id != 3){
                        if(this.props_membership.age.compare=="above" && dob < this.props_membership.age.value) {
                            this.valid = false;
                            this.errors.dob = this.props_membership.age.error_msg;
                        } else if(this.props_membership.age.compare=="below" && dob > this.props_membership.age.value) {
                            this.valid = false;
                            this.errors.dob = this.props_membership.age.error_msg;
                        } else if(!Object.is(Math.abs(dob), +dob)) {
                          this.valid = false;
                          this.errors.dob = "Invalid Date";
                        } else {
                            this.errors.dob = '';
                            this.valid = true;
                        }
                      }else{
                        if(
                            this.userProfileData.personal != undefined &&
                            this.userProfileData.personal.relation != undefined &&
                            this.UpdateProfile.relation == 0 && dob < this.props_membership.age.value
                        ){
                            this.valid = false;
                            this.errors.dob = this.props_membership.age.error_msg;
                        } else if(
                            this.userProfileData.personal != undefined &&
                            this.userProfileData.personal.relation != undefined &&
                            this.UpdateProfile.relation == 1 && dob < this.props_membership.age.value
                        ){
                            this.valid = false;
                            this.errors.dob = this.props_membership.age.spouse_msg;
                        } else if (
                            this.userProfileData.personal != undefined &&
                            this.userProfileData.personal.relation != undefined &&
                            this.UpdateProfile.relation == 2 && dob >= this.props_membership.age.child_age
                        ){
                            this.valid = false;
                            this.errors.dob = this.props_membership.age.child_msg;
                        } else {
                            this.valid = true;
                        }
                      }
                    } else {
                        this.valid = true;
                    }
                }
            }
            if(this.isNotalliancefastpitch) {
              if (this.props_fields.personal != undefined && this.props_fields.personal.veteran_status != undefined) {
                const validMilitary = validateRadio(this.UpdateProfile.veteran_status, "Military Status");
                this.errors.veteran_status = validMilitary.error;

                if (!validMilitary.valid) this.valid = false;
              }
              if (!this.validateDate(this.userProfileData.dob)) {
                this.userProfileData.dob = 'Invalid Date';
                validDob.valid = false;
              }
              if (this.props_fields.general != undefined && this.props_fields.general.sport_discipline != undefined && this.props_fields.general.sport_discipline.first_choice != undefined) {
                const validFirstChoice = validateSelect(this.UpdateProfile.first_choice,'',false);
                if (Array.isArray(this.UpdateProfile.first_choice)) {
                  if (this.UpdateProfile.first_choice.length == 0) {
                    validFirstChoice.valid = false;
                    validFirstChoice.error = "This field is required";
                  } else {
                    validFirstChoice.valid = true;
                    validFirstChoice.error = "";
                  }
                }

                this.errors.first_choice = validFirstChoice.error;

                if (!validFirstChoice.valid&&!(this.subdomain=='shooting'&&this.userProfileData.membership.member_type_id==7)) this.valid = false;
              }

              // Spouse validation
              if (this.UpdateProfile.relation == 1) {
                var relationsArray = [];
                for (var i = 0; i < Object.keys(this.userProfileData.families).length; i++) {
                  if (this.userProfileData.id != this.userProfileData.families[i].id) {
                    relationsArray.push(this.userProfileData.families[i].personal_details.relation);
                  }
                }
                if (relationsArray.includes(1)) {
                  this.valid = false;
                  this.errors.relation = "Spouse is already a member in this family registration";
                }
              }
            }

        if(this.subdomain == 'waterski'){
            const validFederation = validateSelect(this.UpdateProfile.home_federation);
        if(Array.isArray(this.UpdateProfile.home_federation)) {
          if (this.UpdateProfile.home_federation.length == 0) {
            validFederation.valid = false;
            validFederation.error = "This field is required";
          } else {
            validFederation.valid = true;
            validFederation.error = "";
          }
        }
           this.errors.home_federation = validFederation.error;
           if(!validFederation.valid) this.valid = false;
        const validSafeSportForeignFederationId = validateFedration(this.UpdateProfile.skier_license);
          if (!validSafeSportForeignFederationId.valid && this.UpdateProfile.home_federation != 'USA' ) {
            validSafeSportForeignFederationId.valid = false;
            this.errors.skier_license = validSafeSportForeignFederationId.error;
          }
          else if(this.UpdateProfile.skier_license != null && this.UpdateProfile.home_federation == 'USA') {
            validSafeSportForeignFederationId.valid = true;
            validSafeSportForeignFederationId.error = "";
            this.UpdateProfile.skier_license = "";
          }
          else{
          validSafeSportForeignFederationId.valid = true;
          validSafeSportForeignFederationId.error = "";
         }
        this.errors.skier_license = validSafeSportForeignFederationId.error;
        if(!validSafeSportForeignFederationId.valid) this.valid = false;
        }
            return this.valid;
      },
      updateAddressInfo(bvModalEvt){
        // Prevent modal from closing
        bvModalEvt.preventDefault()
        if(this.validateAddressInfo()){
          this.UpdateProfile.addressInfoUpdation = true;
            this.updateMemberInfo();
             // Hide the modal manually
             this.$nextTick(() => {
            this.$bvModal.hide('modal-center')
         });
        }
      },
      validateAddressInfo(){
       // debugger
            this.errors = {};
            this.valid = false;
            var validState ='', validPhone1={valid:true};
            var validCheckParentCitizenship = {valid:true}, validCheckAthleteCitizenship = {valid:true}, validCheckEligibleCitizenship = {valid:true};
            var validParentCitizenship = {valid:true}, validAthleteCitizenship = {valid:true}, validEligibleCitizenship = {valid:true};
            const validAddress1 = validateAddressName(this.UpdateProfile.address1);
            this.errors.address1 = validAddress1.error;

            const validCountry = validateSelect(this.UpdateProfile.country);
            this.errors.country = validCountry.error;

            const validStateCode = validateSelect(this.UpdateProfile.state_code);

            if(this.isNotalliancefastpitch){
              validState = validateStateName(this.UpdateProfile.state);
              if(validCountry.valid && this.UpdateProfile.country != 'USA') {
                this.errors.state = validState.error;
              } else {
                  this.errors.state_code = validStateCode.error;
              }
            }else {
              validPhone1 = {valid:true};
              if (validCountry.valid && this.UpdateProfile.country != 'USA' || this.UpdateProfile.country != 'Canada') {
                this.errors.state = validState.error;
              } else {
                this.errors.state_code = validStateCode.error;
              }

              validCheckParentCitizenship = validateRadio(this.UpdateProfile.is_parent_citizenship.toString(),"Parent/Guardian");
              this.errors.is_parent_citizenship = validCheckParentCitizenship.error;

              validCheckAthleteCitizenship = validateRadio(this.UpdateProfile.is_athlete_citizenship.toString(),"Child/Athlete");
              this.errors.is_athlete_citizenship = validCheckAthleteCitizenship.error;
              if (this.parentCitizenship==1) {
                validParentCitizenship = validateSelectOption(this.UpdateProfile.parent_citizenship, "Parent/Guardian");
                this.errors.parent_citizenship = validParentCitizenship.error;
              }
              if (this.athleteCitizenship==1) {
                validAthleteCitizenship = validateSelectOption(this.UpdateProfile.athlete_citizenship, "Child/Athlete");
                this.errors.athlete_citizenship = validAthleteCitizenship.error;
              }

              if(this.athleteCitizenship == 2 || this.parentCitizenship == 2) {
                validCheckEligibleCitizenship = validateRadio(this.UpdateProfile.is_eligible_citizenship.toString(),"Child/Athlete Eligible");
                this.errors.is_eligible_citizenship = validCheckEligibleCitizenship.error;

                if (this.eligibleCitizenship==1) {
                  validEligibleCitizenship = validateSelectOption(this.UpdateProfile.eligible_citizenship, "Child/Athlete Eligible");
                  this.errors.eligible_citizenship = validEligibleCitizenship.error;
                }
              }
            }

            const validCity = validateName(this.UpdateProfile.city);
            this.errors.city = validCity.error;

            const validZip = validateZip(this.UpdateProfile.zip, this.UpdateProfile.country);
            this.errors.zip = validZip.error;

            if (validAddress1.valid && validCountry.valid
                && validCity.valid && validZip.valid && validPhone1.valid && validCheckParentCitizenship.valid &&
                validCheckAthleteCitizenship.valid && validCheckEligibleCitizenship.valid && validParentCitizenship.valid && validAthleteCitizenship.valid && validEligibleCitizenship.valid) {
                this.valid = true;
                if(this.isNotalliancefastpitch) {
                  if (this.UpdateProfile.country == 'USA') {
                    this.UpdateProfile.state = '';
                    if (!validStateCode.valid) {
                      this.valid = false;
                    }
                  } else if (!validState.valid) {
                    this.UpdateProfile.state_code = '';
                    this.valid = false;
                  }
                }else{
                  if(this.UpdateProfile.country == 'USA' || this.UpdateProfile.country == 'Canada') {
                    this.UpdateProfile.state = '';
                    console.log("validate",!validStateCode.valid);
                    if(!validStateCode.valid&&this.UpdateProfile.country == 'USA') {
                      this.valid = false;
                      this.errors.state_code = validStateCode.error;
                    }
                    else if(!validStateCode.valid&&this.UpdateProfile.country == 'Canada'){
                      this.valid = false;
                      this.errors.state = validStateCode.error;
                    }
                  } else if(!validState.valid) {
                    this.UpdateProfile.state_code = '';
                    this.valid = false;
                  }
                }
            }
        if(this.UpdateProfile.address_type=='Work')
        {
          const validCompany = validateName(this.UpdateProfile.company_name);
          this.errors.company_name = validCompany.error;
          if(!validCompany.valid) this.valid = false;
        }
            return this.valid;
      },
      updateContactInfo(bvModalEvt){
            // Prevent modal from closing
            bvModalEvt.preventDefault()
            if(this.validateContactInfo()){
                this.updateMemberInfo();
                // Hide the modal manually
                this.$nextTick(() => {
                    this.$bvModal.hide('modal-center3')
                });
            }
      },
      validateContactInfo(){
            this.errors = {};
            this.valid = false;
            var validParentFirstName = {valid:true}, validParentLastName = {valid:true};

            const validEmail = validateEmail(this.UpdateProfile.email);
            this.errors.email = validEmail.error;

            const validPhone1 = validatePhone(this.UpdateProfile.phone_1,this.validTelinput);
            this.errors.phone_1 = validPhone1.error;

            const validPhone2 = validatePhone2(this.UpdateProfile.phone_2,this.validTelinput2);
            this.errors.phone_2 = validPhone2.error;

            if(this.isChild) {
              validParentFirstName = validateName(this.UpdateProfile.parent_first_name);
              this.errors.parent_first_name = validParentFirstName.error;

              validParentLastName = validateLastName(this.UpdateProfile.parent_last_name);
              this.errors.parent_last_name = validParentLastName.error;
            }

            if (validEmail.valid && validPhone1.valid && validParentFirstName.valid && validParentLastName.valid && validPhone2.valid){
                this.valid = true;
            }

            return this.valid;

      },
      updateDemographicInfo(bvModalEvt){
            // Prevent modal from closing
            bvModalEvt.preventDefault()
            if(this.validateDemographicInfo()){
                this.updateMemberInfo();
                // Hide the modal manually
                this.$nextTick(() => {
                    this.$bvModal.hide('edit-demographic')
                });
            }
      },
      validateDemographicInfo(){
            this.errors = {};
            this.valid = false;

            const validDiversity = validateRadio(this.UpdateProfile.diversity_id);
            this.errors.diversity_id = validDiversity.error;

            const validEthnicity = validateRadio(this.UpdateProfile.ethnicity);
            if(this.props_fields.demographic != undefined && this.props_fields.demographic.ethnicity != undefined){
                this.errors.ethnicity = validEthnicity.error;
            } else {
                validEthnicity.valid = true;
            }

            const validDisability = validateRadio(this.UpdateProfile.disability);
            if(this.props_fields.demographic != undefined && this.props_fields.demographic.disability_status != undefined){
                this.errors.disability = validDisability.error;
            } else {
                validDisability.valid = true;
            }

            const validVeteranStatus = validateRadio(this.UpdateProfile.veteran_status);
            if(this.props_fields.demographic != undefined && this.props_fields.demographic.veteran_status != undefined){
                this.errors.veteran_status = validVeteranStatus.error;
            } else {
                validVeteranStatus.valid = true;
            }

            if (validDiversity.valid && validEthnicity.valid && validDisability.valid && validVeteranStatus.valid) {
                this.valid = true;
            }

            if(this.props_fields.demographic != undefined && this.props_fields.demographic.primary_area_interest != undefined){
                const validPrimaryAreaInterest = validateSelect(this.UpdateProfile.primary_area_interest);
                this.errors.primary_area_interest = validPrimaryAreaInterest.error;
                if(!validPrimaryAreaInterest.valid) this.valid = false;
            }

            if(this.props_fields.demographic != undefined && this.props_fields.demographic.category != undefined){
                const validCategory = validateSelect(this.UpdateProfile.category.toString());
                this.errors.category = validCategory.error;
                if(!validCategory.valid) this.valid = false;
            }

            if(this.props_fields.demographic != undefined && this.props_fields.demographic.hear_about != undefined){
                const validHearAbout = validateSelect(this.UpdateProfile.hear_about.toString());
                this.errors.hear_about = validHearAbout.error;
                if(!validHearAbout.valid) this.valid = false;
            }

            return this.valid;

    },
    validateUpdateMemberInfo(){
      this.errors = {};
      this.valid = false;

      const validStartDate = validStartDisabledDate(new Date(this.UpdateProfile.start_date),new Date(this.UpdateProfile.valid_thru),'Start Date');
      this.errors.start_date = validStartDate.error;

      const validEndDate = validEndDisabledDate(new Date(this.UpdateProfile.valid_thru),new Date(this.UpdateProfile.start_date),'Expiration Date');
      this.errors.end_date = validEndDate.error;

      if(validStartDate.valid && validEndDate.valid){
        this.valid = true;
      }

      return this.valid;
    },
    updateMemberDetails(bvModalEvt){
      bvModalEvt.preventDefault()
      if(this.validateUpdateMemberInfo()){
        this.updateMemberInfo();
        // Hide the modal manually
        this.$nextTick(() => {
          this.$bvModal.hide('modalMembership')
        });
      }
    },
    updateMemberInfo(){
        if(!this.isNotalliancefastpitch){
          if(this.parentCitizenship==2)
            this.UpdateProfile.parent_citizenship ='';

          if(this.athleteCitizenship==2)
            this.UpdateProfile.athlete_citizenship ='';

          if(this.eligibleCitizenship==2)
            this.UpdateProfile.eligible_citizenship ='';

          if(this.collegeCommitted == 2 || this.college_list == 2){
            this.UpdateProfile.college_committed = '';
          }
        }
        this.displayAlert = false;
         if(this.userProfileData.contact_details.bad_address == 0 && this.UpdateProfile.bad_address == 1){
            this.UpdateProfile.addressInfoUpdation = false;
          }
        axios.post(this.basePath + 'api/update/member/'+this.currentMemberId, this.UpdateProfile,{ headers:this.adminHeaders })
            .then(function (res) {
               this.userDatadetails = res.data.data;
               this.userDatadetails = res.data.message;
              if(res.data.message == "Duplicate member found"){
                this.duplicateErrorForPersonal = 'A user with the same details already exists.';
                this.$bvModal.show('modal-center1');
                return "Duplicate";
              }
              this.alertMessage = "Member Information updated successfully";
              this.displayAlert = true;
              this.userProfileApi();
              this.updateLoggedUserInfo += 1;
              this.$bvModal.hide('modal-center1');
            }.bind(this))
            .catch(function (error) {
              console.log(error);
            });
      return "Success";
    },
    getClubs(){
        axios.get(this.basePath + "api/get_teams")
            .then((response) => {
              var clubs = response.data.data;
              this.clubOptions = clubs;
            })
            .catch(error=>{
              console.log("error geting clubs list")
            })
    },
    isFutureDate(date) {
        const currentDate = new Date();
        return date > currentDate;
    },
      changeAutoRenewal(val,result) {
          console.log( val);
        if(![7,9,10].includes(this.userProfileData.membership_status)){
          if(val == 1){
              result = "Are you sure want to enable the Auto-Renewal?"
          }else{
             result = "Are you sure want to disable the Auto-Renewal?"
          }
          this.$bvModal
              .msgBoxConfirm(result, {
                title: "Please Confirm",
                size: "md",
                buttonSize: "sm",
                okVariant: "danger",
                okTitle: "YES",
                cancelTitle: "NO",
                footerClass: "p-2",
                hideHeaderClose: false,
                centered: true,
              })
              .then((value) => {

                if(value){
                  this.autoRenewData.status = val;

                  axios
                      .post(
                          this.basePath + "api/club/auto-renew-status",
                          this.autoRenewData,
                          { headers: this.adminHeaders }
                      )
                      .then(
                          function(response) {
                            this.userProfileApi();
                            this.alertMessage = "Auto renewal changed successfully";
                            this.displayAlert = true;
                            this.autoRenew = true;
                          }.bind(this)
                      )
                      .catch(function(error) {
                        console.log(error);
                      });
                } else {
                  this.autoRenew = false;
                }
              })
              .catch((err) => {
                console.log(err);
              });
        }
        else{
          this.autoRenew=false;
          this.alertMessage = 'Member is '+this.userProfileData.membership_status_text;
          this.displayAlert = true;
          this.setDangerBg();
        }
    },
    changeBadAddress(val) {
          console.log( val);
          let result='';
          if(val != true){
              result = "Are you sure want to set this address as UnDeliverable?"
          }else{
             result = "Are you sure want to set this address as Deliverable?"
          }
        this.$bvModal
            .msgBoxConfirm(result, {
              title: "Please Confirm",
              size: "md",
              buttonSize: "sm",
              okVariant: "danger",
              okTitle: "YES",
              cancelTitle: "NO",
              footerClass: "p-2",
              hideHeaderClose: false,
              centered: true,
            })
            .then((value) => {
              if(value){
                this.updateMemberInfo();
              }else{
                if(val == 1){
                  this.UpdateProfile.bad_address = 1;
                }else{
                  this.UpdateProfile.bad_address =0;
                }
                console.log('valuevalue', value);
              }
            })
            .catch((err) => {
              console.log(err);
            });
    },
    validateTelinput(obj){
          this.validTelinput = obj.valid;
          if(obj.valid){
              this.errors.company_phone = '';
          }
    },
    getDiversity(){
          axios.get(this.basePath+"api/getDiversity")
              .then(response => {
                  this.diversities = response.data.data;
              })
              .catch(err => {
                  // Manage the state of the application if the request
                  // has failed
                  console.log(err);
              })
    },
    getCategories(){
          axios.get(this.basePath + "api/categories")
          .then(response => {
              this.categories = response.data.data;
          })
          .catch(error => {
              console.log("Error in getting categories: ", JSON.stringify(error));
          });
    },
    displayAlertAndReloadData(response){
        this.alertMessage = response.message;
        this.displayAlert = true;
        this.userProfileApi();
        this.updateLoggedUserInfo += 1;
    },
    resetDisciplineData(){
        this.changeDisciplineData = {
          'sports_discipline':'',
          'id':'',
        };
    },
    dispDisciplineModal(item, index, target){
        this.changeDisciplineData.sports_discipline = item.sports_discipline;
        this.changeDisciplineData.id = item.id;
        this.DonationSportDisciplineDpDwn = (this.changeDisciplineData.sports_discipline!=0);
        this.$bvModal.show('changeDiscipline');
    },
    changeDiscipline(){
        if(this.changeDisciplineData.sports_discipline==0){
          return;
        }
        axios.post(this.basePath + "api/change_donation_discipline", this.changeDisciplineData, {headers: this.adminHeaders})
            .then(
                function(response) {
                  this.alertMessage = response.data.message;
                  this.displayAlert = true;
                  this.userProfileApi();
                }.bind(this)
            ).catch(function(error) {
          console.log(error);
        });
    },
    checkDiscipline(){
        this.DonationSportDisciplineDpDwn = (this.changeDisciplineData.sports_discipline!=0);
    },
    resetMemberType(){
        this.changeMemberType = {
          'member_type_id':''
        };
    },
    updateMemberType(){
        if(this.changeMemberType.member_type_id == 0){
          return;
        }
        axios.post(this.basePath + "api/change_member_type/"+this.regId, this.changeMemberType, {headers: this.adminHeaders})
            .then(
                function(response) {
                  this.alertMessage = response.data.message;
                  this.displayAlert = true;
                  this.userProfileApi();
                }.bind(this)
            ).catch(function(error) {
          console.log(error);
        });
    },
    addRow(button) {
        (this.editModal.editing = false),
            (this.editModal.title = "Add New Committee");
        let id = localStorage.getItem("id");
        this.editModal.content = {
          name: "",
          code: "",
          organization_id: 1,
          updated_by: id,
          discount_type: 1,
          status: 1,
        };
        this.$root.$emit("bv::show::modal", "edit-modal", button);
    },
    viewRow(item, index, button) {
        this.infoModal.title = item.name;
        this.infoModal.content = item;
        this.$root.$emit("bv::show::modal", this.infoModal.id, button);
    },
    editRow(item, button) {
        (this.editModal.editing = true),
            (this.editModal.title = "Update " + item.name);
        this.editModal.content = item;
        console.log(item);
        this.$root.$emit("bv::show::modal", "edit-modal", button);
    },
    editPosition(id,position_id,effective_date,expiration_date,sport_discipline,confirmation_code,full_name,group_name){
      (this.editPositionModal.title = "Update Committee Member");
      this.editPositionModal.memberid = id;
      this.editPositionModal.confirmationcode = confirmation_code;
      this.editPositionModal.member = full_name;
      this.editPositionModal.groupname = group_name;
      this.editPositionModal.content.position_id = position_id;
      this.editPositionModal.content.sport_discipline = (!isNaN(parseFloat(sport_discipline)) && isFinite(sport_discipline))?sport_discipline:'';
      this.editPositionModal.content.effective_date = effective_date;
      this.editPositionModal.content.expiration_date = expiration_date;
      this.position_err = '';
      this.$root.$emit("bv::show::modal", "edit-position-modal");
    },
    deleteRow(id) {
      this.displayAlert = false;
        this.$bvModal.msgBoxConfirm("Are you sure you are removing this member from this committee?" ,{
          title: 'Please Confirm',
          size: 'md',
          buttonSize: 'sm',
          okVariant: 'danger',
          okTitle: 'YES',
          cancelTitle: 'NO',
          footerClass: 'p-2',
          hideHeaderClose: false,
          noCloseOnBackdrop: true,
           noCloseOnEsc: true,
          centered: true
        }).then(value => {
          if (String(value) == "true") {
             axios.delete(process.env.VUE_APP_URL + "api/committee_members/delete/" + id)
              .then(function (response) {
              this.alertMessage = "The member has been removed from this committee!";
              this.displayAlert = true;
              this.userProfileApi();
              //this.resetEditModal();
            }.bind(this))
            .catch(function (error) {
              console.log(error);
            });
          }
          })
           .catch(err => {
          console.log(err);
        });
         return false;
    },

      //Modals
    resetInfoModal() {
        this.infoModal.title = "";
        this.infoModal.content = "";
    },
    resetEditModal() {
        this.errors.clear();
        this.loaded = false;
        axios
            .get(process.env.VUE_APP_URL + "api/member_committee/index")
            .then((response) => {
              let data = response.data.member_committee;
              this.items = data;
              this.loaded = true;
            })
            .catch((error) => (this.errors = error.response.data));
        this.loaded = true;
    },
    handleOk(bvModalEvt) {
        bvModalEvt.preventDefault();
        let url = process.env.VUE_APP_URL + "api/member_committee";
        let requestType = "post";

        if (this.editModal.editing) {
          url = process.env.VUE_APP_URL + "api/member_committee/update/" + this.editModal.content.id;
          requestType = "patch";
        }
        this.handleSubmit(requestType, url)
            .then(() => {
              this.$nextTick(() => {
                var message = this.editModal.editing ? "saved" : "created";
                this.alertMessage = "Committee Member has been " + message + ".";
                this.displayAlert = true;
                this.$bvModal.hide("edit-modal");
              });
            })
            .catch(() => false);
    },

    handleSubmit(requestType, url) {
        return new Promise((resolve, reject) => {
          axios[requestType](url, this.editModal.content)
              .then((response) => {
                resolve(response.data);
              })
              .catch((error) => {
                this.onFail(error.response.data.errors);
                reject(error.response.data);
              });
        });
    },
    formatDob(value) {
        if (value) {
          return moment(String(value)).format('MM/DD/YYYY')
        }
    },
    refreshCard(event){
        this.refreshProgress = true;
          let member_id = this.userProfileData.id;
          this.displayAlert = false;
          axios.post(this.basePath+"api/member/refresh-card", { member: member_id })
          .then(res => {
              this.alertMessage = "Member Card updated successfully";
              this.displayAlert = true;
              this.refreshProgress = false;
              this.updateLoggedUserInfo += 1;
              this.userProfileApi();
          })
          .catch(err => {
              console.log(err);
              this.refreshProgress = false;
          })
    },
    checkcommittee(){
      const el = this.$refs.committee;
          if (el && this.$route.query.isCommittee) {
            el.scrollIntoView();
            //el.scrollIntoView({behavior: 'smooth'});
          }
    },
    impersonate(code = 0)
      {
        axios
            .post(process.env.VUE_APP_URL + "api/impersonate/member", {
              code: code
            })
            .then((response) => {
              if (response.data.status == "success") {
                localStorage.removeItem('admintoken');
                localStorage.removeItem('admin');
                localStorage.setItem("membertoken", response.data.data.token);
                localStorage.setItem(
                    "member",
                    JSON.stringify(response.data.data.member)
                );
                localStorage.setItem("time", moment().unix());

                let d = new Date();
                d.setTime(d.getTime() + 60 * 60 * 1000);
                let expires = "expires=" + d.toUTCString();
                let hostName=window.location.hostname;
                let mainDomain=hostName.substring(hostName.lastIndexOf(".", hostName.lastIndexOf(".") - 1) + 1);
                document.cookie = "LMSACCESSTOKEN=" + response.data.data.member.lmsAccessToken + ";domain=."+mainDomain+";" + expires + ";path=/";

                this.$router.push({ path: "/member/dashboard" });

              }
            })
            .catch((error) => {

            });
    },
    familyprofileview(id){          
          this.isTravelFormloading = true;
          var vueRouter = this.$router;
          this.isProfileloading = true;
          //  this.displayAlert = false;                   
            if(id){   
              vueRouter.push('/admin/family-registrations/'+id); 
              setTimeout(() => {
                this.userProfileApi();
                if(this.GroupModel)
                {
                  this.getMemberGroup();
                }                
              },2000);                
            } 
    },
    
    addnote(){
        this.note = '';
        this.edit_note_id='';
        this.selectedNote='';
        this.notestatus=1;
    },
    
    storenote(){

        $('.note-error, .note-type-error').html("");
        var error_flag = 0;
        if(this.note.trim() == ""){
          $('.note-error').html("Please enter note");
          error_flag++;
        }

        if(this.selectedNote == ""){
          $('.note-type-error').html("Please enter note type");
          error_flag++;
        }

        if(error_flag) return;

        let form = {'note': this.note,'member_id':this.userProfileData.id,'selectedNote':this.selectedNote,'notestatus':this.notestatus};

        this.noteProgress = true;
        axios.post(this.basePath+"api/Addnotes",form,{ headers:this.adminHeaders }).then(function (response){
          console.log(response)
                  this.alertMessage = "Note added successfully";
                  this.displayAlert = true;
                  this.$bvModal.hide("note-modal");
                  this.fetchnote();
                  this.addnote();
                  this.noteProgress = false;
                  $('html, body').animate({
                      scrollTop: $("#noteList").offset().top
                  }, 1000);
              }.bind(this)
              )
        .catch(function (error) {
          console.log(error);
        });
    },
    fetchnote(){
      this.regId = this.$route.params.regId;
      axios.get(this.basePath+"api/Addnotes/member/"+ this.regId,{ headers:this.adminHeaders }).then(response => {
        console.log(response);
             this.userNote = response.data;
             console.log("`*`*`*`*`*`*`*`*`*`*`*`*`*`*`*`*`*`*`*`*`*`*`*`*`*`*`*`*`*`");
             console.log(this.userNote);
             console.log("`*`*`*`*`*`*`*`*`*`*`*`*`*`*`*`*`*`*`*`*`*`*`*`*`*`*`*`*`*`");
        //this.notes = response.data.notes;

        console.log(response,"notes");
        //this.notestatus = response.data.notes.status;
      })
      .catch(err => {
        console.log(err);
      })
    },
    editNote(item, button) {

      console.log('hi');
      console.log(item.id);
      if(item.id){
        this.note=item.note;
        console.log( this.note);
        this.member_id = item.member_id;
        console.log( this.member_id);
        this.selectedNote = item.note_type;
        console.log(this.selectedNote);
        this.notestatus = item.status;
        console.log( this.notestatus);
        this.edit_note_id = item.id;
        console.log( this.edit_note_id);
        this.edit_index = item;
         console.log( this.edit_index);
         console.log( this.edit_index.id);
      }
    },



    updatenote(){
        $('.note-error, .note-type-error').html("");
        var error_flag = 0;
        if(this.note.trim() == ""){
          $('.note-error').html("Please enter note");
          error_flag++;
        }

        if(this.selectedNote == ""){
          $('.note-type-error').html("Please enter note type");
          error_flag++;
        }

        if(error_flag) return;

        this.noteProgress = true;
        let form = {'note': this.note,'member_id':this.userProfileData.id,'selectedNote':this.selectedNote,'notestatus':this.notestatus};
        axios.put(this.basePath+'api/Addnotes/'+this.edit_index.id,form,{ headers:this.adminHeaders }).then(function (response){
          this.alertMessage = "Note updated successfully";
          this.displayAlert = true;
          this.noteProgress = false;
          this.$bvModal.hide("note-modal");
          this.fetchnote();
        }.bind(this))
        .catch(function (error) {
          console.log(error);
        });
    },

    deleteNote(id){
      this.displayAlert = false;
        this.$bvModal.msgBoxConfirm("Are you sure to delete this note ?" ,{
          title: 'Please Confirm',
          size: 'md',
          buttonSize: 'sm',
          okVariant: 'danger',
          okTitle: 'YES',
          cancelTitle: 'NO',
          footerClass: 'p-2',
          hideHeaderClose: false,
          noCloseOnBackdrop: true,
           noCloseOnEsc: true,
          centered: true
        }).then(value => {

           console.log(value);
          if (String(value) == "true") {
             axios.delete(this.basePath+'api/Addnotes/'+id,{ headers:this.adminHeaders })
              .then(function (response) {
                console.log(response);
              this.alertMessage = "Note deleted Successfully";
              this.displayAlert = true;
              this.fetchnote();
            }.bind(this))
            .catch(function (error) {
              console.log(error);
            });
          }
          })
           .catch(err => {
          console.log(err);
        });
         return false;
    },


    loadPropsfieldsData(){
      const retry = setInterval(() => {
        this.props_fields = this.getSiteProps('memberRegistration.individual.fields');
        this.listOfCountriesCitizen = (this.subdomain == 'alliancefastpitch') ? this.listOfCountries : null;
        this.listOfCountries = (this.subdomain == 'alliancefastpitch') ? this.props_fields.contact.country.options : this.listOfCountries;

        if(this.props_fields) clearInterval(retry);
      }, 200);

      const retry1 = setInterval(() => {
        this.Notetype = this.getSiteProps('Notetype.disciplines');              
        if(this.Notetype != "") clearInterval(retry1);
      }, 200);
    },
    getGroupList()
    {
      this.groupList = [];
      axios.get(this.basePath+'api/admin/group/list',{ headers:this.adminHeaders })
      .then((res) => {
        this.groupList_id = res.data.data; 
        let group_list = Object.keys(this.groupList_id); 
        if(group_list.length ){        
          group_list.forEach(id => {              
              this.groupList.push({ id: this.groupList_id[id].id, text: this.groupList_id[id].name });
          });     
        }           
      })
      .catch((error) => {
          console.log('Error:'+ error);               
      }).finally(() => {
      });
    },
    getMemberGroup()
    {
      axios.get(this.basePath+'api/admin/member-group/'+this.$route.params.regId,{ headers:this.adminHeaders })
      .then((res) => {
          this.memberGroups = res.data.data;
          this.memberGroupIds = this.memberGroups.map(x => x.id);                               
      })
      .catch((error) => {
          console.log('Error:'+ error);               
      }).finally(() => {
      });
    },
    updateGroupInfo()
    {       
      this.membergroupform.member_id = this.$route.params.regId;
      this.membergroupform.group_ids = this.memberGroupIds;       
      axios.post(this.basePath+'api/admin/group/assign-member-groups', this.membergroupform, { headers: this.adminHeaders })
      .then((res) => {
          this.displayAlert = true, 
          this.alertMessage = res.data.message;            
          if(this.getSiteProps('group_model.visible'))
          {
            this.getMemberGroup();
          } 
          this.userProfileApi();                  
      })
      .catch((error) => {
          console.log('Error:'+ error);               
          return false;
      
      }).finally(() => {
          
      }); 
    },
    loadTravelInformation(){
      axios.get(this.basePath+"api/admin/member/"+ this.userProfileData.id + "/travel-information/list",{ headers:this.adminHeaders }).then(response => {
        console.log(response);
        this.travelInformation = response.data.data;
        if (this.travelInformation.length === 0){
          this.addTravelForm = true;
        }else{
          this.addTravelForm = false;
        }
        this.isTravelFormloading = false;
      })
      .catch(err => {
        this.isTravelFormloading = false;
        console.log(err);
      })
    },
    addTravelInformation(){
        var vueRouter = this.$router;
        vueRouter.push(`/admin/member/travel-information/${this.userProfileData.id}/add`);
    },
    editTravelInformation(memberId){
        var vueRouter = this.$router;
        vueRouter.push(`/admin/member/travel-information/${memberId}/edit`);
    },
    deleteTravelForm(id){
        if(confirm("Are you sure you want to delete?")){
            fetch(process.env.VUE_APP_URL + "api/admin/member/travel-form/delete/"+id,{
                method:'delete',
                headers: this.adminHeaders,
            })
            .then(res => res.json())
            .then(data => {
                this.alertMessage = "Travel information deleted successfully";
                this.displayAlert = true;
                this.isTravelFormloading = true;
                this.loadTravelInformation();
                if (this.travelInformation.length === 0){
                  this.addTravelForm = true;
                }else{
                  this.addTravelForm = false;
                }
            })
            .catch(err => console.log(err));
        }
    },
    loadPurchaseHistory(){ 
        let memberId = this.$route.params.regId;
        axios.get(this.basePath+"api/member/purchase-history/"+ memberId,{ headers:this.adminHeaders }).then(response => {
            this.purchaseHistory.data = response.data.data;
            this.purchaseHistory.loaded = true;
            this.purchaseHistory.totalRows = this.purchaseHistory.data.length;
        })
        .catch(err => {
            console.log(err);
        })
    },
    onFilteredPurchaseHistory(filteredItems,data) {        
        this.purchaseHistory.totalRows = filteredItems.length;
        this.purchaseHistory.currentPage = 1;
    },
    customFilter(rows, filter){ 
        let transactionValid = false;
        let searchFlag = false;
        let startDateFlag = false;
        let endDateFlag = false;
        let compareDateFlag = false;

        let filterNames = rows.name + rows.product + rows.transaction_id;
        if(filter.search != null && filter.search.trim() != ''){
            searchFlag = true;
            if( filterNames.toLowerCase().includes(filter.search.trim().toLowerCase())){
                transactionValid =  true;
            }
        }
        
        if(filter.startDate != null && filter.startDate.trim() != '' && (filter.endDate.trim() == '' ) ){
            const start_date = filter.startDate;
            const filter_start_date = moment(rows.transaction_date).format("MM/DD/YYYY");
            if( moment(start_date).isSame(filter_start_date)  ){
                transactionValid =  true;
            }else if( moment(start_date).isBefore(filter_start_date) ){
                transactionValid =  true;
            }
            startDateFlag = true;
        }

        if(filter.endDate != null && filter.endDate.trim() != '' && filter.startDate.trim() == ''){
            const end_date = filter.endDate;
            const filter_end_date = moment(rows.transaction_date).format("MM/DD/YYYY");
            
            if( moment(end_date).isSame(filter_end_date)  ){
                transactionValid =  true;
            }else if( moment(end_date).isAfter(filter_end_date) ){
                transactionValid =  true;
            }
            endDateFlag = true;
        }

        if(filter.endDate.trim() != '' && filter.startDate.trim() != '' ){
            const filter_transaction_date = moment(rows.transaction_date).format("MM/DD/YYYY");
            const start_date = filter.startDate;
            const end_date = filter.endDate;

            if( moment(start_date).isSame(filter_transaction_date)  ){
                transactionValid =  true;
            }else if( moment(end_date).isSame(filter_transaction_date)  ){
                transactionValid =  true;
            }else if( moment(filter_transaction_date).isBetween(start_date, end_date)){
                transactionValid =  true;
            }
            compareDateFlag = true;
        }
        
        if( searchFlag || startDateFlag || endDateFlag || compareDateFlag){
            return transactionValid;
        }
        return  true;
    },
    //volunteering methods
      onFiltered(filteredItems) {         
        this.totalRows = filteredItems.length;
        this.currentPage = 1;
      },  
      getRoles()
      {
        axios.get(this.basePath+'api/admin/volunteering/roles', { headers: this.adminHeaders })
            .then((res) => { 
                this.roles = res.data.data;
            })
            .catch((error) => {
                console.log('Error:'+ error);               
                return false;            
            }).finally(() => {
            }
        );  
      },
      userVolunteering()
      {         
        axios.get(this.basePath+"api/admin/volunteering/list/"+this.regId,{headers: this.adminHeaders})
          .then(res => {
              this.volunteering = res.data.data;
          })
          .catch(err => {
              console.log(err);               
          })
      },
      loadsubroles(event){
        $("#sub_roles").css('display','flex');             
        if(event.target.options.selectedIndex!=0)
            this.sub_roles = this.roles[event.target.options.selectedIndex].sub_roles;   
        else     
            this.sub_roles = '';               
      },
      loadother(event){ 
        if(event.includes('-1'))
          $("#other_role").css('display','flex'); 
        else
        {
          $("#other_role").css('display','none'); 
          this.form.other_role='';  
        }                                                   
      },
     
      onFail(errors) {
        this.errors.record(errors);
      },
      addvolRow(button) {
        (this.editvolModal.editing = false),
          (this.editvolModal.title = "Add New volunteering");
        //let id = localStorage.getItem("id");
        this.editvolModal.content = {
          role: "",
          sub_roles: "",
          other_role: ""
        };
        this.$root.$emit("bv::show::modal", "editvol-modal", button);
      },
      editvolRow(item,button)
      {
        console.log("edit row id", item.id);
        this.$root.$emit("bv::show::modal", "editvol-modal", button);
        axios.get(this.basePath+'api/admin/volunteering/'+item.id, { headers: this.adminHeaders })
            .then((res) => { 
                this.form={};
                this.form.status = (res.data.data.status == 1) ? 'Current' : 'Invalid'; 
                $("#sub_roles, #volunteer_status").css('display','flex'); 
                this.form.role = res.data.data.role;  
                $("#role").attr('disabled','true');  
                this.sub_roles = this.roles[res.data.data.role].sub_roles;  
                this.form.sub_roles = res.data.data.sub_roles.split(","); 
                if(res.data.data.sub_roles.split(",").includes('-1'))
                $("#other_role").css('display','flex');  
                this.form.other_role = res.data.data.other_role;
                this.form.current_id = res.data.data.id;
            })
            .catch((error) => {
                console.log('Error:'+ error);               
                return false;            
            }).finally(() => {
                
            }
        ); 
      },
      deletevolRow(id)
      {
        if(confirm("Are you sure you want to delete?")){
          fetch(process.env.VUE_APP_URL + "api/admin/volunteering/"+id,{
              method:'delete',
              headers: this.adminHeaders,
          })
          .then(res => res.json())
          .then(data => {           
            if(data.status == "success")
            {
              this.alertMessage = "Volunteering deleted successfully";
              this.displayAlert = true;
              this.userVolunteering();   
            }                          
          })
          .catch(err => console.log(err));
        }
      },
      resetEditvolModal() {        
        this.loaded = false;        
        this.form = {
            role: '',
            sub_roles: '',  
            other_role: '',              
        }        
        this.loaded = true;
      },
      validate() {
          this.errors = {}
          this.valid = false;
          const validRole = validateRole(this.form.role);
          this.errors.role = validRole.error;

          const validsubRole = validateRole(this.form.sub_roles);
          this.errors.sub_roles = validsubRole.error;

          const validotherRole = validateOtherRole(this.form.sub_roles,this.form.other_role);
          this.errors.other_role = validotherRole.error;

          if (validRole.valid && validsubRole.valid && validotherRole.valid)
          {
              this.valid = true;
          }
          return this.valid;
      },

      saveVolunteering(bvModalEvt)
      {
        bvModalEvt.preventDefault();
        if (!this.validate()) {
            return;
        }
        this.form.member_id = this.regId;
        axios.post(this.basePath+'api/admin/volunteering/store', this.form, { headers: this.adminHeaders })
            .then((res) => {
                this.displayAlert = true, 
                this.alertMessage = res.data.message; 
                this.userVolunteering();
            })
            .catch((error) => {
                console.log('Error:'+ error);               
                return false;
            
            }).finally(() => {
                
            }
        );              
        this.errors = {};
        this.$nextTick(() => {
            this.$bvModal.hide('editvol-modal')
        });            
      },
      removeStateCode(){
        this.UpdateProfile.state = '';
        this.UpdateProfile.state_code = '';
      },
      isStartDisabledDate(date) {
        const currentDate = new Date(this.UpdateProfile.valid_thru);
        return date > currentDate;
      },
      isEndDisabledDate(date) {
        const currentDate = new Date(this.UpdateProfile.start_date);
        return date < currentDate;
      },
      formatDateValid(value){
        if (value) {
          return moment(String(value)).format('MM/DD/YYYY')
        }
      },
      
    messageFor(val){
      this.errors.gender = this.getSiteProps('gender_alert_message.'+val);
    },
    setDangerBg(){
      setTimeout(() => {
        $('#alert_box').children('div').removeClass('alert-success');
        $('#alert_box').children('div').addClass('alert-danger');
      }, 100);
    },
    removeDangerBg(){
      setTimeout(() => {
        $('#alert_box').children('div').removeClass('alert-danger');
        $('#alert_box').children('div').addClass('alert-success');
      }, 100);
    },
    resetAlert(){
      this.displayAlert = false;
      this.removeDangerBg();
    },
    validateTelinput2(obj){
      this.validTelinput2 = obj.valid;
      if(obj.valid){
        this.errors.phone_2 = '';
      }
    },
    enableMagazine(data){
     return this.getSiteProps('membershipInfoEdit.printedMagazine') && data?.membership.id != 7 && data?.membership?.id != 13 && (data?.membership?.id != 5 || (data?.personal_details?.relation == 0 || data?.relation == 0));
    }
  },
  mounted(){
    this.loadPositions();
    this.getSelectDiscipline();
    const retry2 = setInterval(() => {
        this.GroupModel = this.getSiteProps('group_model.visible');  
        if(this.GroupModel)
        {
          this.getMemberGroup();
          this.getGroupList();
        }          
        if(this.GroupModel != undefined) clearInterval(retry2);
      }, 200);
      this.userProfileApi();
      this.getDiscipline(1);
      this.getClubs();
      this.getGroup();
      this.getClubType();
      this.getMembershipStatus();
      this.getCurrentSeason();

    if(this.subdomain == 'alliancefastpitch')
    {
      this.getTeamsList();
      this.getCollegeCommittedsList();
    }

    if(this.subdomain != 'alliancefastpitch')
    {
      this.getRoles();
      this.getCategories();
      this.userVolunteering();
    } 
      
    this.getDiversity();
    
    this.fetchnote();
    this.loadPropsfieldsData();
    const year = new Date().getFullYear();
    const yrs = year+20;
    const twoDigitYear = yrs.toString().substr(-2);
    const lengthOfYear = parseInt(20) + parseInt(twoDigitYear);
    this.GraduationyearArr = Array.from({length: lengthOfYear}, (value, index) => yrs - index);
    this.fetchnote();
  },
    created() {
      this.loaded = true;
    },
  computed: {
    familyCat(){
      let cats = { 0: 'HOH', 1: 'Spouse', 2: 'Child', 3: "Dependent" };
      if(this.userProfileData.membership.member_type_id == 3){
        return cats[this.userProfileData.personal.relation];
      }
      return "";
    },
    purchaseHistoryRows() {
        return this.purchaseHistory.data.length;
    },
    rows() {
        return this.volunteering.length;
    },
    membershipStatusLists(){
       return this.membershipStatus.filter(item => item !=='');
    },
    showMemberTypeDropdown(){
      return this.getSiteProps('membershipInfoEdit.membershipType') && this.enableMemberTypeChange;
    }
  }
  }
</script>
<style scoped>
span.text-danger.bordernote i {
    border: 1px solid #e25858;
    padding: 4px 6px;
    border-radius: 20px;
    cursor: pointer;
    margin-left: 5px;
    line-height: normal;
    font-size: 10px !important;
}
.btn-tag-blue {
  font-size: 12px;
}
#volunteer_status,#other_role,#sub_roles
{
    display: none;
}
.select_position{
  height: calc(2.25rem + 3px)!important;
  font-size: 100%;
}
</style>